import React, { useEffect, useState } from 'react'

const SelectOne = ({ list, onChange, defaultIndex }) => {


    const [selectedIndex, setSelectedIndex] = useState(defaultIndex ? defaultIndex : 3)



    useEffect(()=>{
        setSelectedIndex(defaultIndex ? defaultIndex : 0)
    },[defaultIndex])
    return (
        <div
            className={'flex-wrap'}
            style={{
                width: "-webkit-fill-available",
                display: "flex",
                alignItems: "center",
                gap: "8px",

            }}
        >
            {
                list ? list.map((i, index) => {
                    return (
                        <div
                            className={`${selectedIndex === index ? "text-primary " : "bg-dark-400 text-white border-0"}`}
                            onClick={() => {
                                setSelectedIndex(index)
                                onChange(index)
                            }}
                            key={index}
                            style={{
                                background: selectedIndex === index ? "#FFF3F0" : "white",
                                color: selectedIndex === index ? "#FF5612" : "black",
                                padding: "9px 10px",
                                borderRadius: "16px",
                                fontSize: "14px",
                                fontFamily: "medium",
                                textTransform: "capitalize",
                                border: selectedIndex === index ? "1px solid #FFF3F0" : "1px solid #D9D9D9",
                                cursor: "pointer"
                            }}
                        >
                            {i}
                        </div>
                    )
                }) : <></>
            }


        </div>
    )
}

export default SelectOne
