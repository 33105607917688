import React, { useState } from 'react'
import SearchIcon from '../../assets/icons/search'


const SearchBar = ({ mystyle, onKeyDown, onChange, inputValue, searchValue }) => {

    const [textValue, setTextValue] = useState("")


    return (
        <div className="bg-dark-500" onKeyDown={(event) => onKeyDown(event)} style={{ marginRight: "10px", display: 'flex', alignItems: 'center',  height: "50px", padding: "0px 20px", borderRadius: "400px", width: "-webkit-fill-available", ...mystyle }}>
            <SearchIcon style={{ marginRight: "12px", cursor: "pointer" }} />
            <input

            onChange={(i) => {
                onChange(i)
                setTextValue(i.target.value)
            }}
                className='outline-none search-input bg-dark-500 text-white'
                placeholder='Search for anything'
                style={{ border: "0", fontSize: "15px", width: "100%" }} />
        </div>
    )
}

export default SearchBar