import React from 'react';

const PauseIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27" fill="none">
            <path d="M13.625 0.666687C6.449 0.666687 0.625 6.49069 0.625 13.6667C0.625 20.8427 6.449 26.6667 13.625 26.6667C20.801 26.6667 26.625 20.8427 26.625 13.6667C26.625 6.49069 20.814 0.666687 13.625 0.666687ZM12 17.6057C12 18.2297 11.74 18.4767 11.077 18.4767H10.2081C9.54505 18.4767 9.28505 18.2297 9.28505 17.6057V9.72769C9.28505 9.10369 9.54505 8.85669 10.2081 8.85669H11.064C11.727 8.85669 11.987 9.10369 11.987 9.72769V17.6057H12ZM18.0429 17.6057C18.0429 18.2297 17.7829 18.4767 17.1199 18.4767H16.264C15.601 18.4767 15.341 18.2297 15.341 17.6057V9.72769C15.341 9.10369 15.601 8.85669 16.264 8.85669H17.1199C17.7829 8.85669 18.0429 9.10369 18.0429 9.72769V17.6057Z" fill="white"/>
        </svg>
    );
};

export default PauseIcon;


