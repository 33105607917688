import React from 'react'

const HalfArrow = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M0.244379 15.1721C0.570192 15.498 1.09686 15.498 1.42267 15.1721L10 6.59483L18.5773 15.1721C18.9032 15.498 19.4298 15.498 19.7556 15.1721C20.0815 14.8463 20.0815 14.3197 19.7556 13.9938L10.5892 4.82736C10.4267 4.66487 10.2133 4.58319 9.99999 4.58319C9.78663 4.58319 9.57332 4.66487 9.41082 4.82736L0.244339 13.9938C-0.0814342 14.3197 -0.0814342 14.8463 0.244379 15.1721Z" fill="black" />
        </svg>
    )
}

export default HalfArrow