import React, { useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import theme from '../../../theme/theme'
import Header from '../../../components/globle/header'
import Button from '../../../components/globle/button'
import UploadCourse from './uploadCourse'
import SectionTopic from './sectionTopic'
import api from '../../../services/api'

const CreateCourse = ({ isAuthenticated }) => {
    const [section, setSection] = useState(1)
    const [saveClick, setSaveClick] = useState(false)
    const [createdCourseId, setCreatedCourseId] = useState()

    return (
        <div>
            <div
                style={{
                    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
                }}
            >
                <Header isAuthenticated={isAuthenticated} />
            </div>
            <div
                className='top-cont'
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "20px",
                    padding: "20px 0px"
                }}
            >

                <Button
                    className={'btn-dark-500'}
                    onClick={() => setSaveClick(true)}
                    title={"Save & Next"}
                />
            </div>
            <div
                className={'border-bottom-solid border-bottom-2 border-dark-400'}
                style={{
                    borderBottom: "2px solid #F6F6F9",
                }}
            >



                <div className='top-cont'>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "flex-start",
                            gap: "90px",
                        }}
                    >


                        <div
                            onClick={() => setSection(1)}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: "20px"
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: "semiBold",
                                    fontSize: "20px",
                                    color: section === 1 ? theme.primary : "#CDCDCD"
                                }}
                            >Create Course</span>

                            <div
                                style={{
                                    width: "100%",
                                    height: "4px",
                                    background: theme.primary,
                                    borderRadius: "20px",
                                    visibility: section === 1 ? "visible" : "hidden"
                                }}
                            ></div>
                        </div>


                    </div>
                </div>
            </div>
            {
                section === 1 ?
                    <div>
                        <UploadCourse setCreatedCourseId={setCreatedCourseId} createdCourseId={createdCourseId} saveClick={saveClick} setSaveClick={setSaveClick} />
                    </div>
                    :
                    <></>
            }


        </div>
    )
}

export default CreateCourse
