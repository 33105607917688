import {Client, Account} from "appwrite";
import config from '../config.json'


const client = new Client()
    .setEndpoint(config.APPWRITE_ENDPOINT)
    .setProject(config.APPWRITE_PROJECT_ID);

const account = new Account(client);

export default account
