import React, {useState} from 'react'
import Section from './section'
import Model from "../../components/globle/model";

const Sections = ({sections, onTopicClick}) => {
    const [showModel, setShowModel] = useState(false)


    return (
        <div

            style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px"
            }}
        >

            {
                sections.map((section, index) =>
                    <Section
                        index={index}
                        onTopicClick={onTopicClick}
                        sectionId={section?.id || ""}
                        key={index}
                        name={section?.name || ""}
                        topics={section?.topics || []}
                    />)
            }


            <Model
                title={"Certificates"}
                isShow={showModel}
                onCloseClick={() => setShowModel(false)}
            >
                <p className='text-white p-25'>Complete all topics to unlock your certificate. Happy learning!</p>
            </Model>
        </div>
    )
}

export default Sections
