import React, { useState } from 'react'
import theme from "../../../theme/theme";



const InstructorLoading = ({ author, className }) => {



    return (
        <div className={`${className} p-25-phone bg-dark-500`} style={{ padding: "50px", background: theme.color.gray100, borderRadius: "20px" }}>
            <div>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginBottom: "30px" }}>
                    <div className="bg-dark-400 b-radius-10px"
                       style={{ margin: 0,
                           fontFamily: "semiBold",
                           fontSize: "14px",
                           width: "90px",
                           height: "17px"
                       }}/>
                    <p className='fs-20-phone bg-dark-400 b-radius-10px'
                       style={{ margin: 0, fontFamily: "semiBold", fontSize: "35px",
                           width: "200px",
                           height: "43px"
                       }}/>
                </div>
                <div className='g-20-phone' style={{ display: "flex", gap: "40px" }}>
                    <div className='author-section-photo border-dark-400 bg-dark-400' style={{ width: "200px", height: "200px", objectFit: "cover", borderRadius: "20px", border: "1px solid #EDEDED" }}   />
                    <div>
                        <div className='mb-20-phone' style={{ display: "flex", flexDirection: "column", gap: "3px", marginBottom: "30px" }}>
                            <p className='fs-14-phone text-white bg-dark-400 b-radius-10px'
                               style={{
                                   margin: "0",
                                    width: "150px",
                                   height: "30px"
                               }}/>
                            <div className='fs-12-phone text-gray-200 bg-dark-400 b-radius-10px'
                               style={{ fontFamily: "semiBold", fontSize: "14px", margin: "0", color: theme.primary,   width: "65px",
                                   height: "17px" }}/>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>

                                            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                                <div className='bg-dark-400 b-radius-10px'
                                                   style={{ margin: "0",
                                                        width: "130px",
                                                       height: "17px"
                                                   }}/>
                                            </div><div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                                <div className='bg-dark-400 b-radius-10px'
                                                   style={{ margin: "0",
                                                        width: "130px",
                                                       height: "17px"
                                                   }}/>
                                            </div><div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                                <div className='bg-dark-400 b-radius-10px'
                                                   style={{ margin: "0",
                                                        width: "130px",
                                                       height: "17px"
                                                   }}/>
                                            </div><div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                                <div className='bg-dark-400 b-radius-10px'
                                                   style={{ margin: "0",
                                                        width: "130px",
                                                       height: "17px"
                                                   }}/>
                                            </div><div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                                <div className='bg-dark-400 b-radius-10px'
                                                   style={{ margin: "0",
                                                        width: "130px",
                                                       height: "17px"
                                                   }}/>
                                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-20-phone g-10-phone' style={{ marginTop: "40px", display: "flex", flexDirection: "column", gap: "20px" }}>
                <div className='fs-14-phone text-white bg-dark-400 b-radius-10px w-100p' style={{ fontFamily: "bold", fontSize: "20px", margin: "0", height: "50px" }}/>
                <div className='fs-12-phone text-gray-200 bg-dark-400 b-radius-10px' style={{ fontFamily: "semiBold", color: "#8D8D8D", margin: "0", fontSize: "14px", height: "51px" }}/>
            </div>
        </div>
    )
}

export default InstructorLoading
