import React from 'react';
import ZebralearnLogo from '../../assets/icons/zebralearn-logo.svg'
import SearchBar from "../globle/searchBar";
import theme from "../../theme/theme";

const HeaderLoading = ({styles, isAuthenticated}) => {
    return (
        <div className='bg-black px-10-phone position-sticky top-0' style={{
            display: 'flex',
            padding: "5px 50px",
            alignItems: 'center',
            height: "75px", ...styles,
            justifyContent: "space-between",
            zIndex: "1000"
        }}>
            <a href="https://zebralearn.com" >
                <img className='logo p-0-phone'  alt='zebralearn' src={ZebralearnLogo}
                     style={{
                         cursor: 'pointer',
                         width: "144px"
                     }}
                />
            </a>


            <div style={{display: 'flex', alignItems: 'center', height: "65px"}}>
                {
                    isAuthenticated ?
                        <></>
                        :
                        <p
                            className='fs-12-phone bg-white text-black border-2-s-dark-400'
                            style={{
                                margin: "0px",
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                                padding: "9px 20px",
                                fontSize: "14px",
                                fontFamily: "semiBold",
                                background: "#F6F6F9",
                                borderRadius: "12px",
                                textTransform: "capitalized"
                            }}

                        >Login</p>
                }

                {
                    isAuthenticated ?

                        <div
                            style={{
                                padding: "8px 16px 8px 8px",
                                borderRadius: "50px",
                                position: "relative"
                            }}
                            className="d-flex a-i-center g-10  bg-dark-400 cursor-pointer">





                            <div
                                className='overflow-hidden b-rounded-circle bg-dark-500 text-white ff-bold text-transform-uppercase d-flex a-i-center j-c-center fs-12 overflow-hidden'
                                style={{
                                    width: "32px",
                                    height: "32px",
                                    minWidth: "32px",
                                    minHeight: "32px",

                                }}
                            >
                            </div>
                            <div className='d-flex flex-column'
                                 style={{
                                     gap: "6px"
                                 }}
                            >
                                <div className='bg-dark-500 b-radius-10px'
                                     style={{
                                         width: "14px",
                                         height: "2px",

                                     }}
                                ></div>
                                <div className='bg-dark-500 b-radius-10px'
                                     style={{
                                         width: "14px",
                                         height: "2px",

                                     }}
                                ></div>
                            </div>
                        </div>
                        :
                        <></>

                }


            </div>
        </div>
    );
};

export default HeaderLoading;
