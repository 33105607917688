import axios from 'axios';
import config from '../config.json';

const BaseUrl = config.ecom_url;


const getEcomCourses = async (currentPage) => {
    try {
        const response = await axios.get(`${BaseUrl}/api/v3/products?category_id=5&page=${currentPage ? currentPage : "1"}`);
        return response;
    } catch (error) {
        console.error(error);
        return error.response;
    }
}



export default { getEcomCourses }
