import React, { useState } from 'react'
import theme from '../../theme/theme'
import PersonPhoto from "../../assets/images/person2.jpg"
import '../../assets/css/courseInfo.css'


const Instructor = ({ author, className }) => {


    return (
        <div className={`${className} p-25-phone bg-dark-500`} style={{ padding: "50px", background: theme.color.gray100, borderRadius: "20px" }}>
            <div>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginBottom: "30px" }}>
                    <p className="text-primary" style={{ margin: 0, fontFamily: "semiBold", fontSize: "14px" }}>Meet your</p>
                    <p className='fs-20-phone text-white' style={{ margin: 0, fontFamily: "semiBold", fontSize: "35px" }}>Instructor</p>
                </div>
                <div className='g-20-phone' style={{ display: "flex", gap: "40px" }}>
                    <img className='author-section-photo border-dark-400' style={{ width: "200px", height: "200px", objectFit: "cover", borderRadius: "20px", border: "1px solid #EDEDED" }} src={author && author.avatar ? author.avatar : PersonPhoto} alt='instructor' />
                    <div>
                        <div className='mb-20-phone' style={{ display: "flex", flexDirection: "column", gap: "3px", marginBottom: "30px" }}>
                            <p className='fs-14-phone text-white' style={{ fontFamily: "bold", fontSize: "20px", margin: "0", color: "black" }}>{author && author.first_name && author.last_name ? author.first_name + " " + author.last_name : "Anurag Sunderka"}</p>
                            <p className='fs-12-phone text-gray-200' style={{ fontFamily: "semiBold", fontSize: "14px", margin: "0", color: theme.primary }}>
                                {
                                    author && author.length > 0 ? author.author_categories.map((i, index) => {
                                        return (
                                            <span
                                                key={index}
                                            >{i.category.name + (index === (author.author_categories.length) - 1 ? "" : ", ")}</span>
                                        )

                                    })
                                        :
                                        <span>Finance</span>
                                }
                            </p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>

                            {
                                author && author.user_highlights && author.user_highlights.length > 0 ? author.user_highlights.map((item, index) => {
                                    return (
                                        <div key={index} style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                            <div className="bg-gray-100" style={{ width: "5px", height: "5px", background: "black", borderRadius: "50%" }}></div>
                                            <p className='fs-12-phone text-gray-100' style={{ margin: "0", fontFamily: "medium", fontSize: "14px" }}>{item.description}</p>
                                        </div>
                                    )
                                })
                                    :
                                    <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                        <div style={{ width: "5px", height: "5px", background: "black", borderRadius: "50%" }}></div>
                                        <p className='fs-12-phone' style={{ margin: "0", fontFamily: "medium", fontSize: "14px" }}>Nodata of No data</p>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-20-phone g-10-phone' style={{ marginTop: "40px", display: "flex", flexDirection: "column", gap: "20px" }}>
                <p className='fs-14-phone text-white' style={{ fontFamily: "bold", fontSize: "20px", margin: "0" }}>{author && author.headline ? author.headline : "Dummy Headline"}</p>
                <p className='fs-12-phone text-gray-200' style={{ fontFamily: "semiBold", color: "#8D8D8D", margin: "0", fontSize: "14px" }}>{author && author.description ? author.description : "Hi there! I am Anurag Sundarka, co-founder of ZebraPro and author of the book- 'Rich Ponds - The Complete Guide to Identifying Rich Industries to Invest In.'"}</p>
            </div>
        </div>
    )
}

export default Instructor