import React from 'react'

const LockIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.25 11C0.25 8.37665 2.37665 6.25 5 6.25H13C15.6234 6.25 17.75 8.37665 17.75 11V15C17.75 17.6234 15.6234 19.75 13 19.75H5C2.37665 19.75 0.25 17.6234 0.25 15V11ZM9.75 12C9.75 11.5858 9.41421 11.25 9 11.25C8.58579 11.25 8.25 11.5858 8.25 12V14C8.25 14.4142 8.58579 14.75 9 14.75C9.41421 14.75 9.75 14.4142 9.75 14V12Z" fill="#010101" />
            <path d="M13 7V5C13 2.79086 11.2091 1 9 1V1C6.79086 1 5 2.79086 5 5L5 7" stroke="#010101" strokeWidth="1.5" />
        </svg>
    )
}

export default LockIcon