import React from 'react';
import Category from "../../components/category/category";

const Categories = () => {
    return (
        <div
        className='top-cont mt-10'
        >
           <Category/>
        </div>
    );
};

export default Categories;
