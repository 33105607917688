import React from 'react';
import theme from "../../../theme/theme";
import CourseContentItem from "../../courseDetails/courseLessonPlan/courseContentItem";
import CourseContentItemLoading from "./courseContentItemLoading";

const CourseContentLoading = ({style, courseCategories, courseSections}) => {
    return (
        <div className="overflow-hidden" style={{...style}}>
            <div style={{display: "flex", flexDirection: "column", gap: "5px", marginBottom: "30px"}}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div className="text-primary bg-dark-500 b-radius-10px"
                         style={{
                             margin: 0,
                             fontFamily: "semiBold",
                             fontSize: "14px",
                             width: "100px",
                             height: "17px"
                         }}/>
                </div>
                <div className='fs-20-phone text-white bg-dark-500 b-radius-10px'
                     style={{
                         margin: 0,
                         fontFamily: "semiBold",
                         fontSize: "35px",
                         height: "43px",
                         width: "220px"

                     }}/>
            </div>

            <div className='bg-dark-500' style={{backgroundColor: theme.color.gray100, borderRadius: "20px"}}>

                <CourseContentItemLoading />
                <CourseContentItemLoading />
                <CourseContentItemLoading />
                <CourseContentItemLoading />
                <CourseContentItemLoading />
                <CourseContentItemLoading />
                <CourseContentItemLoading />
                <CourseContentItemLoading />
                <CourseContentItemLoading />
                <CourseContentItemLoading />
                <CourseContentItemLoading />
                <CourseContentItemLoading />
                <CourseContentItemLoading />

            </div>
        </div>
    );
};

export default CourseContentLoading;
