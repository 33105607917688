import React from 'react'

const DownloadIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" >
            <circle cx="16" cy="16" r="16" fill='#1E1E1E' />
            <path d="M20.5714 22.9998H11.4286C10.5193 22.9998 9.64719 22.679 9.00421 22.1078C8.36123 21.5368 8 20.7623 8 19.9546V17.9245C8 17.763 8.07225 17.608 8.20084 17.4938C8.32944 17.3796 8.50385 17.3154 8.68571 17.3154C8.86758 17.3154 9.04199 17.3796 9.17059 17.4938C9.29919 17.608 9.37143 17.763 9.37143 17.9245V19.9546C9.37143 20.4392 9.58817 20.9039 9.97395 21.2466C10.3597 21.5892 10.883 21.7817 11.4286 21.7817H20.5714C21.117 21.7817 21.6402 21.5892 22.0261 21.2466C22.4118 20.9039 22.6286 20.4392 22.6286 19.9546V17.9245C22.6286 17.763 22.7008 17.608 22.8294 17.4938C22.958 17.3796 23.1324 17.3154 23.3143 17.3154C23.4961 17.3154 23.6706 17.3796 23.7991 17.4938C23.9278 17.608 24 17.763 24 17.9245V19.9546C24 20.7623 23.6388 21.5368 22.9957 22.1078C22.3528 22.679 21.4808 22.9998 20.5714 22.9998Z" fill="#ffffff" />
            <path d="M16.0021 18.095C15.8203 18.095 15.6458 18.0266 15.5173 17.9047C15.3886 17.7829 15.3164 17.6176 15.3164 17.4454V9.64964C15.3164 9.47735 15.3886 9.31211 15.5173 9.19028C15.6458 9.06845 15.8203 9 16.0021 9C16.184 9 16.3584 9.06845 16.487 9.19028C16.6156 9.31211 16.6878 9.47735 16.6878 9.64964V17.4454C16.6855 17.617 16.6125 17.7809 16.4844 17.9023C16.3563 18.0236 16.1832 18.0928 16.0021 18.095Z" fill="#ffffff" />
            <path d="M15.9991 19.1698C15.909 19.1707 15.8196 19.1543 15.7363 19.1215C15.653 19.0888 15.5776 19.0404 15.5146 18.9792L11.638 15.3066C11.5733 15.2469 11.5219 15.1754 11.4868 15.0966C11.4517 15.0178 11.4336 14.9331 11.4336 14.8475C11.4336 14.7619 11.4517 14.6772 11.4868 14.5984C11.5219 14.5196 11.5733 14.4482 11.638 14.3884C11.7666 14.2668 11.9409 14.1985 12.1226 14.1985C12.3043 14.1985 12.4786 14.2668 12.6071 14.3884L15.9991 17.602L19.3911 14.3884C19.4539 14.3246 19.5296 14.2734 19.6138 14.2379C19.6979 14.2024 19.7887 14.1833 19.8807 14.1817C19.9728 14.1802 20.0642 14.1963 20.1496 14.229C20.235 14.2616 20.3126 14.3102 20.3776 14.372C20.4428 14.4336 20.4941 14.5071 20.5286 14.588C20.5631 14.6689 20.5801 14.7555 20.5784 14.8427C20.5768 14.93 20.5567 15.016 20.5192 15.0957C20.4817 15.1754 20.4277 15.2471 20.3603 15.3066L16.4837 18.9792C16.4204 19.0399 16.3449 19.0881 16.2617 19.1209C16.1785 19.1536 16.0892 19.1702 15.9991 19.1698Z" fill="#ffffff" />
        </svg>
    )
}

export default DownloadIcon
