import React from 'react';

const YoutubeIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" >
            <g clipPath="url(#clip0_1454_27953)">
                <path d="M17.9987 31.0776C17.9956 31.0776 17.9923 31.0776 17.9891 31.0776C16.9028 31.0702 7.29961 30.9826 4.57884 30.2468C2.67738 29.7356 1.17637 28.2363 0.663858 26.3345C-0.0480578 23.6618 -0.00273915 18.5161 0.00220471 18.1052C-0.00246448 17.6963 -0.0483325 12.5079 0.66166 9.81327C0.662484 9.8108 0.663034 9.80805 0.663858 9.80558C1.1706 7.92554 2.7054 6.37976 4.57417 5.86725C4.57884 5.86588 4.58379 5.86478 4.58846 5.8634C7.27846 5.15643 16.9006 5.06992 17.9891 5.0625H18.0086C19.0976 5.06992 28.7263 5.15753 31.4221 5.89444C33.3186 6.40421 34.8185 7.90165 35.3324 9.80091C36.0709 12.4975 36.0025 17.6971 35.9951 18.136C36.0003 18.5683 36.0435 23.6679 35.3357 26.354C35.3351 26.3568 35.3343 26.3593 35.3338 26.3617C34.821 28.2635 33.3202 29.7628 31.4166 30.2745C31.4141 30.2753 31.4113 30.2759 31.4089 30.2767C28.7191 30.9834 19.0968 31.0699 18.0086 31.0776C18.0053 31.0776 18.002 31.0776 17.9987 31.0776ZM3.38023 10.5345C2.75511 12.9131 2.81416 18.0352 2.81471 18.0868V18.1239C2.79603 19.5444 2.86168 23.6583 3.38051 25.6067C3.63209 26.5397 4.3723 27.2788 5.31108 27.5312C7.31856 28.0742 15.14 28.2451 17.9987 28.2651C20.8647 28.2451 28.6977 28.0789 30.6904 27.5576C31.6261 27.3044 32.3639 26.5677 32.6171 25.6336C33.1365 23.6566 33.2016 19.5628 33.1826 18.1514C33.1826 18.1365 33.1826 18.1217 33.1829 18.1069C33.2087 16.6693 33.1579 12.5074 32.6196 10.5433C32.619 10.5414 32.6185 10.5395 32.6182 10.5375C32.3655 9.60041 31.625 8.8613 30.6863 8.60889C28.6983 8.06534 20.8642 7.89506 17.9987 7.87501C15.1345 7.89506 7.30922 8.06095 5.31053 8.5817C4.3896 8.83713 3.63264 9.60261 3.38023 10.5345ZM14.4141 23.7651V12.3747L24.2579 18.0701L14.4141 23.7651Z" />
            </g>
            <defs>
                <clipPath id="clip0_1454_27953">
                    <rect width="36" height="36"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default YoutubeIcon;
