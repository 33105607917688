import React from 'react'

const PlayIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M16 0C7.17706 0 0 7.17775 0 16C0 24.8223 7.17706 32 16 32C24.8229 32 32 24.8223 32 16C32 7.17775 24.8229 0 16 0ZM22.3607 16.5606L13.0274 22.5606C12.918 22.6315 12.7917 22.6667 12.6667 22.6667C12.5573 22.6667 12.4466 22.6393 12.3477 22.5853C12.1328 22.4681 12 22.2441 12 22V10C12 9.75588 12.1328 9.53187 12.3477 9.41469C12.5586 9.29881 12.8229 9.30594 13.0274 9.43944L22.3607 15.4394C22.5508 15.5618 22.6667 15.7734 22.6667 16C22.6667 16.2266 22.5508 16.4381 22.3607 16.5606Z" fill="#FF5612" />
        </svg>
    )
}

export default PlayIcon