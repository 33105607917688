import theme from "../../../theme/theme";
import React from "react";

const CardLoading = () => {

    return (
        <div

            style={{
                color: "inherit",
                textDecoration: "none",
                boxShadow: "rgba(0, 0, 0, 0.08) 0px 0px 12px 0px",
                borderRadius: "12px",
                padding: "20px"
            }}
        >
            <div
                className='bg-dark-500'
                style={{
                    borderRadius: "12px 12px 0 0",
                    width: "100%",
                    objectFit: "cover",
                    aspectRatio: "1189/668"
                }}/>
            <div
                className='bg-dark-500'
                style={{
                    width: "100%",
                    height: "6px",
                    borderRadius: "10px",
                    background: "#F6F6F9",
                    marginTop: "5px",
                    position: "relative",
                    overflow: "hidden",
                    marginBottom: "15px"
                }}
            >
                <div
                    style={{
                        height: "100%",
                        background: theme.primary,
                        position: "absolute",
                        top: "0",
                        left: "0",
                        borderRadius: "20px"
                    }}
                ></div>

            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    marginBottom: "11px"
                }}
            >
                <div
                    className='bg-dark-500'
                    style={{
                        width: "16px",
                        height: "18px",
                        objectFit: "cover",
                        borderRadius: "50%",
                    }}/>
                <div
                    className='text-white bg-dark-500 b-radius-10px'
                    style={{
                        margin: "0",
                        fontSize: "13px",
                        fontFamily: "medium",
                        width: "100px",
                        height: "16px"
                    }}
                />
            </div>
            <div
                className='bg-dark-500'
                style={{
                    width: "100%",
                    height: "2px",
                    background: "#F6F6F9",
                    marginBottom: "21px"
                }}
            >

            </div>
            <div
                className='text-white bg-dark-500 b-radius-10px'
                style={{
                    margin: "0",
                    fontSize: "16px",
                    fontFamily: "medium",
                    marginBottom: "12px",
                    width: "70%",
                    height: "20px"
                }}
            />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
            </div>

        </div>
    )
}

export default CardLoading
