import React from 'react'

const AddRoundIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
            <circle cx="19" cy="19" r="18" stroke="#F6F6F9" strokeWidth="2" />
            <path d="M27.0575 19.08L27.0575 19.08C27.0575 19.292 26.9733 19.4954 26.8234 19.6453C26.6735 19.7952 26.4702 19.8794 26.2582 19.8794L11.9023 19.8794L27.0575 19.08ZM27.0575 19.08C27.0575 18.868 26.9733 18.6647 26.8234 18.5148C26.6735 18.3649 26.4702 18.2806 26.2582 18.2806L11.9021 18.2806C11.9021 18.2806 11.9021 18.2806 11.902 18.2806C11.7439 18.2806 11.5894 18.3275 11.4579 18.4153C11.3265 18.5032 11.224 18.628 11.1635 18.7741C11.103 18.9202 11.0872 19.0809 11.118 19.236C11.1489 19.3909 11.2249 19.5332 11.3366 19.645M27.0575 19.08L11.3366 19.645M11.3366 19.645C11.4108 19.7194 11.499 19.7784 11.5961 19.8186C11.6931 19.8588 11.7971 19.8795 11.902 19.8794L11.3366 19.645Z" fill="#FF5612" stroke="#FF5612" strokeWidth="0.5" />
            <path d="M18.5151 26.8235C18.4407 26.7493 18.3817 26.6611 18.3415 26.564C18.3013 26.467 18.2806 26.3631 18.2807 26.2581L18.5151 26.8235ZM18.5151 26.8235C18.6269 26.9352 18.7692 27.0112 18.9241 27.0421C19.0792 27.0729 19.2399 27.0571 19.386 26.9966C19.5321 26.9361 19.6569 26.8336 19.7448 26.7022C19.8326 26.5707 19.8795 26.4162 19.8795 26.2581C19.8795 26.258 19.8795 26.258 19.8795 26.258L19.8795 11.9019C19.8795 11.6899 19.7952 11.4866 19.6453 11.3367C19.4954 11.1868 19.2921 11.1026 19.0801 11.1026C18.8681 11.1026 18.6647 11.1868 18.5148 11.3367C18.3649 11.4866 18.2807 11.6899 18.2807 11.9019L18.2807 26.2578L18.6917 26.6465L18.5148 26.8232C18.5149 26.8233 18.515 26.8234 18.5151 26.8235Z" fill="#FF5612" stroke="#FF5612" strokeWidth="0.5" />
        </svg>
    )
}

export default AddRoundIcon