import React, {useEffect} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import theme from '../../theme/theme'
import Header from '../../components/globle/header'
import Button from '../../components/globle/button'

const Admin = ({isAuthenticated}) => {


    const navigation = useNavigate()
    const location = useLocation()

    useEffect(() => {
        navigation('/admin/courses')

    }, [])


    return (
        <div className='bg-black'>
            <div
                style={{
                    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
                }}
            >
                <Header isAuthenticated={isAuthenticated}/>
            </div>
            <div
                className={'border-bottom-2 border-dark-400 border-bottom-solid'}

            >
                <div
                    className='top-cont'
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "20px",
                        padding: "20px 0px"
                    }}
                >
                    <Button
                        className={'btn-dark-500'}
                        onClick={() => navigation('/admin/author/create')}
                        title={"Create new Author"}
                    />
                    <Button
                        className={'btn-dark-500'}
                        onClick={() => navigation('/admin/course/create')}
                        title={"Create new Course"}
                    />
                    <Button
                        className={'btn-dark-500'}
                        onClick={() => navigation('/admin/chapter/create')}
                        title={"Create new Chapter"}
                    />
                </div>


                <div className='top-cont'>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "flex-start",
                            gap: "90px"
                        }}
                    >


                        <div
                            onClick={() => navigation('/admin/courses')}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: "20px"
                            }}
                        >
              <span
                  className={`${location.pathname === "/admin/courses" ? 'text-primary cur' : 'text-white'} cursor-pointer`}
                  style={{
                      fontFamily: "semiBold",
                      fontSize: "20px",
                  }}
              >All Courses</span>

                            <div
                                style={{
                                    width: "100%",
                                    height: "4px",
                                    background: theme.primary,
                                    borderRadius: "20px",
                                    visibility: location.pathname === "/admin/courses" ? "visible" : "hidden"
                                }}
                            ></div>
                        </div>


                        <div
                            onClick={() => navigation("/admin/authors")}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: "20px"
                            }}
                        >
              <span
                  className={`${location.pathname === "/admin/authors" ? 'text-primary' : 'text-white'} cursor-pointer`}
                  style={{
                      fontFamily: "semiBold",
                      fontSize: "20px",
                      color: location.pathname === "/admin/authors" ? theme.primary : "#CDCDCD"
                  }}
              >All Authors</span>
                            <div
                                style={{
                                    width: "100%",
                                    height: "4px",
                                    borderRadius: "20px",
                                    visibility: location.pathname === "/admin/authors" ? "visible" : "hidden",
                                    background: theme.primary
                                }}
                            ></div>
                        </div>

                        <div
                            onClick={() => navigation("/admin/categories")}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: "20px"
                            }}
                        >
              <span
                  className={`${location.pathname === "/admin/categories" ? 'text-primary' : 'text-white'} cursor-pointer`}
                  style={{
                      fontFamily: "semiBold",
                      fontSize: "20px",
                      color: location.pathname === "/admin/categories" ? theme.primary : "#CDCDCD"
                  }}
              >All Categories</span>
                            <div
                                style={{
                                    width: "100%",
                                    height: "4px",
                                    borderRadius: "20px",
                                    visibility: location.pathname === "/admin/categories" ? "visible" : "hidden",
                                    background: theme.primary
                                }}
                            ></div>
                        </div>

                    </div>
                </div>
            </div>

            <Outlet/>
        </div>
    )
}

export default Admin
