import React, {useEffect, useState} from 'react'
import AddRoundIcon from '../../../assets/icons/addRoundIcon'
import SelectMultiple from '../../../components/globle/selectMultiple'
import api from "../../../services/api"
import Loading from '../../../components/globle/loading'
import LoadingAnimation from '../../../components/globle/loadingAnimation'
import CloseIcon from '../../../assets/icons/closeIcon'
import {useNavigate} from 'react-router-dom'

const AuthorDetails = ({saveClick, setSaveClick}) => {
    const [categoryRes, setCategoryRes] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [avatarUrl, setAvatarUrl] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [headline, setHeadline] = useState("")
    const [description, setDescription] = useState("")
    const [website, setWebsite] = useState("")
    const [facebook, setFacebook] = useState("")
    const [instagram, setInstagram] = useState("")
    const [linkedin, setLinkedin] = useState("")
    const [twitter, setTwitter] = useState("")
    const [categories, setCategories] = useState([])
    const [imageUploadingLoading, setImageUploadingLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [highlightText, setHighlightText] = useState("")
    const [highlight, setHighlight] = useState([])
    const [fileId, setFileId] = useState("")
    const [email, setEmail] = useState("");

    const navigate = useNavigate()

    const avatarHandle = async (event) => {
        setImageUploadingLoading(true)

        const file = event.target.files[0];

        // create file start
        try {
            let fData = {
                name: file.name,
                size: file.size,
                type: file.type,
            }
            const createFile = await api.fileCreate(fData);


            if (createFile && createFile.data && createFile.data.data && createFile.data.data.id) {
                setFileId(createFile.data.data.id)

                const data = {
                    fileName: file.name,
                    ContentType: file.type
                }

                const response = await api.getPresignedUrl(data)

                if (response && response.data && response.data.data) {
                    const presignedUrl = response.data.data;

                    const fileData = {
                        url: presignedUrl,
                        fileType: file.type,
                        file: file
                    }

                    const sendDataResponse = await api.sendDataToS3(fileData);

                    if (sendDataResponse) {

                        const imageUrl = presignedUrl.split("?")[0]

                        // update key start
                        const videoUrl = imageUrl;
                        const urlObject = new URL(videoUrl);
                        let pathname = urlObject.pathname;
                        let storageKey = "";
                        for (let i = 1; i < pathname.length; i++) {
                            storageKey = storageKey + pathname[i]
                        }
                        const actualNameKey = decodeURIComponent(storageKey);
                        const njernData = {
                            fileId: createFile.data.data.id,
                            storageKey: actualNameKey
                        }
                        const updateKey = await api.updateStorageKey(njernData);

                        if (updateKey) {

                            const data = {
                                fileId: createFile.data.data.id
                            }

                            const thumbnailUrl = await api.readFileUrl(data);

                            if (thumbnailUrl && thumbnailUrl.data && thumbnailUrl.data.data) {
                                setAvatarUrl(thumbnailUrl.data.data)
                            }

                        }
                        // update key end
                    }

                }

            }

        } catch (e) {
            alert(e.message)
        }

        // create file end
        setImageUploadingLoading(false)
    }

    const getCategories = async () => {

        try {
            const responce = await api.getCategories();
            if (responce && responce.data && responce.data.data) {
                const dataArray = responce.data.data;
                setCategoryRes(dataArray)
                let newArray = []

                for (let i = 0; i < dataArray.length; i++) {
                    newArray.push(dataArray[i].name)
                }
                setAllCategories(newArray)
            }
        } catch (e) {
            alert(e.message)
        }
    }

    const createAuthorHandle = async () => {
        if (saveClick) {

            try {


                let finalCategories = []
                for (let i = 0; i < categories.length; i++) {
                    finalCategories.push(categoryRes[categories[i]].id)
                }

                const data = {
                    firstName,
                    lastName,
                    headline,
                    description,
                    website,
                    facebook,
                    instagram,
                    linkedin,
                    twitter,
                    categories: finalCategories,
                    email: email,
                    password: "",
                    phone: "",
                    highlights: highlight,
                    avatar_file_id: fileId
                }
                const response = await api.createAuthor(data);

                setAvatarUrl("")
                navigate('/admin/courses')

            } catch (e) {
                alert(e.response && e.response.data && e.response.data.message ? e.response.data.message : "Server error")
            }


            setIsLoading(false)
            setSaveClick(false)
        }
    }

    useEffect(() => {
        createAuthorHandle()
    }, [saveClick])

    useEffect(() => {
        getCategories()
    }, [])

    if (isLoading) {
        return (
            <Loading/>
        )
    } else {
        return (
            <div
                className='top-cont'
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "40px",
                    marginBottom: "40px"
                }}
            >

                <div
                    className={'border-bottom-2 border-bottom-solid border-dark-400'}
                    style={{
                        display: "flex",
                        padding: "30px 0",
                        borderBottom: "1px solid #F6F6F9"

                    }}
                >
                    <div
                        style={{
                            flex: "0 0 250px",
                            display: "flex",
                            alignItems: "center"
                        }}
                    >
                        <p
                            className={'text-white'}
                            style={{
                                fontSize: "16px",
                                fontFamily: "medium",
                                margin: "0"
                            }}
                        >Profile picture</p>
                    </div>

                    <div
                        style={{
                            width: "-webkit-fill-available",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flex: "1"
                        }}
                    >
                        <p
                            className={'text-white'}
                            style={{
                                fontSize: "16px",
                                fontFamily: "medium",
                                margin: "0"
                            }}
                        >This picture will be visible to all</p>
                        <input
                            accept="image/*"
                            onChange={avatarHandle}
                            style={{display: "none"}}
                            id='avatarFile'
                            type='file'/>

                        <div
                            style={{
                                width: "85px",
                                height: "85px",
                                borderRadius: "50%",
                                position: "relative"
                            }}
                        >
                            {
                                imageUploadingLoading ?
                                    <div
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <LoadingAnimation

                                            style={{
                                                width: "60px",
                                                height: "60px",

                                            }}
                                        />
                                    </div> : <></>
                            }

                            <label htmlFor="avatarFile">

                                {
                                    avatarUrl ?
                                        <img
                                            className={'cursor-pointer bg-dark-400 border-2-s-dark-500'}
                                            src={avatarUrl}
                                            style={{
                                                width: "85px",
                                                height: "85px",
                                                borderRadius: "50%",
                                                objectFit: "cover"
                                            }}/>
                                        : <div className={'d-flex a-i-center j-c-center cursor-pointer b-rounded-circle bg-dark-400 border-2-s-dark-500'} style={{
                                            width: "85px",
                                            height: "85px",
                                        }}>
                                            {!imageUploadingLoading ? <span>➕</span> : <></>}
                                        </div>

                                }

                            </label>
                        </div>
                    </div>
                </div>
                {/*  */}

                {/*  */}
                <div
                    style={{
                        display: "flex",

                    }}
                >
                    <div
                        style={{
                            flex: "0 0 250px",
                            display: "flex",
                            alignItems: "start"
                        }}
                    >
                        <p
                            className={'text-white'}
                            style={{
                                fontSize: "16px",
                                fontFamily: "medium",
                                margin: "0"
                            }}
                        >Name</p>
                    </div>
                    <div
                        style={{
                            width: "-webkit-fill-available",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: "10px"

                        }}
                    >
                        <input
                            className={'bg-dark-400 outline-none text-white border-2-s-dark-500'}
                            onChange={(i) => setFirstName(i.target.value)}
                            placeholder='First Name'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                height: "65px",
                                width: "100%",
                                padding: "0 30px",
                                fontSize: "16px",

                            }}
                        />
                        <input
                            className={'bg-dark-400 outline-none text-white border-2-s-dark-500'}
                            onChange={(i) => setLastName(i.target.value)}
                            placeholder='Last Name'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                height: "65px",
                                width: "100%",
                                padding: "0 30px",
                                fontSize: "16px",

                            }}
                        />

                    </div>
                </div>
                {/*  */}
                {/*  */}
                <div
                    style={{
                        display: "flex",

                    }}
                >
                    <div
                        style={{
                            flex: "0 0 250px",
                            display: "flex",
                            alignItems: "start"
                        }}
                    >
                        <p
                            className={'text-white'}
                            style={{
                                fontSize: "16px",
                                fontFamily: "medium",
                                margin: "0"
                            }}
                        >Email</p>
                    </div>
                    <div
                        style={{
                            width: "-webkit-fill-available",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: "10px"

                        }}
                    >
                        <input
                            value={email}
                            className={'bg-dark-400 outline-none text-white border-2-s-dark-500'}
                            onChange={(i) => setEmail(i.target.value)}
                            placeholder='Email'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                height: "65px",
                                width: "100%",
                                padding: "0 30px",
                                fontSize: "16px",

                            }}
                        />

                    </div>
                </div>
                {/*  */}
                <div
                    style={{
                        display: "flex",

                    }}
                >
                    <div
                        style={{
                            flex: "0 0 250px",
                            display: "flex",
                            alignItems: "start"
                        }}
                    >
                        <p
                            className={'text-white'}
                            style={{
                                fontSize: "16px",
                                fontFamily: "medium",
                                margin: "0"
                            }}
                        >Headline</p>
                    </div>
                    <div
                        style={{
                            width: "-webkit-fill-available",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            // flex: "1"
                            flexDirection: "column"
                        }}
                    >
                        <input
                            className={'bg-dark-400 outline-none text-white border-2-s-dark-500'}
                            onChange={(i) => setHeadline(i.target.value)}
                            placeholder='Add professional line like “Be awesome”'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                height: "65px",
                                width: "100%",
                                padding: "0 30px",
                                fontSize: "16px",

                            }}
                        />

                    </div>
                </div>
                {/*  */}
                <div
                    style={{
                        display: "flex",

                    }}
                >
                    <div
                        style={{
                            flex: "0 0 250px",
                            display: "flex",
                            alignItems: "start"
                        }}
                    >
                        <p
                            style={{
                                fontSize: "16px",
                                fontFamily: "medium",
                                margin: "0"
                            }}
                        >Description</p>
                    </div>
                    <div
                        style={{
                            width: "-webkit-fill-available",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            // flex: "1"
                            flexDirection: "column"
                        }}
                    >
                        <textarea
                            className={'bg-dark-400 outline-none text-white border-2-s-dark-500'}
                            onChange={(i) => setDescription(i.target.value)}
                            placeholder='Add Awesome description'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                width: "100%",
                                padding: "30px",
                                fontSize: "16px",
                                height: "150px"
                            }}
                        ></textarea>

                    </div>
                </div>
                {/*  */}
                <div
                    style={{
                        display: "flex",

                    }}
                >
                    <div
                        style={{
                            flex: "0 0 250px",
                            display: "flex",
                            alignItems: "start"
                        }}
                    >
                        <p
                            className={'text-white'}
                            style={{
                                fontSize: "16px",
                                fontFamily: "medium",
                                color: "black",
                                margin: "0"
                            }}
                        >Link</p>
                    </div>
                    <div
                        style={{
                            width: "-webkit-fill-available",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flex: "1",
                            flexDirection: "column",
                            gap: "10px"
                        }}
                    >
                        <input
                            className={'bg-dark-400 outline-none text-white border-2-s-dark-500'}
                            onChange={(i) => setWebsite(i.target.value)}
                            placeholder='Website Link'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                height: "65px",
                                width: "100%",
                                padding: "0 30px",
                                fontSize: "16px",

                            }}
                        />
                        <input
                            className={'bg-dark-400 outline-none text-white border-2-s-dark-500'}
                            onChange={(i) => setFacebook(i.target.value)}
                            placeholder='Facebook Link'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                height: "65px",
                                width: "100%",
                                padding: "0 30px",
                                fontSize: "16px",

                            }}
                        />
                        <input
                            className={'bg-dark-400 outline-none text-white border-2-s-dark-500'}
                            onChange={(i) => setInstagram(i.target.value)}
                            placeholder='Instagram Link'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                height: "65px",
                                width: "100%",
                                padding: "0 30px",
                                fontSize: "16px",

                            }}
                        />
                        <input
                            className={'bg-dark-400 outline-none text-white border-2-s-dark-500'}
                            onChange={(i) => setLinkedin(i.target.value)}
                            placeholder='Linkedin Link'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                height: "65px",
                                width: "100%",
                                padding: "0 30px",
                                fontSize: "16px",

                            }}
                        />
                        <input
                            className={'bg-dark-400 outline-none text-white border-2-s-dark-500'}
                            onChange={(i) => setTwitter(i.target.value)}
                            placeholder='Twitter Link'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                height: "65px",
                                width: "100%",
                                padding: "0 30px",
                                fontSize: "16px",

                            }}
                        />
                    </div>
                </div>
                {/*  */}
                <div
                    style={{
                        display: "flex",

                    }}
                >
                    <div
                        style={{
                            flex: "0 0 250px",
                            display: "flex",
                            alignItems: "start"
                        }}
                    >
                        <p
                            className={'text-white'}
                            style={{
                                fontSize: "16px",
                                fontFamily: "medium",
                                margin: "0"
                            }}
                        >Highlight Points</p>
                    </div>
                    <div
                        style={{
                            width: "-webkit-fill-available",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            // flex: "1"
                            flexDirection: "column"
                        }}
                    >
                        <div
                            style={{
                                width: "100%"
                            }}
                        >
                            {
                                highlight.map((i, index) => {
                                    return (
                                        <div
                                            className={'border-2-s-dark-400'}
                                            key={index}
                                            style={{
                                                width: "100%",
                                                border: "2px solid #F6F6F9",
                                                borderRadius: "16px",
                                                padding: "10px 30px",
                                                marginBottom: "10px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                gap: "20px"
                                            }}
                                        >
                                            <p
                                                className={'text-white'}
                                                style={{
                                                    margin: 0,
                                                    fontSize: "16px",
                                                    fontFamily: "medium",
                                                    width: "-webkit-fill-available"
                                                }}
                                            >{i}</p>
                                            <CloseIcon
                                                className={'filter-invert-100'}
                                                onClick={() => {
                                                    const array11 = [...highlight];
                                                    array11.splice(index, 1);
                                                    setHighlight(array11)
                                                }}
                                                style={{
                                                    cursor: "pointer"
                                                }}
                                            />

                                        </div>
                                    )
                                })
                            }

                        </div>

                        <textarea
                            className={'bg-dark-400 outline-none text-white border-2-s-dark-500'}
                            value={highlightText}
                            onChange={(i) => setHighlightText(i.target.value)}
                            placeholder='Write Highlights Points about you'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                width: "100%",
                                padding: "30px",
                                fontSize: "16px",
                                height: "150px"
                            }}
                        ></textarea>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                marginTop: "20px"
                            }}
                        >
                            <div
                                className='bg-dark-400'
                                style={{
                                    height: "2px",
                                    background: "#F6F6F9",
                                    width: "calc(50% - 40px)"
                                }}
                            ></div>
                            <AddRoundIcon
                                style={{
                                    cursor: "pointer"
                                }}
                                onClick={() => {

                                    setHighlight([...highlight, highlightText])
                                    setHighlightText("")

                                }}
                            />
                            <div
                                className='bg-dark-400'
                                style={{
                                    height: "2px",
                                    background: "#F6F6F9",
                                    width: "calc(50% - 40px)"
                                }}
                            ></div>
                        </div>


                    </div>
                </div>
                {/*  */}
                {/*  */}
                <div
                    style={{
                        display: "flex",

                    }}
                >
                    <div
                        style={{
                            flex: "0 0 250px",
                            display: "flex",
                            alignItems: "start"
                        }}
                    >
                        <p
                            className={'text-white'}
                            style={{
                                fontSize: "16px",
                                fontFamily: "medium",
                                margin: "0"
                            }}
                        >Category</p>
                    </div>
                    <SelectMultiple
                        onChangeArray={(i) => setCategories(i)}
                        list={allCategories}
                    />
                </div>
                {/*  */}


            </div>
        )
    }

}

export default AuthorDetails
