import React from 'react'
import theme from '../../../theme/theme'
import CourseContentItem from './courseContentItem'
import '../../../assets/css/courseInfo.css'


const CourseContent = ({ style, courseCategories, courseSections }) => {


    return (
        <div className="overflow-hidden" style={{ ...style }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginBottom: "30px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {
                        courseCategories && courseCategories.length !== 0 ? courseCategories.map((i, index) => {
                            return (
                                <p className="text-primary" key={index} style={{ margin: 0, fontFamily: "semiBold", fontSize: "14px" }}>&nbsp;{i && i.category && i.category.name ? i.category.name : "null"}{courseCategories && courseCategories.length !== 0 && index < courseCategories.length - 1 ? `,` : ""}</p>

                            )
                        })
                            :
                            <p className="text-primary" style={{ margin: 0, fontFamily: "semiBold", fontSize: "14px" }}>No Categories</p>
                    }

                </div>
                <p className='fs-20-phone text-white' style={{ margin: 0, fontFamily: "semiBold", fontSize: "35px" }}>Lesson plan</p>
            </div>

            <div className='bg-dark-500' style={{ backgroundColor: theme.color.gray100, borderRadius: "20px" }}>

                {
                    courseSections && courseSections.length !== 0 ? courseSections.map((i, index) => {

                        return (
                            <CourseContentItem key={index} index={index} topics={i.topics} name={i.name} />
                        )
                    })
                        :
                        <CourseContentItem name={"Finacial Analysis and Valuation"} />
                }


            </div>
        </div>
    )
}

export default CourseContent
