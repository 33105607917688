import React from 'react';

const TopicUncheckIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <circle cx="12" cy="12" r="11.5" stroke="white"/>
        </svg>
    );
};

export default TopicUncheckIcon;