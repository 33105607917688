import React, {useEffect, useState} from 'react'
import api from "../../../services/api";

const UploadChapter = ({saveClick, setSaveClick}) => {

    const [name, setName] = useState('')
    const [slug, setSlug] = useState('')



    const saveFunctionHandle = async () => {
        try{
            const response = await api.createChapter(name, slug);
            console.log("Response ", response);
            if(response?.data?.message) alert(response.data.message);
        }catch (e) {
            console.error(e);
            if(e?.response?.data?.message) alert(e.response.data.message);
        }
    }


    useEffect(() => {
        if (saveClick) {
            saveFunctionHandle()
            setSaveClick(false)
        }

    }, [saveClick])


    return (
        <div
            className='top-cont'
            style={{
                marginTop: "72px",
                display: "flex",
                flexDirection: "column",
                gap: "40px",
                marginBottom: "40px"
            }}
        >



            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Chapter Name</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // flex: "1"
                        flexDirection: "column"
                    }}
                >
                    <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className={'bg-dark-400 border-2-s-dark-500 outline-none text-white'}
                        placeholder='Eg. Matrix'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />

                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Slug</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // flex: "1"
                        flexDirection: "column"
                    }}
                >
                    <input
                        className={'bg-dark-400 border-2-s-dark-500 outline-none text-white'}
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                        placeholder='Eg. matrix'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />

                </div>
            </div>
        </div>
    )
}

export default UploadChapter
