import React from 'react'

const SectionsIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
            <path d="M12.7751 7.02182L14.4753 7.94157L14.8313 8.14154C15.5896 8.56759 15.5896 9.66599 14.8313 10.092L10.002 12.8053C8.88195 13.4345 7.51804 13.4345 6.39803 12.8053L1.56874 10.092C0.810419 9.66599 0.810421 8.56759 1.56874 8.14154L1.92468 7.94157L3.53067 7.02182M13.1449 11.1169L14.6903 11.8948C15.4874 12.296 15.513 13.4318 14.7348 13.869L10.002 16.5281C8.88195 17.1573 7.51804 17.1573 6.39803 16.5281L1.73424 13.9078C0.942716 13.4631 0.98633 12.3022 1.80896 11.919L3.53067 11.1169M10.002 8.84894L14.8313 6.13569C15.5896 5.70964 15.5896 4.61124 14.8313 4.18519L10.002 1.47194C8.88196 0.842686 7.51805 0.842685 6.39803 1.47194L1.56874 4.18519C0.810421 4.61124 0.810419 5.70964 1.56874 6.13569L6.39803 8.84893C7.51804 9.47819 8.88195 9.47819 10.002 8.84894Z" stroke="#FF5612" strokeWidth="1.5" />
        </svg>
    )
}

export default SectionsIcon