import React from 'react'

const SearchIcon = (props) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.9384 14.8006C17.2478 13.2351 18.036 11.2186 18.036 9.01802C18.036 4.03751 13.9985 0 9.01802 0C4.03751 0 0 4.03751 0 9.01802C0 13.9985 4.03751 18.036 9.01802 18.036C10.8247 18.036 12.5073 17.5048 13.9181 16.5899L19.9375 22.6094H23.7472L15.9384 14.8006ZM9.01802 15.7793C5.28387 15.7793 2.25675 12.7522 2.25675 9.01802C2.25675 5.28387 5.28387 2.25675 9.01802 2.25675C12.7522 2.25675 15.7793 5.28387 15.7793 9.01802C15.7793 12.7522 12.7522 15.7793 9.01802 15.7793Z" fill="#999999" />
        </svg>
    )
}

export default SearchIcon