import React from 'react';

const TopicCheckIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <circle cx="12" cy="12" r="11.5" fill="white" stroke="white"/>
            <path d="M15.2559 10.0669C15.5 9.82291 15.5 9.42715 15.2559 9.18301C15.0118 8.939 14.6161 8.939 14.3719 9.18301L10.621 12.9341L9.06689 11.38C8.82288 11.1359 8.42712 11.1359 8.18311 11.38C7.93896 11.6241 7.93896 12.0198 8.18311 12.2638L10.1791 14.2599C10.4232 14.5039 10.8188 14.5039 11.063 14.2599L15.2559 10.0669Z" fill="black" stroke="black" strokeWidth="0.5"/>
        </svg>
    );
};

export default TopicCheckIcon;
