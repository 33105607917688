import React, {useState} from "react";
import theme from "../../../theme/theme";
import checkIcon from "../../../assets/check.svg";

const WhatYouWillLearnLoading = ({courseWillLearn, className}) => {

    const [height, setHeight] = useState(false)

    return (
        <div className={`${className} `}>
            <div className='p-20-phone bg-dark-500' style={{
                padding: "50px",
                background: theme.color.gray100,
                borderRadius: "20px",
                maxHeight: height ? "" : "350px",
                overflow: "hidden"
            }}>
                <div style={{display: "flex", flexDirection: "column", gap: "5px", marginBottom: "30px"}}>
                    <div className='text-primary bg-dark-400 b-radius-10px'
                         style={{
                             margin: 0,
                             fontFamily: "semiBold",
                             fontSize: "14px",
                             height: "17px",
                             width: "75px"
                         }}/>
                    <div className='fs-20-phone text-white bg-dark-400 b-radius-10px w-100p'
                         style={{margin: 0, fontFamily: "semiBold", fontSize: "35px", height: "43px"}}/>
                </div>
                <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>

                    <div style={{display: "flex", alignItems: "start", gap: "10px"}}>
                        <div className="text-gray-100 bg-dark-400 b-radius-10px w-100p" style={{
                            fontFamily: "semiBold",
                            color: "#8D8D8D",
                            margin: "0",
                            fontSize: "14px",
                            height: "17px"
                        }}/>
                    </div>

                </div>

            </div>
            <button
                className="bg-dark-500 "
                onClick={() => setHeight(!height)}
                style={{
                    background: "#F6F6F9",
                    border: "0",
                    width: "100%",
                    height: '50px',
                    borderRadius: "20px",
                    marginTop: "10px"
                }}
            >
                <span
                    className='text-white'
                    style={{
                        fontFamily: "medium",
                        fontSize: "15px",
                        color: "black",
                    }}
                >{height ? "close" : "Read more"}</span>
            </button>
        </div>
    )
}

export default WhatYouWillLearnLoading
