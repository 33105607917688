import React from 'react';

const FacebookWhiteIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g clipPath="url(#clip0_1454_27955)">
                <path d="M31.7812 0H4.21875C1.89253 0 0 1.89253 0 4.21875V31.7812C0 34.1075 1.89253 36 4.21875 36H31.7812C34.1075 36 36 34.1075 36 31.7812V4.21875C36 1.89253 34.1075 0 31.7812 0ZM33.1875 31.7812C33.1875 32.5567 32.5567 33.1875 31.7812 33.1875H23.7656V21.7266H28.1102L28.8281 17.3672H23.7656V14.3438C23.7656 13.1502 24.6814 12.2344 25.875 12.2344H28.7578V7.875H25.875C22.2945 7.875 19.4078 10.7754 19.4078 14.356V17.3672H15.1875V21.7266H19.4078V33.1875H4.21875C3.44334 33.1875 2.8125 32.5567 2.8125 31.7812V4.21875C2.8125 3.44334 3.44334 2.8125 4.21875 2.8125H31.7812C32.5567 2.8125 33.1875 3.44334 33.1875 4.21875V31.7812Z"/>
            </g>
            <defs>
                <clipPath id="clip0_1454_27955">
                    <rect width="36" height="36"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default FacebookWhiteIcon;
