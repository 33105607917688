import React from 'react'

const RoundCloseIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <circle cx="18" cy="18" r="17.5" stroke="#E2E2E2" />
            <path d="M12.3518 24.2638C12.2308 24.2638 12.1126 24.228 12.012 24.1608C11.9114 24.0936 11.8331 23.9981 11.7868 23.8864C11.7405 23.7746 11.7284 23.6517 11.752 23.5331C11.7756 23.4144 11.8338 23.3055 11.9194 23.22L23.2181 11.9213C23.3327 11.8066 23.4883 11.7422 23.6505 11.7422C23.8126 11.7422 23.9682 11.8066 24.0828 11.9213C24.1975 12.036 24.2619 12.1915 24.2619 12.3537C24.2619 12.5158 24.1975 12.6714 24.0828 12.7861L12.7841 24.0848C12.7274 24.1416 12.66 24.1867 12.5858 24.2174C12.5116 24.2481 12.4321 24.2639 12.3518 24.2638Z" fill="black" />
            <path d="M23.6504 24.2638C23.5701 24.2639 23.4906 24.2481 23.4164 24.2174C23.3422 24.1867 23.2748 24.1416 23.218 24.0848L11.9193 12.7861C11.8047 12.6714 11.7402 12.5158 11.7402 12.3537C11.7402 12.1915 11.8047 12.036 11.9193 11.9213C12.034 11.8066 12.1895 11.7422 12.3517 11.7422C12.5139 11.7422 12.6694 11.8066 12.7841 11.9213L24.0828 23.22C24.1683 23.3055 24.2266 23.4144 24.2502 23.5331C24.2738 23.6517 24.2617 23.7746 24.2154 23.8864C24.1691 23.9981 24.0907 24.0936 23.9902 24.1608C23.8896 24.228 23.7714 24.2638 23.6504 24.2638Z" fill="black" />
        </svg>
    )
}

export default RoundCloseIcon