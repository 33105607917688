import React, {useState, useEffect, useMemo} from 'react'
import WhatYouWillLearn from '../../components/courseDetails/whatYouWillLearn'
import theme from '../../theme/theme'
import ThisCourseIncludes from '../../components/courseDetails/thisCourseIncludes'
import CourseContent from '../../components/courseDetails/courseLessonPlan/courseContent'
import Requirements from '../../components/courseDetails/requirements'
import StartRating from '../../components/globle/startRating'
import Instructor from '../../components/courseDetails/instructor'
import Header from '../../components/globle/header'
import PlayBtn from "../../assets/playBtn.svg"
import apis from '../../services/api'
import {useLocation} from 'react-router-dom';
import config from "../../config.json"
import Loading from '../../components/globle/loading'
import '../../assets/css/courseInfo.css'
import courseDummyImage from "../../assets/courseImage.png"
import personDummyImage from "../../assets/images/person.jpg"
import utilities from "../../utility/utilities";
import Button from "../../components/globle/button";
import HeaderLoading from "../../components/loadings/headerLoading";
import CourseContentLoading from "../../components/loadings/courseInfo/courseContentLoading";
import InstructorLoading from "../../components/loadings/courseInfo/InstructorLoading";
import ThisCourseIncludesLoading from "../../components/loadings/courseInfo/ThisCourseIncludesLoading";
import RequirementsLoading from "../../components/loadings/courseInfo/requirementLoading";
import WhatYouWillLearnLoading from "../../components/loadings/courseInfo/whatYouWillLearnLoading";
import Footer from "../../components/globle/footer";
import utility from "../../utility/utilities"

const CourseInfo = ({isAuthenticated, currentUser}) => {
    const {search} = useLocation();

    const [courseImage, setCourseImage] = useState("")
    const [courseName, setCourseName] = useState("")
    const [courseDescription, setCourseDescription] = useState("")
    const [courseStarRating, setCourseStarRating] = useState(0)
    const [studentCount, setStudentCount] = useState("")
    const [discountedPrice, setDiscountedPrice] = useState("")
    const [price, setPrice] = useState("")
    const [courseCategories, setCourseCategories] = useState([])
    const [courseSections, setCourseSections] = useState([])
    const [courseWillLearn, setCourseWillLearn] = useState([])
    const [requirement, setRequirement] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [ecomProductId, setEcomProductId] = useState("")
    const [author, setAuthor] = useState({})
    const [courseIncludes, setCourseIncludes] = useState([])
    const [encodedTokenQuery, setEncodedTokenQuery] = useState("")
    const [affiliateCode, setAffiliateCode] = useState("")
    const [buyLink, setBuyLink] = useState("")


    const location = useLocation();


    const initFunction = async () => {


        const currentUrlPath = location.pathname;
        const urlArray = currentUrlPath.split("/")
        const courseSlug = urlArray[urlArray.length - 1]
        setIsLoading(true)


        try {
            const courseData = await apis.getCourseInfoBySlug(courseSlug);

            setCourseImage(courseData?.data?.data?.course_image_url || "");
            setCourseName(courseData?.data?.data?.name || "")
            setCourseDescription(courseData?.data?.data?.description || "")
            setCourseStarRating(courseData?.data?.data?.star_rating || "")
            setStudentCount(courseData?.data?.data?.student_count || "")
            setDiscountedPrice(courseData?.data?.data?.discounted_price || "")
            setPrice(courseData?.data?.data?.price || "")
            setCourseCategories(courseData?.data?.data?.course_categories || [])
            setCourseSections(courseData?.data?.data?.sections || [])
            setCourseWillLearn(courseData?.data?.data?.will_learns || []);
            setRequirement(courseData?.data?.data?.requirement || "")

            setEcomProductId(courseData?.data?.data?.ecom_product_id || "")
            setAuthor(courseData?.data?.data?.user || [])
            setCourseIncludes(courseData?.data?.data?.course_includes || [])
            setIsLoading(false)

        } catch (e) {
            console.error("Unable to fetch course information")
        }
    }



    const setAffiliateCookie = () => {
        const searchParams = new URLSearchParams(search);
        const afc = searchParams.get('afc');

        if(afc){
            utility.setCookie("afc", afc, 7);
        }
    }

    const findAndSetAffiliate = () => {
        const afc = utility.getCookie("afc");
        if(afc){
            setAffiliateCode(afc)
        }
    }

    useEffect(()=>{

        let link = `${config.ecom_front_end}/course?add-to-cart=${ecomProductId}${affiliateCode ? `&afc=${affiliateCode}` : ""}&redirect_url=${encodedTokenQuery}`

        setBuyLink(link)

    },[affiliateCode, ecomProductId])

    useEffect(() => {

        const token = utilities.getCookie("token")
        const e = utilities.encodeCryptoWithURIComponent(token);
        setEncodedTokenQuery(e)
        initFunction();
        setAffiliateCookie();
        findAndSetAffiliate()
    }, [])


    if (isLoading) {

        return (
            <div className="bg-black">
                <HeaderLoading isAuthenticated={isAuthenticated}/>

                <div id='info' className='top-cont'>

                    <div style={{position: "relative", width: "100%", marginTop: "30px"}}>
                        <div className="hero-section hero-photo-phone bg-dark-500"
                             style={{
                                 aspectRatio: "1280/720",
                                 width: "100%", borderRadius: "20px",

                             }}/>
                        <div className='p-20-phone hero-info-phone' style={{
                            borderRadius: "20px",
                            boxSizing: "border-box",
                            color: "white",
                            padding: "50px",
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "flex-start",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            background: "linear-gradient(0deg, #000 35.59%, rgba(0, 0, 0, 0.00) 78.3%)"
                        }}>
                            <div className={"w-100p"}>
                                <div className='mb-10-phone mb-10-phone'
                                     style={{
                                         display: "flex",
                                         alignItems: "center",
                                         gap: "15px",
                                         marginBottom: "30px"
                                     }}>
                                    <div
                                        className='author-photo-phone bg-dark-500'
                                        style={{
                                            width: "35px",
                                            height: "35px",
                                            borderRadius: "50%"
                                        }}
                                    />
                                    <div className='bg-dark-500 b-radius-10px'
                                         style={{
                                             width: "150px",
                                             height: "17px",
                                             margin: "0",
                                             color: "white",
                                             textTransform: "capitalize",
                                             fontSize: "14px",
                                             fontFamily: "medium"
                                         }}/>
                                </div>
                                <div className='fs-20-phone mb-10-phone bg-dark-500 b-radius-10px w-50p' style={{
                                    textTransform: "capitalize",
                                    fontSize: "30px",
                                    color: "white",
                                    fontFamily: "semiBold",
                                    marginTop: "0",
                                    marginBottom: "10px",
                                    height: "36px"
                                }}/>
                                <div className='w-100p-phone fs-12-phone mb-10-phone bg-dark-500 b-radius-10px' style={{
                                    textTransform: "capitalize",
                                    fontSize: "14px",
                                    color: "#C3C3C3",
                                    marginTop: "0",
                                    width: "60%",
                                    marginBottom: "30px",
                                    height: "34px"
                                }}/>

                                <div className='mt-20-phone' style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%",
                                    justifyContent: "center",
                                    gap: "5px",
                                    marginTop: "20px"
                                }}>
                                    {/* <p style={{ fontSize: "12px", color: "white", fontFamily: "medium", margin: "0" }}>Price</p> */}
                                    <div className='d-flex j-c-s-b g-20-phone flex-wrap'>
                                        <div className='d-flex g-10 a-i-center'>
                                            <div className='price-phone bg-dark-500 b-radius-10px'
                                                 style={{
                                                     fontSize: "30px",
                                                     color: "white",
                                                     fontFamily: "semiBold",
                                                     margin: "0",
                                                     height: "36px",
                                                     width: "80px"
                                                 }}/>
                                            <div
                                                className='fs-12-phone bg-dark-500 b-radius-10px'
                                                style={{
                                                    fontSize: "20px",
                                                    color: "white",
                                                    fontFamily: "regular",
                                                    margin: "0",
                                                    textDecoration: "line-through",
                                                    height: "24px",
                                                    width: "63px"
                                                }}/>
                                        </div>

                                        <a
                                            style={{
                                                textDecoration: "none"
                                            }}
                                            href={buyLink}>
                                            <button className='btn btn-phone bg-dark-500 bg-dark-500-phone buy-now-button-phone'
                                                    style={{
                                                        width: "191px",
                                                        height: "48px"
                                                    }}
                                            ></button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className='top-cont p-10-phone'
                     style={{marginTop: "60px", padding: "0 60px", boxSizing: "border-box"}}>
                    <div>
                        <CourseContentLoading style={{marginBottom: "35px"}}/>
                    </div>
                    <div className='grid-template-100-phone' style={{
                        display: "grid",
                        gridTemplateColumns: `calc(60% - 35px) 40%`,
                        gap: "35px",
                        boxSizing: "border-box",
                        marginBottom: "35px"
                    }}>
                        <InstructorLoading className='overflow-hidden-phone w-100p-phone overflow-hidden'
                                           author={author}/>
                        <ThisCourseIncludesLoading className='overflow-hidden-phone overflow-hidden'
                                                   courseIncludes={courseIncludes}/>
                    </div>
                    <div className='grid-template-100-phone' style={{
                        display: "grid",
                        gridTemplateColumns: `calc(60% - 35px) 40%`,
                        gap: "35px",
                        boxSizing: "border-box"
                    }}>
                        <RequirementsLoading className="overflow-hidden-phone overflow-hidden"
                                             requirement={requirement}/>
                        <WhatYouWillLearnLoading className="overflow-hidden-phone overflow-hidden"
                                                 courseWillLearn={courseWillLearn}/>
                    </div>


                </div>


            </div>
        )

    } else {
        return (
            <div className="bg-black">
                <Header currentUser={currentUser} isAuthenticated={isAuthenticated}/>

                <div id='info' className='top-cont'>

                    <div style={{position: "relative", width: "100%", marginTop: "30px"}}>
                        <img className="hero-section hero-photo-phone" alt='images'
                             style={{
                                 aspectRatio: "1280/720",
                                 width: "100%", borderRadius: "20px",
                                 objectFit: "cover",

                             }} src={courseImage ? courseImage : courseDummyImage}/>
                        <div className='p-20-phone hero-info-phone' style={{
                            borderRadius: "20px",
                            boxSizing: "border-box",
                            color: "white",
                            padding: "50px",
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "flex-start",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            background: "linear-gradient(0deg, #000 35.59%, rgba(0, 0, 0, 0.00) 78.3%)"
                        }}>
                            <div className={"w-100p"}>
                                <div className='mb-10-phone mb-10-phone'
                                     style={{
                                         display: "flex",
                                         alignItems: "center",
                                         gap: "15px",
                                         marginBottom: "30px"
                                     }}>
                                    <img
                                        className='author-photo-phone'
                                        style={{
                                            width: "35px",
                                            height: "35px",
                                            objectFit: "cover",
                                            borderRadius: "50%"
                                        }}
                                        src={author && author.avatar ? author.avatar : personDummyImage}
                                        alt="author name"/>
                                    <p style={{
                                        margin: "0",
                                        color: "white",
                                        textTransform: "capitalize",
                                        fontSize: "14px",
                                        fontFamily: "medium"
                                    }}>{author.first_name && author.last_name ? author.first_name + " " + author.last_name : "Zebra Learn"}</p>
                                </div>
                                <p className='fs-20-phone mb-10-phone' style={{
                                    textTransform: "capitalize",
                                    fontSize: "30px",
                                    color: "white",
                                    fontFamily: "semiBold",
                                    marginTop: "0",
                                    marginBottom: "10px"
                                }}>{courseName ? courseName : "Financial Modelling And Valuation"}</p>
                                <p className='w-100p-phone fs-12-phone mb-10-phone' style={{
                                    textTransform: "capitalize",
                                    fontSize: "14px",
                                    color: "#C3C3C3",
                                    marginTop: "0",
                                    width: "60%",
                                    marginBottom: "30px"
                                }}>{courseDescription ? courseDescription : "Explore the fundamentals of finance with our comprehensive course. Covering topics such as financial markets, investment strategies, risk management, and financial analysis, "}</p>

                                <div className='mt-20-phone' style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%",
                                    justifyContent: "center",
                                    gap: "5px",
                                    marginTop: "20px"
                                }}>
                                    {/* <p style={{ fontSize: "12px", color: "white", fontFamily: "medium", margin: "0" }}>Price</p> */}
                                    <div className='d-flex j-c-s-b g-20-phone flex-wrap'>
                                        <div className='d-flex g-10 a-i-center'>
                                            <p className='price-phone'
                                               style={{
                                                   fontSize: "30px",
                                                   color: "white",
                                                   fontFamily: "semiBold",
                                                   margin: "0"
                                               }}>₹{discountedPrice ? discountedPrice : "990"}</p>
                                            <p
                                                className='fs-12-phone'
                                                style={{
                                                    fontSize: "20px",
                                                    color: "white",
                                                    fontFamily: "regular",
                                                    margin: "0",
                                                    textDecoration: "line-through"
                                                }}>₹{price ? price : "2990"}</p>
                                        </div>
                                        <a
                                            style={{
                                                textDecoration: "none"
                                            }}
                                            href={buyLink}>
                                            <button className='btn btn-phone'>BUY COURSE</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className='top-cont p-10-phone'
                     style={{marginTop: "60px", padding: "0 60px", boxSizing: "border-box"}}>
                    <div>
                        <CourseContent courseSections={courseSections} courseCategories={courseCategories}
                                       style={{marginBottom: "35px"}}/>
                    </div>
                    <div className='grid-template-100-phone' style={{
                        display: "grid",
                        gridTemplateColumns: `calc(60% - 35px) 40%`,
                        gap: "35px",
                        boxSizing: "border-box",
                        marginBottom: "35px"
                    }}>
                        <Instructor className='overflow-hidden-phone w-100p-phone overflow-hidden' author={author}/>
                        <ThisCourseIncludes className='overflow-hidden-phone overflow-hidden'
                                            courseIncludes={courseIncludes}/>
                    </div>
                    <div className='grid-template-100-phone' style={{
                        display: "grid",
                        gridTemplateColumns: `calc(60% - 35px) 40%`,
                        gap: "35px",
                        boxSizing: "border-box"
                    }}>
                        <Requirements className="overflow-hidden-phone overflow-hidden" requirement={requirement}/>
                        <WhatYouWillLearn className="overflow-hidden-phone overflow-hidden"
                                          courseWillLearn={courseWillLearn}/>
                    </div>


                </div>
                <Footer/>
            </div>
        )
    }


}

export default CourseInfo
