import React, {useEffect, useState} from 'react'
import ZebralearnLogo from '../../assets/icons/zebralearn-logo.svg'
import SearchBar from './searchBar'
import BellIcon from "../../assets/bell.svg"
import CartIcon from "../../assets/cart.svg"
import {useNavigate} from 'react-router-dom'
import theme from '../../theme/theme'
import api from '../../services/api'
import utilities from '../../utility/utilities'
import "../../assets/css/header.css"


const Header = ({styles, searchValue, isAuthenticated, onSearch, isAdmin, currentUser}) => {

    const navigate = useNavigate();

    const [inputValue, setInputValue] = useState("")
    const [isManuShow, setIsMenuShow] = useState(false)
    const [isMyLearningDropdown, setIsMyLearningDropdown] = useState(true)
    const [isCartDropdown, setIsCartDropdown] = useState(true)
    const [isProfileDropdown, setIsProfileDropdown] = useState(true)
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false)

    const searchHandle = async (event) => {
        if (event.key === 'Enter') {

            navigate(`/search/${inputValue ? inputValue : ""}`)


            if (onSearch) {
                onSearch()
            }

        }

    }

    const signOutHandle = async () => {
        utilities.setCookie("token", "", 20)
        navigate("/")
        window.location.reload()
    }

    const signInHandle = async () => {
        navigate("/auth/signin")
    }



    return (
        <div className=' px-10-phone position-sticky top-0 bd-f-blur-5' style={{
            display: 'flex',
            padding: "5px 50px",
            alignItems: 'center',
            height: "75px", ...styles,
            justifyContent: "space-between",
            zIndex: "10000"
        }}>
            <a href="https://zebralearn.com" >
            <img className='logo p-0-phone'  alt='zebralearn' src={ZebralearnLogo}
                 style={{
                     cursor: 'pointer',
                     width: "144px"
                 }}
            />
            </a>

            <div className='d-none-phone d-none' style={{width: "600px"}}>
                <SearchBar onChange={(i) => {
                    setInputValue(i.target.value)
                }} onKeyDown={(event) => searchHandle(event)} mystyle={{margin: "0 10px"}}/>
            </div>
            {/* <p style={{ margin: "0px", cursor: "pointer", whiteSpace: "nowrap", padding: "0 10px" }}>Contact us</p> */}
            {/* <p style={{ margin: "0px", cursor: "pointer", whiteSpace: "nowrap", padding: "0 10px" }}>Earn with Us!</p> */}
            <div style={{display: 'flex', alignItems: 'center', height: "65px"}}>
                {
                    isAuthenticated ?
                        <></>
                        :
                        <p
                            className='fs-12-phone bg-white text-black border-2-s-dark-400'
                            onClick={() => navigate("/auth/signin")}
                            style={{
                                margin: "0px",
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                                padding: "9px 20px",
                                fontSize: "14px",
                                fontFamily: "semiBold",
                                background: "#F6F6F9",
                                borderRadius: "12px",
                                textTransform: "capitalized"
                            }}

                        >Login</p>
                }

                {
                    isAuthenticated ?

                        <div
                            onClick={() => setIsProfileDropdown(!isProfileDropdown)}
                            style={{
                                padding: "8px 16px 8px 8px",
                                borderRadius: "50px",
                                position: "relative"
                            }}
                            className="d-flex a-i-center g-10  bg-dark-400 cursor-pointer">


                            <div className='bg-dark-500' style={{
                                display: isProfileDropdown ? "none" : "",
                                position: "absolute",
                                top: "71px",
                                right: "0px",
                                width: "250px",
                                background: "white",
                                boxSizing: "border-box",
                                zIndex: "10000000",
                                backgroundColor: theme.color.gray100,
                                borderRadius: "10px",
                            }}>
                                <div className='border-bottom border-black overflow-hidden' style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "13px",
                                    justifyContent: "start"
                                }}>
                                    <div
                                        className="overflow-hidden"

                                        style={{
                                        fontFamily: "semiBold",
                                        textTransform: "uppercase",
                                        background: "black",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "50%",
                                        minWidth: "35px",
                                        minHeight: "35px",
                                        width: "35px",
                                        height: "35px",
                                        color: "white",
                                        fontSize: "12px",
                                        marginRight: "10px",
                                        cursor: "pointer",

                                    }}>{currentUser && currentUser.first_name && currentUser.first_name[0] && currentUser.first_name[1] ? currentUser.first_name[0] + currentUser.first_name[1] : "S"}</div>
                                    <div
                                        className='overflow-hidden'
                                        style={{
                                            width: "185px"
                                        }}
                                    >
                                        <p className="text-white"
                                           style={{
                                               margin: "0",
                                               fontSize: "15px"
                                           }}>{currentUser?.first_name || "User"}</p>
                                        <p className='text-gray-100 overflow-hidden white-space-nowrap text-overflow-ellipsis' style={{
                                            margin: "0",
                                            fontWeight: "400",
                                            fontSize: "12px",
                                            color: theme.textGray,
                                        }}>{currentUser?.email || "null@email.com"}</p>
                                    </div>
                                </div>
                                <div className='border-bottom border-black'>
                                    <p onClick={() => navigate("/my-courses")}
                                       className='text-hover-primary text-white' style={{
                                        margin: "0",
                                        fontSize: "14px",
                                        padding: "10px 20px",
                                        cursor: "pointer"
                                    }}>My Courses</p>
                                    {
                                        isAdmin ?
                                            <p onClick={() => navigate('/admin/courses')}
                                               className='text-hover-primary text-white' style={{
                                                margin: "0",
                                                fontSize: "14px",
                                                padding: "10px 20px",
                                                cursor: "pointer"
                                            }}>Admin</p>
                                            :
                                            <></>
                                    }

                                </div>
                                <div>
                                    {
                                        isAuthenticated
                                            ?
                                            <p
                                                onClick={signOutHandle}
                                                className='text-hover-primary text-white'
                                                style={{
                                                    cursor: "pointer",
                                                    margin: "0",
                                                    fontSize: "14px",
                                                    padding: "20px 20px"
                                                }}>Sign Out</p>
                                            :
                                            <p
                                                onClick={signInHandle}
                                                className='text-hover-primary text-white'
                                                style={{
                                                    cursor: "pointer",
                                                    margin: "0",
                                                    fontSize: "14px",
                                                    padding: "20px 20px"
                                                }}>Sign In</p>

                                    }

                                </div>
                            </div>


                            <div
                                className='overflow-hidden b-rounded-circle bg-primary text-white ff-bold text-transform-uppercase d-flex a-i-center j-c-center fs-12 overflow-hidden'
                                style={{
                                    width: "32px",
                                    height: "32px",
                                    minWidth: "32px",
                                    minHeight: "32px",

                                }}
                            >
                                {currentUser && currentUser.first_name && currentUser.first_name[0] && currentUser.first_name[1] ? currentUser.first_name[0] + currentUser.first_name[1] : "S"}
                            </div>
                            <div className='d-flex flex-column'
                                 style={{
                                     gap: "6px"
                                 }}
                            >
                                <div className='bg-white b-radius-10px'
                                     style={{
                                         width: "14px",
                                         height: "2px",

                                     }}
                                ></div>
                                <div className='bg-white b-radius-10px'
                                     style={{
                                         width: "14px",
                                         height: "2px",

                                     }}
                                ></div>
                            </div>
                        </div>
                        :
                        <></>

                }


            </div>
        </div>
    )
}

export default Header
