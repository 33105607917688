import React from 'react'

const CloseIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
            <path d="M0.611516 12.5216C0.490576 12.5216 0.372345 12.4858 0.27178 12.4186C0.171214 12.3514 0.0928318 12.2559 0.0465469 12.1442C0.000261904 12.0324 -0.0118461 11.9095 0.0117544 11.7909C0.0353549 11.6723 0.0936037 11.5633 0.179133 11.4778L11.4778 0.179099C11.5925 0.0644239 11.748 0 11.9102 0C12.0724 0 12.2279 0.0644239 12.3426 0.179099C12.4573 0.293774 12.5217 0.449307 12.5217 0.611482C12.5217 0.773658 12.4573 0.929191 12.3426 1.04387L1.0439 12.3426C0.98717 12.3994 0.919769 12.4445 0.845568 12.4752C0.771366 12.5059 0.691826 12.5217 0.611516 12.5216Z" fill="black" />
            <path d="M11.9102 12.5216C11.8299 12.5217 11.7503 12.5059 11.6761 12.4752C11.6019 12.4445 11.5345 12.3994 11.4778 12.3426L0.179099 1.04387C0.0644239 0.929191 0 0.773658 0 0.611482C0 0.449307 0.0644239 0.293774 0.179099 0.179099C0.293774 0.0644239 0.449307 0 0.611483 0C0.773658 0 0.929191 0.0644239 1.04387 0.179099L12.3426 11.4778C12.4281 11.5633 12.4864 11.6723 12.51 11.7909C12.5336 11.9095 12.5214 12.0324 12.4752 12.1442C12.4289 12.2559 12.3505 12.3514 12.2499 12.4186C12.1494 12.4858 12.0311 12.5216 11.9102 12.5216Z" fill="black" />
        </svg>
    )
}

export default CloseIcon