import React from 'react'
import ZebraLearnWhiteLogo from "../../assets/icons/zebralearn-logo.svg"
import Facebook from "../../assets/facebookWhite.svg"
import Instagram from "../../assets/instagramWhite.svg"
import Linkedin from "../../assets/linkedInWhite.svg"
import Youtube from "../../assets/icons/youtubeIcon"
import FacebookIcon from "../../assets/icons/facebookIcon";
import FacebookWhiteIcon from "../../assets/icons/facebookWhiteIcon";
import LinkedinIcon from "../../assets/icons/linkedinIcon";
import InstagramIcon from "../../assets/icons/instagramIcon";
import InstagramWhiteIcon from "../../assets/icons/instagramWhiteIcon";
import "../../assets/css/footer.css"

const Footer = () => {
    return (
        <div
            style={{
                background: "#131313",
            }}
        >
            <div
                className='py-20-phone'
                style={{
                    background: "#131313",
                    padding: '112px 0',
                    marginTop: "60px"
                }}>
                <div className='top-cont top-cont-tab top-cont-minitab mx-20-phone'>
                    <div
                        className='col-3-tab col-1-phone col-2-minitab'
                        style={{
                            display: "grid",
                            gridTemplateColumns: "auto auto auto auto auto",
                            columnGap: "20px"
                        }}
                    >
                        <div
                            className='logo-cont-phone '
                            style={{
                                marginRight: "100px",
                            }}
                        >
                            <img
                                src={ZebraLearnWhiteLogo}
                                style={{
                                    width: "200px",
                                    marginBottom: "30px"
                                }}
                            />
                            <div>
                                <p
                                    className='mt-0 fs-14 ff-medium text-decoration-none text-white'
                                >
                                    Follow us on
                                </p>
                                <div className='d-flex a-i-center g-16'>
                                    <a href={"https://www.instagram.com/zebra_learn/"}>
                                        <InstagramWhiteIcon
                                            className={'fill-gray-100 fill-hover-white cursor-pointer'}
                                            style={{
                                                width: "18px",
                                                height: "18px"
                                            }}
                                        />
                                    </a>
                                    <a href={"https://www.youtube.com/@zebralearn9279"}>
                                        <Youtube
                                            className={'fill-gray-100 fill-hover-white cursor-pointer'}
                                            style={{
                                                width: "18px",
                                                height: "18px"
                                            }}/>
                                    </a>
                                    <a href={"https://www.facebook.com/Zebralearn?mibextid=LQQJ4d"}>
                                        <FacebookWhiteIcon
                                            className={'fill-gray-100 fill-hover-white cursor-pointer'}
                                            style={{
                                                width: "18px",
                                                height: "18px"
                                            }}/>
                                    </a>

                                    <a href={"https://www.linkedin.com/company/zebra-learn/?viewAsMember=true"}>
                                        <LinkedinIcon
                                            className={'fill-gray-100 fill-hover-white cursor-pointer'}
                                            style={{
                                                width: "18px",
                                                height: "18px"
                                            }}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className={'d-flex  flex-column footer-cont-phone'}>
                            <div style={{
                                marginBottom: "40px"
                            }}>
                                <h1 className='fs-16 ff-semibold text-white mt-0'
                                    style={{
                                        marginBottom: "15px"
                                    }}
                                >For Traders</h1>
                                <ul className='p-0 d-flex flex-column m-0'
                                    style={{
                                        gap: "10px",
                                        listStyleType: "none"
                                    }}
                                >
                                    <li><a
                                        href={"https://go.zebralearn.com/trading-mastermind?_gl=1*6upa8k*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDA2NTkuNjAuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Trading
                                        Mastermind</a></li>
                                    <li><a
                                        href={"https://go.zebralearn.com/futures-options-blueprint?_gl=1*6upa8k*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDA2NTkuNjAuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>
                                        Futures & Options Blueprint</a></li>
                                    <li><a
                                        href={"https://go.zebralearn.com/51-trading-strategies?_gl=1*6upa8k*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDA2NTkuNjAuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>51
                                        Trading Strategies</a></li>
                                </ul>
                            </div>
                            <div>
                                <h1 className='fs-16 ff-semibold text-white mt-0'
                                    style={{
                                        marginBottom: "15px"
                                    }}
                                >Business Books
                                </h1>
                                <ul className='p-0 d-flex flex-column m-0'
                                    style={{
                                        gap: "10px",
                                        listStyleType: "none"
                                    }}
                                >
                                    <li><a
                                        href={"https://go.zebralearn.com/mindful-marketing?_gl=1*1e7t1tm*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDA2NTkuNjAuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Mindful
                                        Marketing</a></li>
                                    <li><a
                                        href={"https://go.zebralearn.com/hr-mastermind?_gl=1*1e7t1tm*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDA2NTkuNjAuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>HR
                                        Mastermind</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className={'d-flex  flex-column footer-cont-phone'}>
                            <div style={{
                                marginBottom: "40px"
                            }}>
                                <h1 className='fs-16 ff-semibold text-white mt-0'
                                    style={{
                                        marginBottom: "15px"
                                    }}
                                >For Investors</h1>
                                <ul className='p-0 d-flex flex-column m-0'
                                    style={{
                                        gap: "10px",
                                        listStyleType: "none"
                                    }}
                                >
                                    <li><a
                                        href={"https://go.zebralearn.com/money-smart?_gl=1*n2pj5p*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDA2NTkuNjAuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Money
                                        Smart in your 20s & 30s</a></li>
                                    <li><a
                                        href={"https://go.zebralearn.com/stock-investing-mastermind-2?_gl=1*n2pj5p*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDA2NTkuNjAuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Stock
                                        Investing Mastermind</a></li>
                                </ul>
                            </div>
                            <div>
                                <h1 className='fs-16 ff-semibold text-white mt-0'
                                    style={{
                                        marginBottom: "15px"
                                    }}
                                >Quick Links</h1>
                                <ul className='p-0 d-flex flex-column m-0'
                                    style={{
                                        gap: "10px",
                                        listStyleType: "none"
                                    }}
                                >
                                    <li><a
                                        href={"https://zebralearn.com/product"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Books
                                        & Courses</a></li>
                                    <li><a
                                        href={"https://zebralearn.com/schools"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Schools</a>
                                    </li>
                                    <li><a
                                        href={"https://studio.zebralearn.com/?_gl=1*173yd8p*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDA2NTkuNjAuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Authors</a>
                                    </li>
                                    <li><a
                                        href={"https://studio.zebralearn.com/?_gl=1*1telqcc*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDA2NTkuNjAuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Partnerships
                                        & Collaborations</a></li>
                                    <li><a
                                        href={"https://zebralearn.com/auth/login"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Login</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={'d-flex  flex-column footer-cont-phone'}>
                            <div style={{
                                marginBottom: "40px"
                            }}>
                                <h1 className='fs-16 ff-semibold text-white mt-0'
                                    style={{
                                        marginBottom: "15px"
                                    }}
                                >For Finance Students</h1>
                                <ul className='p-0 d-flex flex-column m-0'
                                    style={{
                                        gap: "10px",
                                        listStyleType: "none"
                                    }}
                                >
                                    <li><a
                                        href={"https://go.zebralearn.com/financial-modelling-handbook?_gl=1*1telqcc*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDA2NTkuNjAuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Financial
                                        Modeling Handbook</a></li>
                                    <li><a
                                        href={"https://go.zebralearn.com/financial-statement-analysis?_gl=1*13tipty*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDA2NTkuNjAuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Financial
                                        Statement Analysis Handbook</a></li>

                                </ul>
                            </div>
                            <div>
                                <h1 className='fs-16 ff-semibold text-white mt-0'
                                    style={{
                                        marginBottom: "15px"
                                    }}
                                >Company</h1>
                                <ul className='p-0 d-flex flex-column m-0'
                                    style={{
                                        gap: "10px",
                                        listStyleType: "none"
                                    }}
                                >
                                    <li><a
                                        href={"https://zebralearn.com/about_us/"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>About
                                        Us</a></li>
                                    <li><a
                                        href={"https://zebralearn.com/contact"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Contact
                                        Us</a></li>
                                    <li><a
                                        href={"https://studio.zebralearn.com/?_gl=1*qa761s*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDEyMTYuMTkuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Careers</a>
                                    </li>
                                    <li><a
                                        href={"https://studio.zebralearn.com/?_gl=1*1vete6m*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDEyMTYuMTkuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>For
                                        Designer</a></li>
                                    <li><a
                                        href={"https://studio.zebralearn.com/?_gl=1*1vete6m*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDEyMTYuMTkuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>For
                                        Writers</a></li>
                                    <li><a
                                        href={"https://zebralearn.com/Privacy_Policy_Reader.html"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Terms
                                        & Conditions</a></li>
                                    <li><a
                                        href={"https://zebralearn519766423.wpcomstaging.com/refunds-and-cancellation-policy/"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Refund
                                        Policy</a></li>
                                    <li><a
                                        href={"https://support.zebralearn.com/support/home?_gl=1*5v411l*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDEyMTYuMTkuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Help
                                        & Support</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className={'d-flex  flex-column '}>
                            <div style={{
                                marginBottom: "40px"
                            }}>
                                <h1 className='fs-16 ff-semibold text-white mt-0'
                                    style={{
                                        marginBottom: "15px"
                                    }}
                                >For Entrepreneurs</h1>
                                <ul className='p-0 d-flex flex-column m-0'
                                    style={{
                                        gap: "10px",
                                        listStyleType: "none"
                                    }}
                                >
                                    <li><a
                                        href={"https://go.zebralearn.com/the-industry-handbook?_gl=1*vdp6s3*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDEyMTYuMTkuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>The
                                        Industry Handbook</a></li>
                                    <li><a
                                        href={"https://go.zebralearn.com/fundraising-decoded?_gl=1*vdp6s3*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDEyMTYuMTkuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Fundraising
                                        Decoded</a></li>
                                    <li><a
                                        href={"https://go.zebralearn.com/fundraising-decoded?_gl=1*vdp6s3*_ga*MjgwNjM5MzM3LjE3MDEyMzYwOTQ.*_ga_21G196HESG*MTcwOTA5ODg3Ny4zMC4xLjE3MDkxMDEyMTYuMTkuMC4w"}
                                        className='fs-14 ff-medium text-decoration-none text-gray-100 text-hover-white cursor-pointer'>Startup
                                        Finance 360</a></li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <hr className='border-0 bg-dark-400 m-0'
                style={{
                    height: "1px"
                }}
            />
            <div className={'top-cont p-10 m-0-phone'}>
                <p className='text-gray-100 fs-12 m-0 '>© 2023 Zebra Learn. All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer
