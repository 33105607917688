import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import theme from '../../../theme/theme'
import Header from '../../../components/globle/header'
import Button from '../../../components/globle/button'
import UploadCourse from '../createCourse/uploadCourse'
import SectionTopic from '../createCourse/sectionTopic'
import UpdateCourse from './updateCourse'
import UpdateSectionTopic from './updateSectionTopic'

const EditCourse = ({ isAuthenticated }) => {
    const [section, setSection] = useState(1)
    const [saveClick, setSaveClick] = useState(false)
    const [resSlug, setResSlug] = useState("")

    const location = useLocation()
    const navigate = useNavigate()


    const fetchCourseDetails = async () => {
        const urlPath = location.pathname;
        const urlPathArray = urlPath.split("/")
        const slug = urlPathArray[urlPathArray.length - 1]
        setResSlug(slug)
    }

    useEffect(()=>{
        fetchCourseDetails()
    },[])
    return (
        <div className={'bg-black text-white'}>
            <div
                style={{
                    boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)"
                }}
            >
                <Header isAuthenticated={isAuthenticated} />
            </div>
            <div
                className='top-cont'
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "20px",
                    padding: "20px 0px"
                }}
            >{
                section === 1
                ?
                    <Button
                        className={'btn-dark-500'}
                        onClick={() => setSaveClick(true)}
                        title={"Save & Update"}
                    />
                    : <div style={{
                        height: "46px",
                    }}></div>

            }

            </div>
            <div
                className={'border-bottom-solid border-bottom-2 border-dark-400'}

            >



                <div className='top-cont'>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "flex-start",
                            gap: "90px",
                        }}
                    >


                        <div
                            onClick={() => setSection(1)}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: "20px"
                            }}
                        >
                            <span
                                className={`${section === 1 ? 'text-primary' : 'text-white'}`}
                                style={{
                                    fontFamily: "semiBold",
                                    fontSize: "20px",
                                }}
                            >Update Course</span>

                            <div
                                style={{
                                    width: "100%",
                                    height: "4px",
                                    background: theme.primary,
                                    borderRadius: "20px",
                                    visibility: section === 1 ? "visible" : "hidden"
                                }}
                            ></div>
                        </div>



                        <div
                            onClick={() => setSection(2)}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                gap: "20px"
                            }}
                        >
                            <span
                                className={`${section === 2 ? 'text-primary' : 'text-white'}`}
                                style={{
                                    fontFamily: "semiBold",
                                    fontSize: "20px",
                                }}
                            >Sections & Topics</span>
                            <div
                                style={{
                                    width: "100%",
                                    height: "4px",
                                    borderRadius: "20px",
                                    visibility: section === 2 ? "visible" : "hidden",
                                    background: theme.primary
                                }}
                            ></div>
                        </div>

                    </div>
                </div>
            </div>
            {
                section === 1 ?
                    <div>
                        <UpdateCourse setSection={setSection} resSlug={resSlug} saveClick={saveClick} setSaveClick={setSaveClick}/>
                    </div>
                    :
                    <div>
                        <UpdateSectionTopic resSlug={resSlug}/>
                    </div>
            }


        </div>
    )
}

export default EditCourse
