import React from 'react'
import theme from '../../theme/theme'
import '../../assets/css/courseInfo.css'
import checkIcon from "../../assets/check.svg"

const ThisCourseIncludes = ({ courseIncludes, className }) => {
    return (
        <div className={`${className} p-20-phone bg-dark-500`} style={{ padding: "50px", background: theme.color.gray100, borderRadius: "20px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginBottom: "30px" }}>
                <p className="text-primary" style={{ margin: 0, fontFamily: "semiBold", fontSize: "14px" }}>What you get</p>
                <p className='fs-20-phone text-white' style={{ margin: 0, fontFamily: "semiBold", fontSize: "35px" }}>Course includes</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                {
                    courseIncludes && courseIncludes.length !== 0 ? courseIncludes.map((i, index) => {
                        return (
                            <div key={index} style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                                <img className="filter-invert-100" style={{ width: "20px", height: "20px" }} src={checkIcon} />
                                <p className="text-white" style={{ margin: "0", fontFamily: "semiBold", fontSize: "14px" }}>{i.description}</p>
                            </div>
                        )
                    })
                        :
                        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                            <div style={{ width: "40px", height: "40px", background: "white", borderRadius: "50%" }}></div>
                            <p style={{ margin: "0", fontFamily: "semiBold", fontSize: "14px" }}>Dummy course includes</p>
                        </div>
                }

            </div>
        </div>
    )
}

export default ThisCourseIncludes