import React, {useEffect, useState} from 'react'
import CloseIcon from '../../../assets/icons/closeIcon'
import AddRoundIcon from '../../../assets/icons/addRoundIcon'
import AddImageIcon from '../../../assets/icons/addImageIcon'
import SelectMultiple from '../../../components/globle/selectMultiple'
import SelectDropdown from '../../../components/globle/selectDropdown'
import ecomApi from '../../../services/ecomApi'
import api from '../../../services/api'
import SelectOne from '../../../components/globle/selectOne'
import Loading from "../../../components/globle/loading";
import LoadingAnimation from "../../../components/globle/loadingAnimation";
import {useNavigate} from "react-router-dom";

const UploadCourse = ({saveClick, setSaveClick, setCreatedCourseId}) => {
    const [ecomProductNames, setEcomProductNames] = useState([])
    const [ecomProductImages, setEcomProductImages] = useState([])
    // uncomment start
    const [selectedEcomProductIndex, setSelectedEcomProductIndex] = useState("")
    // uncomment end
    // remove start
    // const [selectedEcomProductIndex, setSelectedEcomProductIndex] = useState(" ")
    // remove end
    const [allAuthorsName, setAllAuthorsName] = useState([])
    const [allAuthorsImage, setAllAuthorsImage] = useState([])
    const [selectedAuthorIndex, setselectedAuthorIndex] = useState("")
    const [imageUploadingLoading, setImageUploadingLoading] = useState(false)
    const [courseImageUrl, setCourseImageUrl] = useState("")
    const [courseName, setCourseName] = useState("")
    const [slug, setSlug] = useState("")
    const [description, setDescription] = useState("")
    const [studentCount, setStudentCount] = useState("")
    const [starRating, setStarRating] = useState("")
    const [actualPrice, setActualPrice] = useState("")
    const [cutPrice, setCutPrice] = useState("")
    const [categories, setCategories] = useState([0])
    const [allCategories, setAllCategories] = useState([])
    const [categoryRes, setCategoryRes] = useState([])
    const [willLearn, setWillLearn] = useState([])
    const [courseIncludes, setCourseIncludes] = useState([])
    const [willLearnText, setWillLearnText] = useState("")
    const [courseIncludesText, setCourseIncludesText] = useState("")
    const [featuredReviewId, setFeaturedReviewId] = useState("")
    const [requirement, setRequirement] = useState("")
    const [levels, setLevels] = useState("")
    const [selectedLevel, setSelectedLevel] = useState(0)
    const [languages, setLanguages] = useState()
    const [selectedLanguage, setSelectedLanguage] = useState(0)
    const [ecomProductResponse, setEcomProductResponse] = useState([])
    const [authorsResponse, setAuthorsResponse] = useState()
    const [levelsResponce, setLevelsResponce] = useState()
    const [languagesResponse, setLanguagesResponse] = useState()
    const [resCategoriesIdArray, setResCategoriesIdArray] = useState([])
    const [fileId, setFileId] = useState("")
    const [ecomCurrentPage, setEcomCurrentPage] = useState(1)
    const [ecomProductResponseMeta, setEcomProductResponseMeta] = useState(1)



    const navigate = useNavigate()


    const getCategories = async () => {
        try {
            const response = await api.getCategories();
            const dataArray = response.data.data;
            setCategoryRes(dataArray)
            let newArray = []
            for (let i = 0; i < dataArray.length; i++) {
                newArray.push(dataArray[i].name)
            }
            setAllCategories(newArray)
        } catch (e) {
            alert("Unable to fetch categories")
        }
    }
    const firstCallHandle = async (page) => {
        try {


            // uncomment start
            const response = await ecomApi.getEcomCourses(page ? page : 1);

            const data = response?.data?.data || []
            const meta = response?.data?.meta || []

            setEcomProductResponse(data)
            setEcomProductResponseMeta(meta)

            // uncomment end

            let productIdArray = [];
            let productImagesArray = [];
            if (data.length !== 0) {
                for (let i = 0; i < data.length; i++) {
                    productIdArray.push(data[i].name)
                    productImagesArray.push(data[i].thumbnail_image)
                }

                setEcomProductNames(productIdArray)
                setEcomProductImages(productImagesArray)
            }
        } catch (e) {
            alert("Unable to get courses")
        }
    }
    const getAuthors = async () => {

        try {
            const response = await api.getAuthors();

            const data = response?.data?.data || [];
            setAuthorsResponse(data)
            let authorNameArray = [];
            let authorImagesArray = [];

            for (let i = 0; i < data.length; i++) {
                authorNameArray.push(data[i].first_name + " " + data[i].last_name)
                authorImagesArray.push(data[i].avatar)
            }

            setAllAuthorsName(authorNameArray)
            setAllAuthorsImage(authorImagesArray)

        } catch (e) {
            alert("Something wrong to fetch all authors")
        }
    }
    const courseImageHandle = async (event) => {

        try {
            setImageUploadingLoading(true)
            const file = event.target.files[0];

            // create file start

            let fData = {
                name: file.name,
                size: file.size,
                type: file.type,
            }

            const createFile = await api.fileCreate(fData)

            if (createFile && createFile.data && createFile.data.data && createFile.data.data.id) {
                setFileId(createFile.data.data.id)
            }

            // create file end

            const data = {
                fileName: file.name,
                ContentType: file.type
            }

            const response = await api.getPresignedUrl(data)

            if (response && response.data && response.data.data) {
                const presignedUrl = response.data.data;

                const fileData = {
                    url: presignedUrl,
                    fileType: file.type,
                    file: file
                }

                const sendDataResponse = await api.sendDataToS3(fileData)

                if (sendDataResponse) {

                    const imageUrl = presignedUrl.split("?")[0]

                    // update key start
                    const videoUrl = imageUrl;
                    const urlObject = new URL(videoUrl);
                    let pathname = urlObject.pathname;
                    let storageKey = "";
                    for (let i = 1; i < pathname.length; i++) {
                        storageKey = storageKey + pathname[i]
                    }
                    const actualNameKey = decodeURIComponent(storageKey);
                    const njernData = {
                        fileId: createFile.data.data.id,
                        storageKey: actualNameKey
                    }
                    const updateKey = await api.updateStorageKey(njernData);


                    if (updateKey) {

                        const data = {
                            fileId: createFile.data.data.id
                        }
                        const thumbnailUrl = await api.readFileUrl(data);

                        if (thumbnailUrl && thumbnailUrl.data && thumbnailUrl.data.data) {
                            setCourseImageUrl(thumbnailUrl.data.data)
                        }

                    }
                    // update key end
                }
            }
            setImageUploadingLoading(false)
        } catch (e) {
            alert(e.message ? e.message : "Unable to Upload Thumbnail")
            setImageUploadingLoading(false)
        }

        setImageUploadingLoading(false)
    }
    const getLevels = async () => {
        try {
            const response = await api.getLevels()

            if (response && response.data && response.data.data) {
                const resArray = response.data.data
                setLevelsResponce(resArray)
                let finalArray = []
                for (let i = 0; i < resArray.length; i++) {
                    finalArray.push(resArray[i].name)
                }
                setLevels(finalArray)
            }
        } catch (e) {
            alert("Unable to fetch levels")
        }


    }
    const ecomPageControle = async () =>{

        if(ecomCurrentPage + 1 <= ecomProductResponseMeta.last_page){
            setEcomCurrentPage(ecomCurrentPage + 1)
            firstCallHandle(ecomCurrentPage + 1)
            return;
        }else{
            setEcomCurrentPage(1)
            firstCallHandle(1)
        }

    }
    const getLanguages = async () => {

        try {
            const response = await api.getLanguages();
            if (response && response.data && response.data.data) {
                const resArray = response.data.data
                setLanguagesResponse(resArray)
                let finalArray = []
                for (let i = 0; i < resArray.length; i++) {
                    finalArray.push(resArray[i].name)
                }
                setLanguages(finalArray)
            }
        } catch (e) {
            alert("Unable to fetch languages")
        }
    }
    const saveFunctionHandle = async () => {
        try {
                const allCategoriesId = []

                for (let i = 0; i < categories.length; i++) {
                    allCategoriesId.push(categoryRes[categories[i]].id)
                }
                setResCategoriesIdArray(allCategoriesId)

                const data = {
                    willLearn: willLearn,
                    categoriesId: allCategoriesId,
                    course_includes: courseIncludes,
                    // uncomment start
                    ecom_product_id: ecomProductResponse[selectedEcomProductIndex].id,
                    ecom_product_slug: ecomProductResponse[selectedEcomProductIndex].slug,
                    // uncomment end
                    // remove start
                    // ecom_product_id: 1111,
                    // remove end
                    authorId: authorsResponse[selectedAuthorIndex].id,
                    courseImage: courseImageUrl,

                    slug: slug,
                    name: courseName,
                    description: description,
                    starRating: starRating,
                    studentCount: studentCount,
                    discountedPrice: actualPrice,
                    price: cutPrice,
                    requirements: requirement,
                    featuredReviewId: featuredReviewId,
                    levelId: levelsResponce[selectedLevel].id,
                    languageId: languagesResponse[selectedLanguage].id,
                    thumbnail_file_id: fileId
                }

                const response = await api.createCourse(data)

                if (response && response.data && response.data.success) {
                    const courseId = response?.data?.data?.id || 0
                    setCreatedCourseId(courseId)
                    alert(`course created successfully. course id is ${courseId}`)
                    navigate("/admin/courses")
                }





        } catch (e) {
            alert(e && e.response && e.response.data && e.response.data.message ? e.response.data.message : "Unable to create course")
        }

    }


    useEffect(() => {

        firstCallHandle()
        getAuthors()
        getCategories()
        getLevels()
        getLanguages()
    }, [])

    useEffect(() => {
        if (saveClick) {
            saveFunctionHandle()
            setSaveClick(false)
        }

    }, [saveClick])


    return (
        <div
            className='top-cont'
            style={{
                marginTop: "72px",
                display: "flex",
                flexDirection: "column",
                gap: "40px",
                marginBottom: "40px"
            }}
        >
            {/*  */}
            <div
                style={{
                    display: "flex",
                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Select product ID</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                    }}
                >
                    <SelectDropdown
                        onNextClick={ecomPageControle}
                        value={ecomProductNames[selectedEcomProductIndex]}
                        onOptionClick={(i) => {
                            setSelectedEcomProductIndex(i)
                            setCourseName(ecomProductResponse[i].name)
                            setSlug(ecomProductResponse[i].slug)
                        }}
                        placeholder={'Add professional Course name  like “Be awesome”'}
                        titleList={ecomProductNames}
                        imageUrlList={ecomProductImages}
                    />

                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Select Author</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                    }}
                >
                    <SelectDropdown
                        value={allAuthorsName[selectedAuthorIndex]}
                        onOptionClick={(i) => setselectedAuthorIndex(i)}
                        placeholder={'Add professional Course name  like “Be awesome”'}
                        titleList={allAuthorsName}
                        imageUrlList={allAuthorsImage}
                    />

                </div>
            </div>
            {/*  */}
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Course Thumbnail</p>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                    }}
                >
                    <p
                        style={{
                            width: "50%",
                            color: "#CDCDCD",
                            fontSize: "16px",
                            fontWeight: "medium",
                            margin: "0"
                        }}
                    >
                        This picture will be visible to all
                    </p>
                    <div
                        style={{
                            width: "50%"
                        }}
                    >
                        <div
                            className={'border-2-s-dark-400'}
                            style={{
                                border: "1px solid #E2E2E2",
                                borderRadius: "12px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "178px",
                                marginBottom: "10px",
                                overflow: "hidden"
                            }}
                        >
                            <div
                                style={{
                                    display: courseImageUrl.length === 0 && !imageUploadingLoading ? "" : "none"
                                }}
                            >
                                <AddImageIcon
                                    className={'filter-invert-100'}
                                />
                            </div>

                            {
                                imageUploadingLoading
                                    ?
                                    <LoadingAnimation className='bg-black'/>
                                    :
                                    <img
                                        src={courseImageUrl}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                            display: courseImageUrl.length === 0 ? "none" : ""
                                        }}/>
                            }


                        </div>

                        <input
                            accept="image/*"
                            onChange={courseImageHandle}
                            style={{
                                display: "none"
                            }}
                            id="thumbnail" type='file'/>

                        <label htmlFor='thumbnail'>

                            <div
                                className={'btn-dark-500'}
                                style={{
                                    border: "0",
                                    borderRadius: "12px",
                                    height: "46px",
                                    padding: "0 32px",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                    fontFamily: "medium"
                                }}
                            >
                                {courseImageUrl.length === 0 ? "Upload Thumbnail" : "Change Thumbnail"}
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Course Name</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // flex: "1"
                        flexDirection: "column"
                    }}
                >
                    <input
                        className={'bg-dark-400 border-2-s-dark-500 outline-none text-white'}
                        value={courseName}
                        onChange={(i) => setCourseName(i.target.value)}
                        placeholder='Eg. Finance course'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />

                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Slug</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // flex: "1"
                        flexDirection: "column"
                    }}
                >
                    <input
                        disabled={true}
                        className={'bg-dark-400 border-2-s-dark-500 outline-none text-white'}
                        value={ecomProductResponse.length > 0 &&  ecomProductResponse[selectedEcomProductIndex]?.slug ? ecomProductResponse[selectedEcomProductIndex].slug : ""}
                        onChange={() => null}
                        placeholder='Eg. course-abc-xyz'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />

                </div>
            </div>
            {/* description */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Description</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // flex: "1"
                        flexDirection: "column"
                    }}
                >
          <textarea
              className={'bg-dark-400 border-2-s-dark-500 outline-none text-white'}
              value={description}
              onChange={(i) => setDescription(i.target.value)}
              placeholder='Add Awesome description'
              style={{
                  border: "2px solid #F6F6F9",
                  borderRadius: "16px",
                  width: "100%",
                  padding: "30px",
                  fontSize: "16px",
                  height: "150px"
              }}
          ></textarea>

                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Student Count</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                    }}
                >
                    <input
                        className={'bg-dark-400 border-2-s-dark-500 outline-none text-white'}
                        value={studentCount}
                        onChange={(i) => setStudentCount(i.target.value)}
                        placeholder='Eg. 1000'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />

                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Star Rating</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                    }}
                >
                    <input
                        className={'bg-dark-400 border-2-s-dark-500 outline-none text-white'}
                        value={starRating}
                        onChange={(i) => setStarRating(i.target.value)}
                        placeholder='Eg. 4 or 4.6'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />


                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Add Price</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // flex: "1"
                        flexDirection: "column"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            gap: "20px"
                        }}
                    >
                        <input
                            className={'bg-dark-400 border-2-s-dark-500 outline-none text-white'}
                            value={actualPrice}
                            onChange={(i) => setActualPrice(i.target.value)}
                            placeholder='Discounted Price'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                height: "65px",
                                padding: "0 30px",
                                fontSize: "16px",
                                flex: "1"

                            }}
                        />
                        <input
                            className={'bg-dark-400 border-2-s-dark-500 outline-none text-white'}
                            value={cutPrice}
                            onChange={(i) => setCutPrice(i.target.value)}
                            placeholder='MRP Price'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                height: "65px",
                                padding: "0 30px",
                                fontSize: "16px",
                                flex: "1"

                            }}
                        />
                    </div>

                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Category</p>
                </div>
                <SelectOne
                    onChange={(i) => {
                        setCategories([i])
                    }}
                    list={allCategories}
                />
                {/*<SelectMultiple*/}
                {/*    onChangeArray={(i) => {*/}
                {/*        console.log(i)*/}
                {/*        setCategories(i)*/}
                {/*    }}*/}
                {/*    list={allCategories}/>*/}
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >What you’ll learn</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // flex: "1"
                        flexDirection: "column"
                    }}
                >
                    <div
                        style={{
                            width: "100%"
                        }}
                    >
                        {
                            willLearn.map((i, index) => {
                                return (
                                    <div
                                        className={'border-2-s-dark-400'}
                                        key={index}
                                        style={{
                                            width: "100%",
                                            border: "2px solid #F6F6F9",
                                            borderRadius: "16px",
                                            padding: "10px 30px",
                                            marginBottom: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            gap: "20px"
                                        }}
                                    >
                                        <p
                                            className={'text-white'}
                                            style={{
                                                margin: 0,
                                                fontSize: "16px",
                                                fontFamily: "medium",
                                                width: "-webkit-fill-available"
                                            }}
                                        >{i}</p>
                                        <CloseIcon
                                            className={'filter-invert-100'}
                                            onClick={() => {
                                                const array11 = [...willLearn];
                                                array11.splice(index, 1);
                                                setWillLearn(array11)
                                            }}
                                            style={{
                                                cursor: "pointer"
                                            }}
                                        />

                                    </div>
                                )
                            })
                        }

                    </div>

                    <textarea
                        className={'bg-dark-400 border-2-s-dark-500 outline-none text-white'}
                        value={willLearnText}
                        onChange={(i) => setWillLearnText(i.target.value)}
                        placeholder='What will you learn section'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            width: "100%",
                            padding: "30px",
                            fontSize: "16px",
                            height: "150px"
                        }}
                    ></textarea>
                    <div

                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginTop: "20px"
                        }}
                    >
                        <div
                            className={'bg-dark-400'}
                            style={{
                                height: "2px",
                                background: "#F6F6F9",
                                width: "calc(50% - 40px)"
                            }}
                        ></div>
                        <AddRoundIcon
                            style={{
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                if (willLearnText.length < 5) {
                                    alert("minimum character length is 5")
                                } else if (willLearnText.length > 100) {
                                    alert("maximum character length is 100")
                                } else if (willLearnText.length >= 5) {
                                    setWillLearn([...willLearn, willLearnText])
                                    setWillLearnText("")
                                }
                            }}
                        />
                        <div
                            className={'bg-dark-400'}
                            style={{
                                height: "2px",
                                background: "#F6F6F9",
                                width: "calc(50% - 40px)"
                            }}
                        ></div>
                    </div>


                </div>
            </div>
            {/*  */}
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Course Includes</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // flex: "1"
                        flexDirection: "column"
                    }}
                >
                    <div
                        style={{
                            width: "100%"
                        }}
                    >
                        {
                            courseIncludes.map((i, index) => {
                                return (
                                    <div
                                        className={'border-2-s-dark-400'}
                                        key={index}
                                        style={{
                                            width: "100%",
                                            border: "2px solid #F6F6F9",
                                            borderRadius: "16px",
                                            padding: "10px 30px",
                                            marginBottom: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            gap: "20px"
                                        }}
                                    >
                                        <p
                                            className={'text-white'}
                                            style={{
                                                margin: 0,
                                                fontSize: "16px",
                                                fontFamily: "medium",
                                                width: "-webkit-fill-available"
                                            }}
                                        >{i}</p>
                                        <CloseIcon
                                            className={'filter-invert-100'}
                                            onClick={() => {
                                                const array11 = [...courseIncludes];
                                                array11.splice(index, 1);
                                                setCourseIncludes(array11)
                                            }}
                                            style={{
                                                cursor: "pointer"
                                            }}
                                        />

                                    </div>
                                )
                            })
                        }

                    </div>

                    <textarea
                        className={'bg-dark-400 border-2-s-dark-500 outline-none text-white'}
                        value={courseIncludesText}
                        onChange={(i) => setCourseIncludesText(i.target.value)}
                        placeholder='What will you learn section'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            width: "100%",
                            padding: "30px",
                            fontSize: "16px",
                            height: "150px"
                        }}
                    ></textarea>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginTop: "20px"
                        }}
                    >
                        <div
                            className={'bg-dark-400'}
                            style={{
                                height: "2px",
                                background: "#F6F6F9",
                                width: "calc(50% - 40px)"
                            }}
                        ></div>
                        <AddRoundIcon
                            style={{
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                if (courseIncludesText.length < 5) {
                                    alert("minimum character length is 5")
                                } else if (courseIncludesText.length > 100) {
                                    alert("maximum character length is 100")
                                } else if (courseIncludesText.length >= 5) {
                                    setCourseIncludes([...courseIncludes, courseIncludesText])
                                    setCourseIncludesText("")
                                }
                            }}
                        />
                        <div
                            className={'bg-dark-400'}
                            style={{
                                height: "2px",
                                background: "#F6F6F9",
                                width: "calc(50% - 40px)"
                            }}
                        ></div>
                    </div>


                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Featured Review ID</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                    }}
                >
                    {/* start from here */}
                    <input
                        className={'bg-dark-400 border-2-s-dark-500 outline-none text-white'}
                        value={featuredReviewId}
                        onChange={(i) => setFeaturedReviewId(i.target.value)}
                        placeholder='Eg. 10'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",
                        }}
                    />

                </div>
            </div>
            {/*  */}
            <div style={{display: "flex",}}>
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Requirement</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // flex: "1"
                        flexDirection: "column"
                    }}
                >
          <textarea
              className={'bg-dark-400 border-2-s-dark-500 outline-none text-white'}
              value={requirement}
              onChange={(i) => setRequirement(i.target.value)}
              placeholder='Add Awesome description'
              style={{
                  border: "2px solid #F6F6F9",
                  borderRadius: "16px",
                  width: "100%",
                  padding: "30px",
                  fontSize: "16px",
                  height: "150px"
              }}
          ></textarea>

                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Level</p>
                </div>
                <SelectOne
                    onChange={(i) => setSelectedLevel(i)}
                    list={levels}/>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p
                        className={'text-white'}
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Language</p>
                </div>
                <SelectOne
                    onChange={(i) => setSelectedLanguage(i)}
                    list={languages}
                />

            </div>

        </div>
    )
}

export default UploadCourse
