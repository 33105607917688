import React from 'react'
import Badge from "../globle/badge"
import theme from '../../theme/theme'
import CourseImage from '../../assets/courseImage.png'
import { useNavigate } from 'react-router-dom'
import authorImage from '../../assets/images/person.jpg'
import StartIcon from "../../assets/star.svg"
import SectionIcon from "../../assets/sectionIcon.svg"
import Button from '../globle/button'


const CourseCart = ({
    courseName,
    bestSeller,
    imageUrl,
    starRating,
    studentCount,
    categories,
    sectionsCount,
    cutPrice,
    price,
    isEdit,
    onEditClick,
    firstName,
    lastName,
    avatar,
    coursesLength,
    idx,
    status,
    onStatusClick,
    activeStatus,
    onDeleteClick,
    isDelete,
    className,
    titleClassName,
    k12,
    onClickCopyLink
}) => {


    return (
        <div
            className={`${className} `}
            style={{
                borderRadius: "0",
                cursor: "pointer",
                padding: "0 0 30px 0",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px"
            }}>
            <div style={{ display: "flex", alignItems: "start" }}>
                <img
                    style={{
                        width: "240px",
                        borderRadius: "10px",
                        height: "130px",
                        objectFit: "cover"
                    }}
                    src={imageUrl} alt='courseImage' />
                <div style={{ marginLeft: "30px" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "12px", marginBottom: "10px" }}>
                        {
                            avatar ?
                                <img src={avatar}
                                    style={{ width: "20px", height: "20px", objectFit: "cover", borderRadius: "50%" }} />
                                : <></>

                        }

                        <p
                            className={` ${titleClassName}`}
                            style={{
                                fontFamily: "semiBold",
                                fontSize: "12px",
                                margin: "0"
                            }}>{firstName + " " + lastName}</p>
                    </div>
                    <p style={{
                        fontSize: "14px",
                        fontFamily: "medium",
                        margin: "0",
                        marginBottom: "9px"
                    }}>{courseName}</p>
                    {/*<div style={{display: "flex", alignItems: "center", gap: "5px", marginBottom: "10px"}}>*/}
                    {/*    <p style={{margin: "0", fontSize: "10px", fontFamily: "medium"}}>{starRating}</p>*/}
                    {/*    <img src={StartIcon}/>*/}
                    {/*    <p style={{margin: "0", fontSize: "10px", fontFamily: "medium"}}>({studentCount} Enrolled)</p>*/}
                    {/*</div>*/}
                    <div style={{ display: "flex", gap: "25px" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            {/* {
                                categories ? categories.map((i, index) => {
                                    if (index < 2) {
                                        return (
                                            <div key={index} style={{ background: theme.color.gray100, fontSize: "12px", padding: "8px 12px", borderRadius: "10px" }}>Marketing</div>
                                        )
                                    }

                                })
                                    :
                                    <></>
                            } */}
                            <div
                                className={'bg-dark-400 text-white fs-12 b-radius-10px p-10'}
                            >{categories.length !== 0 ? categories[0].category.name : "No Category"}</div>

                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <img src={SectionIcon} style={{ width: "14px", height: "14px" }} />
                            <p style={{
                                margin: "0",
                                fontSize: "12px",
                                fontFamily: "medium"
                            }}>{sectionsCount} Sections</p>
                        </div>
                        {/*<div style={{display: "flex", alignItems: "center", gap: "5px"}}>*/}
                        {/*    <img src={SectionIcon} style={{width: "14px", height: "14px"}}/>*/}
                        {/*    <p style={{margin: "0", fontSize: "12px", fontFamily: "medium"}}>12 hr 33 min</p>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div
                className='g-10'
                style={{
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end"
                }}
            >
                {
                    !k12 ?
                        <div style={{ display: "flex", alignItems: "flex-start", gap: "5px", justifyContent: "center" }}>
                            <p style={{
                                color: theme.primary,
                                fontFamily: "semibold",
                                textTransform: "uppercase",
                                fontSize: "16px",
                                margin: "0px"
                            }}>₹{price ? price : "null"}</p>
                            <p style={{
                                color: "black",
                                fontFamily: "medium",
                                fontSize: "13px",
                                margin: "0px",
                                textDecoration: "line-through"
                            }}>₹{cutPrice ? cutPrice : "null"}</p>
                        </div> : <></>
                }

                {
                    k12 ?
                        <button onClick={onClickCopyLink}>Copy Link</button> : <></>
                }


                {
                    status && !k12
                        ?
                        <div
                            onClick={() => onStatusClick()}
                            className={activeStatus ? "bg-success" : "bg-danger"}
                            style={{
                                width: "50px",
                                height: "25px",
                                borderRadius: "25px",
                                position: "relative",
                                overflow: "hidden"
                            }}
                        >
                            <div
                                className='d-flex a-i-center '
                                style={{
                                    position: "absolute",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                    justifyContent: activeStatus ? "end" : "start"

                                }}
                            >


                                <div
                                    className="bg-dark-500"
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "50%"
                                    }}
                                >

                                </div>
                            </div>

                        </div>
                        :
                        <></>
                }

                {
                    isEdit ?
                        <Button
                            className={'btn-dark-500'}
                            onClick={() => onEditClick ? onEditClick() : null}
                            buttonStyle={{
                                padding: "0 50px",
                                height: "42px"
                            }}
                            title={'Edit'}
                        />
                        :
                        <></>
                }
                {
                    isDelete && !k12 ?
                        <Button
                            className={'btn-dark-500'}
                            onClick={() => onDeleteClick ? onDeleteClick() : null}
                            buttonStyle={{
                                padding: "0 50px",
                                height: "42px"
                            }}
                            title={'Delete'}
                        />
                        :
                        <></>
                }

            </div>
        </div>
    )
}

export default CourseCart
