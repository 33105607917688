

const VideoPlayer = ({videoUrl }) => {






    return (
        <div className='position-relative'>
            <video
                controlsList="nodownload"
                className='h-auto-phone b-radius-0-phone bg-dark-500 outline-none'
                autoPlay={true}
                key={videoUrl}
                style={{
                    backgroundColor: "black",
                    borderRadius: "12px",
                    width: "100%",
                    aspectRatio: "639/359"
                }}
                controls="controls">
                <source src={videoUrl} type="video/mp4"/>

            </video>


        </div>
    )
}

export default VideoPlayer
