const theme = {
    gray100: "#EAEAEA",
    gray300: "#E6E6E6",
    gray200: "#F6F6F6",
    gray250: "#A9A9A9",
    gray350: "#999999",
    textGray: "#6a6f73",
    font14: "14px",
    font12: "12px",
    font13: "13px",
    br6: "6px",
    fwBold: "600",
    fwSemibold: "500",
    primary: "#FF5612",

    color: {
        gray100: "#F6F6F9",
        
    }
}

export default theme;