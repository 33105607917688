// cookieUtils.js

// Function to store a cookie
export const storeCookieV2 = (key, value, expireTimeInSeconds) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + expireTimeInSeconds * 1000);
    document.cookie = `${key}=${value};expires=${expirationDate.toUTCString()};path=/`;
}

// Function to retrieve a cookie
export const getCookieV2 = (key) => {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        const [cookieKey, cookieValue] = cookie.split('=');
        if (cookieKey.trim() === key) {
            return cookieValue;
        }
    }
    return null;
}
