import React, {useState} from 'react';
import UpArrowBlack from "../../../assets/upArrowBlack.svg";
import CourseContentLesson from "../../courseDetails/courseLessonPlan/courseContentLesson";

const CourseContentItemLoading = () => {
    const [isOpen, setIsOpen] = useState(false)


    return (
        <div style={{padding: "10px", cursor: "pointer"}}>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: "5px 20px",
                boxSizing: "border-box",
                gap: "10px"
            }}>
                <div className='w-50p' style={{display: "flex", flexDirection: "column", gap: "10px", marginBottom: "10px"}}>
                    <div
                        className='fs-10-phone text-gray-200 bg-dark-400 b-radius-10px '
                        style={{
                            margin: 0,
                            fontFamily: "bold",
                            fontSize: "14px",
                            color: "#B4B4B4",
                            width: "165px",
                            height: "17px"
                        }}/>
                    <div className='fs-14-phone text-white bg-dark-400 b-radius-10px w-100p'
                       style={{margin: 0,
                           fontFamily: "medium",
                           fontSize: "14px",
                           marginLeft: "6px",
                           height: "17px"
                       }}/>
                </div>
                <img  alt='arrow' src={UpArrowBlack}
                     style={{width: "13px", transform: isOpen ? "rotate(0deg)" : "rotate(180deg)"}}/>
            </div>
            <div className='bg-dark-400' style={{
                borderRadius: "12px",
                background: "white",
                height: isOpen ? "revert-layer" : "0px",
                overflow: isOpen ? "" : "hidden"
            }}>

            </div>
        </div>
    );
};

export default CourseContentItemLoading;
