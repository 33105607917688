import React from 'react'
import ZebraLogo from "../../assets/ZEBRALEARN.svg"

const Header = ({ name }) => {
    return (
        <div

            className='px-10-phone flex-column-phone p-10-phone g-10-phone h-auto-phone bg-dark-500 d-none-phone'
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "35px",
                height: "75px",
                background: "#F6F6F9",
                padding: "0 60px"
            }}
        >
            <a href="https://zebralearn.com" >
            <img
                alt={"ZEBRALEARN"}
                src={ZebraLogo}
            />
            </a>
            <div
                className='d-none-phone bg-dark-400'
                style={{
                    height: "50px",
                    width: "2px",
                }}
            >

            </div>
            <p
                className='fs-12-phone text-white'
                style={{
                    fontSize: "16px",
                    fontFamily: "semiBold",
                    margin: "0"
                }}
            >{name ? name : ""}</p>
        </div>
    )
}

export default Header
