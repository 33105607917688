import theme from "../../../theme/theme";
import checkIcon from "../../../assets/check.svg";
import React from "react";

const ThisCourseIncludes = ({courseIncludes, className}) => {
    return (
        <div className={`${className} p-20-phone bg-dark-500`}
             style={{padding: "50px", background: theme.color.gray100, borderRadius: "20px"}}>
            <div style={{display: "flex", flexDirection: "column", gap: "5px", marginBottom: "30px"}}>
                <div className="text-primary bg-dark-400 b-radius-10px"
                     style={{margin: 0, fontFamily: "semiBold", fontSize: "14px", width: "120px", height: "17px"}}/>
                <div className='fs-20-phone text-white bg-dark-400 b-radius-10px'
                      style={{
                          margin: 0,
                          fontFamily: "semiBold",
                          fontSize: "35px",
                          height: "43px"
                      }}/>
            </div>
            <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>

                <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                    <div className="text-white bg-dark-400 b-radius-10px w-100p"
                         style={{margin: "0", fontFamily: "semiBold", fontSize: "14px", height: "17px"}}/>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                    <div className="text-white bg-dark-400 b-radius-10px w-100p"
                         style={{margin: "0", fontFamily: "semiBold", fontSize: "14px", height: "17px"}}/>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                    <div className="text-white bg-dark-400 b-radius-10px w-100p"
                         style={{margin: "0", fontFamily: "semiBold", fontSize: "14px", height: "17px"}}/>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                    <div className="text-white bg-dark-400 b-radius-10px w-100p"
                         style={{margin: "0", fontFamily: "semiBold", fontSize: "14px", height: "17px"}}/>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                    <div className="text-white bg-dark-400 b-radius-10px w-100p"
                         style={{margin: "0", fontFamily: "semiBold", fontSize: "14px", height: "17px"}}/>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                    <div className="text-white bg-dark-400 b-radius-10px w-100p"
                         style={{margin: "0", fontFamily: "semiBold", fontSize: "14px", height: "17px"}}/>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                    <div className="text-white bg-dark-400 b-radius-10px w-100p"
                         style={{margin: "0", fontFamily: "semiBold", fontSize: "14px", height: "17px"}}/>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                    <div className="text-white bg-dark-400 b-radius-10px w-100p"
                         style={{margin: "0", fontFamily: "semiBold", fontSize: "14px", height: "17px"}}/>
                </div>

            </div>
        </div>
    )
}

export default ThisCourseIncludes
