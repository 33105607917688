import React, { useEffect, useState } from 'react'

const SelectMultiple = ({ list, onChangeArray, initArray }) => {


    const [selectedArray, setSelectedArray] = useState([])

    const selectHandle = (i) => {
        var array = [...selectedArray];
        if (array.length >= 0 && array.includes(i)) {
            const newArray = array.filter(item => item !== i);
            setSelectedArray([...newArray])
            onChangeArray([...newArray])
        } else if (array.length >= 0 && !array.includes(i)) {
            var array2 = [...selectedArray];
            array2.push(i)
            setSelectedArray([...array2])
            onChangeArray([...array2])

        }
    }


    useEffect(()=>{
        if(initArray){
            setSelectedArray(initArray)
        }
    },[initArray])

    return (
        <div
            className='flex-wrap'
            style={{
                width: "-webkit-fill-available",
                display: "flex",
                alignItems: "center",
                gap: "8px"
            }}
        >
            {
                list ? list.map((i, index) => {
                    return (
                        <div
                            className={`${selectedArray.length >= 0 && selectedArray.includes(index) ? 'text-primary ' : 'bg-dark-400 text-white border-0'}`}
                            onClick={() => selectHandle(index)}
                            key={index}
                            style={{
                                background: selectedArray.length >= 0 && selectedArray.includes(index) ? "#FFF3F0" : "white",
                                color: selectedArray.length >= 0 && selectedArray.includes(index) ? "#FF5612" : "black",
                                padding: "9px 10px",
                                borderRadius: "16px",
                                fontSize: "14px",
                                fontFamily: "medium",
                                textTransform: "capitalize",
                                border: selectedArray.length >= 0 && selectedArray.includes(index) ? "1px solid #FFF3F0" : "1px solid #D9D9D9",
                                cursor: "pointer"
                            }}
                        >
                            {i}
                        </div>
                    )
                }) : <></>
            }


        </div>
    )
}

export default SelectMultiple
