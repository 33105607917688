import React from 'react'
import CourseImage from "../../assets/courseImage.png"
import HorizontalDot from "../../assets/threedot.svg"
import theme from '../../theme/theme'
import { useNavigate, Link } from 'react-router-dom'
import personImage from "../../assets/images/person2.jpg"
import SectionsIcon from '../../assets/icons/sections'
import ClockIcon from '../../assets/icons/clockIcon'

const Card = ({ courseName, courseId, image, completion, slug,avatar,
     firstName, lastName }) => {




    return (
        <Link

            style={{
                color: "inherit",
                textDecoration: "none",
                boxShadow: "rgba(0, 0, 0, 0.08) 0px 0px 12px 0px",
                borderRadius: "12px",
                padding: "20px"
            }}
            to={`/course/${slug}`}
            state={{ courseId: courseId }}
        >
            <img
                style={{
                    borderRadius: "12px 12px 0 0",
                    width: "100%",
                    objectFit: "cover",
                    aspectRatio: "1189/668"
                }}
                src={image} />
            <div
                className='bg-dark-500'
                style={{
                    width: "100%",
                    height: "6px",
                    borderRadius: "10px",
                    background: "#F6F6F9",
                    marginTop: "5px",
                    position: "relative",
                    overflow: "hidden",
                    marginBottom: "15px"
                }}
            >
                <div
                    style={{
                        width: `${completion}%`,
                        height: "100%",
                        background: theme.primary,
                        position: "absolute",
                        top: "0",
                        left: "0",
                        borderRadius: "20px"
                    }}
                ></div>

            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    marginBottom: "11px"
                }}
            >
                <img
                    style={{
                        width: "16px",
                        height: "16px",
                        objectFit: "cover",
                        borderRadius: "50%",
                        border: "1px solid #EDEDED"
                    }}
                    src={avatar ? avatar : personImage} />
                <p
                    className='text-white'
                    style={{
                        margin: "0",
                        fontSize: "13px",
                        fontFamily: "medium"
                    }}
                >{firstName + " " + lastName}</p>
            </div>
            <div
                className='bg-dark-500'
                style={{
                    width: "100%",
                    height: "2px",
                    background: "#F6F6F9",
                    marginBottom: "21px"
                }}
            >

            </div>
            <p
                className='text-white'
                style={{
                    margin: "0",
                    fontSize: "16px",
                    fontFamily: "medium",
                    marginBottom: "12px"
                }}
            >{courseName}</p>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
            </div>

        </Link>
    )
}

export default Card
