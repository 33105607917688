import React from 'react';

const LinkedinIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <g clipPath="url(#clip0_1454_27959)">
                <path d="M11.2505 29.7422H6.32867V13.8516H11.2505V29.7422ZM11.7416 8.78879C11.7416 7.19769 10.4507 5.90625 8.86047 5.90625C7.26416 5.90625 5.97656 7.19769 5.97656 8.78879C5.97656 10.3804 7.26416 11.6719 8.86047 11.6719C10.4507 11.6719 11.7416 10.3804 11.7416 8.78879ZM29.6719 20.9998C29.6719 16.7341 28.7707 13.5703 23.7876 13.5703C21.3931 13.5703 19.7858 14.7678 19.1297 16.0131H19.125V13.8516H14.3438V29.7422H19.125V21.8524C19.125 19.7861 19.651 17.7844 22.2122 17.7844C24.7385 17.7844 24.8203 20.1473 24.8203 21.9836V29.7422H29.6719V20.9998ZM36 31.7812V4.21875C36 1.8924 34.1076 0 31.7812 0H4.21875C1.8924 0 0 1.8924 0 4.21875V31.7812C0 34.1076 1.8924 36 4.21875 36H31.7812C34.1076 36 36 34.1076 36 31.7812ZM31.7812 2.8125C32.5566 2.8125 33.1875 3.44339 33.1875 4.21875V31.7812C33.1875 32.5566 32.5566 33.1875 31.7812 33.1875H4.21875C3.44339 33.1875 2.8125 32.5566 2.8125 31.7812V4.21875C2.8125 3.44339 3.44339 2.8125 4.21875 2.8125H31.7812Z"/>
            </g>
            <defs>
                <clipPath id="clip0_1454_27959">
                    <rect width="36" height="36"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default LinkedinIcon;
