import theme from "../../../theme/theme";
import React from "react";

const RequirementsLoading = ({requirement, className}) => {
    return (
        <div className={`${className} p-20-phone bg-dark-500`} style={{
            padding: "50px",
            background: theme.color.gray100,
            borderRadius: "20px",
            height: "-webkit-fit-content"
        }}>
            <div style={{display: "flex", flexDirection: "column", gap: "5px", marginBottom: "30px"}}>
                <div className="text-primary bg-dark-400 b-radius-10px"
                     style={{
                         margin: 0,
                         fontFamily: "semiBold",
                         fontSize: "14px",
                         width: "182px",
                         height: "17px"
                     }}/>
                <div className='fs-20-phone text-white w-100p bg-dark-400 b-radius-10px'
                     style={{
                         margin: 0,
                         fontFamily: "semiBold",
                         fontSize: "35px",
                         height: "43px"
                     }}/>
            </div>
            <div className='fs-12-phone text-gray-100 w-100p bg-dark-400 b-radius-10px'
                 style={{
                     fontFamily: "semiBold",
                     color: "#8D8D8D",
                     margin: "0",
                     fontSize: "14px",
                     height: "51px"
                 }}/>
        </div>
    )
}

export default RequirementsLoading
