import React, {useEffect, useState} from 'react'
import Header from '../../components/globle/header'
import Card from '../../components/myLearning/card'
import api from '../../services/api'
import Button from '../../components/globle/button'
import Loading from "../../components/globle/loading";
import HeaderLoading from "../../components/loadings/headerLoading";
import CardLoading from "../../components/loadings/myCourses/cardLoading";
import Footer from "../../components/globle/footer";

const MyLearning = ({isAuthenticated, currentUser}) => {
    const [myCourses, setMyCourses] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [loadingCount, setLoadingCount] = useState([0,0,0,0])


    const syncHandle = async () => {
        try {
            setIsLoading(true)
            const response = await api.syncMyCoursesV2();
            await getMyCourses();

            setIsLoading(false)
            console.log(response?.data || "done")
        } catch (error) {
            alert(error?.response?.data?.message || "Error in sync")
            setIsLoading(false)
        }
    }


    const getMyCourses = async () => {

        try {
            setIsLoading(true)
            const myCourses = await api.getMyCourses();
            const data = myCourses?.data?.data || []
            setMyCourses(data)
            setIsLoading(false)
        } catch (e) {
            alert("Unable to fetch your courses")
            setIsLoading(false)
        }

        setIsLoading(false)
    }


    useEffect(() => {
        getMyCourses()
    }, [])

    if (isLoading) {
        return (
            <div
                className='bg-black h-100vh overflow-auto scroll-0'>
                <HeaderLoading currentUser={currentUser} isAuthenticated={isAuthenticated}/>
                <div
                    className='bg-dark-500'
                    style={{background: "#F6F6F9"}}>
                    <div className='top-cont'>
                        <div style={{height: "76px", display: "flex", alignItems: "center"}}>
                            <p
                                className='text-white fs-20-phone'
                                style={{
                                    color: "black",
                                    margin: "0",
                                    fontSize: "24px",
                                    fontFamily: "semiBold"

                                }}>My Learning</p>
                        </div>
                    </div>
                </div>
                <div className='top-cont'>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "20px"
                        }}
                    >
                        <Button
                            className='bg-dark-500 text-white'
                            onClick={syncHandle}
                            buttonStyle={{
                                alignSelf: "flex-end"
                            }}
                            title={"Sync"}
                        />
                    </div>
                    <div
                        className='grid-template-100-phone'
                        style={{
                            display: "grid", gridTemplateColumns: "repeat(3, 1fr)", marginTop: "30px", gap: "30px",
                            marginBottom: "40px"
                        }}>

                        {
                             loadingCount.map((i, index) => {
                                    return (
                                        <CardLoading
                                            key={index}
                                        />

                                    )
                                })

                        }

                    </div>
                </div>
            </div>
        )
    } else {

        return (
            <div
                className='bg-black h-100vh overflow-auto scroll-0'>
                <Header currentUser={currentUser} isAuthenticated={isAuthenticated}/>
                <div
                    className='bg-dark-500'
                    style={{background: "#F6F6F9"}}>
                    <div className='top-cont'>
                        <div style={{height: "76px", display: "flex", alignItems: "center"}}>
                            <p
                                className='text-white fs-20-phone'
                                style={{
                                    color: "black",
                                    margin: "0",
                                    fontSize: "24px",
                                    fontFamily: "semiBold"

                                }}>My Learning</p>
                        </div>
                    </div>
                </div>
                <div className='top-cont'>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "20px"
                        }}
                    >
                        <Button
                            className='bg-dark-500 text-white'
                            onClick={syncHandle}
                            buttonStyle={{
                                alignSelf: "flex-end"
                            }}
                            title={"Sync"}
                        />
                    </div>
                    <div
                        className='grid-template-100-phone'
                        style={{
                            display: "grid", gridTemplateColumns: "repeat(3, 1fr)", marginTop: "30px", gap: "30px",
                            marginBottom: "40px"
                        }}>

                        {
                            myCourses && myCourses.length !== 0 ? myCourses.map((i, index) => {
                                    return (
                                        <Card
                                            avatar={i && i.course && i.course.user && i.course.user.avatar ? i.course.user.avatar : ""}
                                            firstName={i && i.course && i.course.user && i.course.user.first_name ? i.course.user.first_name : ""}
                                            lastName={i && i.course && i.course.user && i.course.user.last_name ? i.course.user.last_name : ""}
                                            slug={i && i.course && i.course.slug ? i.course.slug : ""}
                                            key={index}
                                            courseId={i && i.course && i.course.id ? i.course.id : 0}
                                            courseName={i.course && i.course.name ? i.course.name : ""}
                                            image={i.course && i.course.course_image_url ? i.course.course_image_url : ""}
                                            completion={i && i.completion ? i.completion : 0}
                                            sectionLeft={0}
                                        />

                                    )
                                })
                                :
                                <p
                                    style={{
                                        margin: "20px 0",
                                        fontFamily: "medium",
                                        color: "black",
                                        textAlign: "center"

                                    }}
                                >No course found</p>
                        }

                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default MyLearning
