import React, {useEffect, useState} from 'react'
import AddRoundIcon from '../../../assets/icons/addRoundIcon'
import SelectMultiple from '../../../components/globle/selectMultiple'
import api from "../../../services/api"
import Loading from '../../../components/globle/loading'
import LoadingAnimation from '../../../components/globle/loadingAnimation'
import CloseIcon from '../../../assets/icons/closeIcon'
import {useFetcher, useNavigate, useParams} from 'react-router-dom'
import utilities from "../../../utility/utilities";

const AuthorDetails = ({saveClick, setSaveClick}) => {

    const [author, setAuthor] = useState();
    const [avatar, setAvatar] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [headline, setHeadline] = useState("");
    const [description, setDescription] = useState("");
    const [website, setWebsite] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [facebook, setFacebook] = useState("");
    const [twitter, setTwitter] = useState("");
    const [instagram, setInstagram] = useState("");
    const [highlights, setHighlights] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [avatarFileId, setAvatarFileId] = useState();
    const [avatarLoading, setAvatarLoading] = useState(false);
    const [highlightText, setHighlightText] = useState("");
    const [allCategoriesResponse, setAllCategoriesResponse] = useState({});


    const {id} = useParams();

    const fetchAuthorDetails = async () => {
        try {
            const response = await api.getAdminAuthor(id)
            const data = response?.data?.data || {}
            setAuthor(data)
            setAvatar(data?.avatar || "")
            setFirstName(data?.first_name || "")
            setLastName(data?.last_name || "")
            setEmail(data?.email || "")
            setHeadline(data?.headline || "")
            setDescription(data?.description || "")
            setWebsite(data?.user_link?.website || "")
            setTwitter(data?.user_link?.twitter || "")
            setFacebook(data?.user_link?.facebook || "")
            setInstagram(data?.user_link?.instagram || "")
            setLinkedin(data?.user_link?.linkedin || "")
            setAvatarFileId(data?.avatar_file_id || "")

            let highlightArr = [];
            for (let i = 0; i < data.user_highlights.length; i++) {
                highlightArr.push(data.user_highlights[i].description);
            }
            setHighlights(highlightArr || []);


            const allCategories = await getAllCategories();


            setAllCategories(allCategories)

            let selectedcategoryNames = []
            for (let i = 0; i < data.author_categories.length; i++) {
                selectedcategoryNames.push(data.author_categories[i].category.name)
            }

            let categoryIndexes = [];
            for (let i = 0; i < allCategories.length; i++) {
                if (selectedcategoryNames.includes(allCategories[i])) {
                    categoryIndexes.push(i)
                }
            }
            setSelectedCategories(categoryIndexes)

        } catch (e) {
            alert(e?.response?.data?.message || "Unable to fetch author")
        }
    }

    const getAllCategories = async () => {
        try {
            const response = await api.getCategories();
            setAllCategoriesResponse(response?.data?.data || {})
            const catArray = [];

            for (let i = 0; i < response.data.data.length; i++) {
                catArray.push(response.data.data[i].name)
            }

            return catArray;
        } catch (e) {
            console.error(e)
        }
    }

    const uploadAvatarHandle = async (event) => {
        setAvatarLoading(true)
        const file = event.target.files[0];

        try {
            const fileId = await utilities.uploadImage(file)
            setAvatarFileId(fileId)

            const data = {
                fileId: fileId
            }
            const response = await api.readFileUrl(data);
            const avatarUrl = response.data.data;
            setAvatar(avatarUrl)
            setAvatarLoading(false)

        } catch (e) {
            console.error(e)
        }

    }

    const updateAuthorHandle = async () => {
        const categoriesFinalArray = [];

        for(let i = 0; i < selectedCategories.length; i++){
            categoriesFinalArray.push(allCategoriesResponse[selectedCategories[i]].id)
        }
        const data = {
            id,
            avatarFileId,
            firstName,
            lastName,
            email,
            description,
            headline,
            facebook,
            instagram,
            twitter,
            linkedin,
            website,
            categories: categoriesFinalArray,
            highlights
        }


        try{
            const response = await api.updateAuthor(data);
            await fetchAuthorDetails();
            alert(response?.data?.message || "Unable to update author")
        }catch (e) {
            alert(e?.response?.data?.message || "Unable to update author");
        }
    }

    useEffect(() => {
        fetchAuthorDetails()
    }, [])

    useEffect(() => {
        if(saveClick){
            updateAuthorHandle()

            setSaveClick(false)
        }
    }, [saveClick])

    return (
        <div
            className='top-cont'
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "40px",
                marginBottom: "40px"
            }}
        >

            <div
                className={'border-bottom-solid border-dark-400 border-bottom-2'}
                style={{
                    display: "flex",
                    padding: "30px 0",
                    borderBottom: "1px solid #F6F6F9"

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <p className='text-white'
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Profile picture</p>
                </div>

                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flex: "1"
                    }}
                >
                    <p className='text-white'
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >This picture will be visible to all</p>


                    <div
                        className='d-flex a-i-center j-c-center'
                        style={{
                            width: "85px",
                            height: "85px",
                            borderRadius: "50%",
                            position: "relative",
                            overflow: "hidden"

                        }}
                    >
                        <input className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                            accept="image/*"
                            onChange={uploadAvatarHandle}
                            style={{display: "none"}}
                            id='avatarFile'
                            type='file'/>


                        <label htmlFor="avatarFile">
                            {
                                avatarLoading
                                    ?
                                    <LoadingAnimation className={'filter-invert-100'}/>
                                    : <img
                                        src={avatar}
                                        style={{
                                            width: "85px",
                                            height: "85px",
                                            objectFit: "cover"
                                        }}/>
                            }
                        </label>
                    </div>
                </div>
            </div>
            {/*  */}

            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p className='text-white'
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Name</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "10px"

                    }}
                >
                    <input className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                        value={firstName}
                        onChange={(i) => setFirstName(i.target.value)}
                        placeholder='First Name'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />
                    <input className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                        value={lastName}
                        onChange={(i) => setLastName(i.target.value)}
                        placeholder='Last Name'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />

                </div>
            </div>
            {/*  */}
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p className='text-white'
                       style={{
                           fontSize: "16px",
                           fontFamily: "medium",
                           color: "black",
                           margin: "0"
                       }}
                    >Email</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "10px"

                    }}
                >
                    <input className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                           value={email}
                           onChange={(i) => setEmail(i.target.value)}
                           placeholder='Email'
                           style={{
                               border: "2px solid #F6F6F9",
                               borderRadius: "16px",
                               height: "65px",
                               width: "100%",
                               padding: "0 30px",
                               fontSize: "16px",

                           }}
                    />

                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p className='text-white'
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Headline</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // flex: "1"
                        flexDirection: "column"
                    }}
                >
                    <input className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                        className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                        value={headline}
                        onChange={(i) => setHeadline(i.target.value)}
                        placeholder='Add professional line like “Be awesome”'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />

                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p className='text-white'
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Description</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // flex: "1"
                        flexDirection: "column"
                    }}
                >
                        <textarea className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                            value={description}
                            onChange={(i) => setDescription(i.target.value)}
                            placeholder='Add Awesome description'
                            style={{
                                border: "2px solid #F6F6F9",
                                borderRadius: "16px",
                                width: "100%",
                                padding: "30px",
                                fontSize: "16px",
                                height: "150px"
                            }}
                        ></textarea>

                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p className='text-white'
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Link</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flex: "1",
                        flexDirection: "column",
                        gap: "10px"
                    }}
                >
                    <input className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                        value={website}
                        onChange={(i) => setWebsite(i.target.value)}
                        placeholder='Website Link'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />
                    <input className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                        value={facebook}
                        onChange={(i) => setFacebook(i.target.value)}
                        placeholder='Facebook Link'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />
                    <input className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                        value={instagram}
                        onChange={(i) => setInstagram(i.target.value)}
                        placeholder='Instagram Link'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />
                    <input className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                        value={linkedin}
                        onChange={(i) => setLinkedin(i.target.value)}
                        placeholder='Linkedin Link'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />
                    <input className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                        value={twitter}
                        onChange={(i) => setTwitter(i.target.value)}
                        placeholder='Twitter Link'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            height: "65px",
                            width: "100%",
                            padding: "0 30px",
                            fontSize: "16px",

                        }}
                    />
                </div>
            </div>
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p className='text-white'
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Highlight Points</p>
                </div>
                <div
                    style={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // flex: "1"
                        flexDirection: "column"
                    }}
                >
                    <div
                        style={{
                            width: "100%"
                        }}
                    >
                        {
                            highlights.map((i, index) => {
                                return (
                                    <div
                                        className={'border-2-s-dark-400'}
                                        key={index}
                                        style={{
                                            width: "100%",
                                            border: "2px solid #F6F6F9",
                                            borderRadius: "16px",
                                            padding: "10px 30px",
                                            marginBottom: "10px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            gap: "20px"
                                        }}
                                    >
                                        <p className='text-white'
                                            style={{
                                                margin: 0,
                                                fontSize: "16px",
                                                fontFamily: "medium",
                                                width: "-webkit-fill-available"
                                            }}
                                        >{i}</p>
                                        <CloseIcon
                                            className={'filter-invert-100'}
                                            onClick={() => {
                                                const array11 = [...highlights];
                                                array11.splice(index, 1);
                                                setHighlights(array11)
                                            }}
                                            style={{
                                                cursor: "pointer"
                                            }}
                                        />

                                    </div>
                                )
                            })
                        }

                    </div>

                    <textarea className={'bg-dark-400 border-2-s-dark-500 text-white outline-none'}
                        value={highlightText}
                        onChange={(i) => setHighlightText(i.target.value)}
                        placeholder='Write Highlights Points about you'
                        style={{
                            border: "2px solid #F6F6F9",
                            borderRadius: "16px",
                            width: "100%",
                            padding: "30px",
                            fontSize: "16px",
                            height: "150px"
                        }}
                    ></textarea>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            marginTop: "20px"
                        }}
                    >
                        <div
                            className={'bg-dark-400'}
                            style={{
                                height: "2px",
                                background: "#F6F6F9",
                                width: "calc(50% - 40px)"
                            }}
                        ></div>
                        <AddRoundIcon
                            style={{
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                setHighlights([...highlights, highlightText])
                                setHighlightText("")
                            }}
                        />
                        <div
                            className={'bg-dark-400'}
                            style={{
                                height: "2px",
                                background: "#F6F6F9",
                                width: "calc(50% - 40px)"
                            }}
                        ></div>
                    </div>


                </div>
            </div>
            {/*  */}
            {/*  */}
            <div
                style={{
                    display: "flex",

                }}
            >
                <div
                    style={{
                        flex: "0 0 250px",
                        display: "flex",
                        alignItems: "start"
                    }}
                >
                    <p className='text-white'
                        style={{
                            fontSize: "16px",
                            fontFamily: "medium",
                            color: "black",
                            margin: "0"
                        }}
                    >Category</p>
                </div>
                <SelectMultiple
                    initArray={selectedCategories}
                    onChangeArray={(i) => {
                        setSelectedCategories(i)
                    }}
                    list={allCategories}
                />
            </div>
            {/*  */}


        </div>
    )
}


export default AuthorDetails
