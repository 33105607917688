import React from 'react'
import theme from '../../theme/theme'
import '../../assets/css/courseInfo.css'

const Requirements = ({ requirement, className }) => {
    return (
        <div className={`${className} p-20-phone bg-dark-500`} style={{ padding: "50px", background: theme.color.gray100, borderRadius: "20px", height: "-webkit-fit-content" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginBottom: "30px" }}>
                <p className="text-primary" style={{ margin: 0, fontFamily: "semiBold", fontSize: "14px" }}>What you should have</p>
                <p className='fs-20-phone text-white' style={{ margin: 0, fontFamily: "semiBold", fontSize: "35px" }}>Requirements</p>
            </div>
            <p className='fs-12-phone text-gray-100' style={{ fontFamily: "semiBold", color: "#8D8D8D", margin: "0", fontSize: "14px" }}>{requirement ? requirement : "For web-based features, MS Exec for Finance is optimized for the latest versions of popular web browsers such as Google Chrome, Mozilla Firefox, and Microsoft Edge."}</p>
        </div>
    )
}

export default Requirements