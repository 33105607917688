import React from 'react'
import CourseImage from '../../assets/courseImage.png'
import theme from '../../theme/theme'
import StartIcon from "../../assets/star.svg"

const Card = ({ courseName, imgUrl, starRating, studentCount, discountedPrice, price, category }) => {



    return (
        <div
            className='bg-dark-500'
            style={{ width: "100%", borderRadius: "16px", background: "white", boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)", }}>
            <div style={{ position: "relative" }}>
                <img
                    style={{
                        borderRadius: "12px",
                        margin: "14px",
                        width: "calc(100% - 28px)",
                        objectFit: "cover",
                        aspectRatio: "419/235"
                    }}
                    src={imgUrl ? imgUrl : CourseImage} alt='courseImage' />
                <div style={{ background: "#e4e4e480", backdropFilter: "blur(5px)", borderRadius: "5px", position: "absolute", top: "20px", left: "20px" }}>
                    <p style={{ margin: "0", fontSize: "12px", fontFamily: "regular", padding: "4px 10px" }}>{category ? category : "Any"}</p>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <p
                    className='text-white pb-10'
                    style={{  fontSize: "14px", fontFamily: "medium", margin: "0 14px" }}>{courseName}</p>
                <div className='border-black' style={{ display: "flex", alignItems: "center", padding: "0 14px 20px 14px", borderBottom: `2px solid ${theme.color.gray100}` }}>
                    <p
                        className='text-gray-100'
                        style={{ margin: "0", fontSize: "12px", fontFamily: "medium", marginRight: "5px" }}>{starRating}</p>
                    <img style={{ marginRight: "8px" }} src={StartIcon} alt='star' />
                    <p
                        className='text-gray-100'
                        style={{ margin: "0", fontSize: "12px", fontFamily: "medium" }}>({studentCount ? studentCount : 0} Enrolled)</p>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "8px", margin: "14px 0 14px 14px" }}>
                    <p style={{ margin: "0", fontSize: "16px", fontFamily: "semiBold", color: theme.primary }}>₹{discountedPrice}</p>
                    <p  className='text-gray-100' style={{ margin: "0", fontSize: "12px", fontFamily: "medium", textDecorationLine: "line-through" }}>₹{price}</p>
                </div>
            </div>
        </div>
    )
}

export default Card
