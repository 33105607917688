import React from 'react'
import loadingGif from "../../assets/images/loading.gif";

const LoadingAnimation = ({style, className}) => {
    return (
        <img
            alt={"Loading..."}
            className={className}
            style={{
                width: "100px",
                maxHeight: "300px",
                ...style
            }}
            src={loadingGif}/>
    )
}

export default LoadingAnimation
