import React, { useEffect, useState } from 'react'
import Header from './header'
import VideoPlayer from './videoPlayer'
import { Outlet, useLocation } from "react-router-dom";
import api from '../../services/api'
import PersonImage from "../../assets/images/person.jpg"
import Model from '../../components/globle/model';
import DownloadIcon from '../../assets/icons/downloadIcon';
import "../../assets/css/learn.css"

const Learn = ({ isAuthenticated }) => {
    const [sections, setSections] = useState([])
    const [currentVideoUrl, setCurrentVideoUrl] = useState("")
    const [resourceModel, setResourceModel] = useState(false)
    const [courseName, setCourseName] = useState("")
    const [courseDescription, setCourseDescription] = useState("")
    const [resources, setResources] = useState([])
    const [author, setAuthor] = useState({})
    const [currentCourseId, setCurrentCourseId] = useState(0)
    const [currentVideoNumber, setCurrentVideoNumber] = useState(0)
    const [totalTopicsLength, setTotalTopicsLength] = useState(0)
    const [currentTopicId, setCurrentTopicId] = useState(0)
    const [videoCompletingCheck, setVideoCompletingCheck] = useState({
        topicId: null,
        isCompleted: false
    })
    const [loadingContainers, setLoadingContainers] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [isCourseComplted, setIsCourseCompleted] = useState(false)
    const [courseId, setCourseId] = useState(0)

    const [isLoading, setIsLoading] = useState(true)

    const location = useLocation();

    const getCourseApi = async () => {

        const currentUrlPath = location.pathname;
        const urlArray = currentUrlPath.split("/")
        const courseSlug = urlArray[urlArray.length - 1]


        try {
            const response = await api.getCourseBySlug(courseSlug);
            const data = response && response.data && response.data.data && response.data.data.sections ? response.data.data.sections : []
            const cName = response && response.data && response.data.data && response.data.data.name ? response.data.data.name : ""
            const cDescription = response && response.data && response.data.data && response.data.data.description ? response.data.data.description : "";
            const courseId = response?.data?.data?.id || 0;
            const sections = response?.data?.data?.sections || [];
            setCourseId(courseId)



            setCurrentCourseId(courseId)
            setAuthor(response?.data?.data?.user || {})
            setSections(data)
            setCourseDescription(cDescription)
            setCourseName(cName)
            setIsLoading(false)
            totalTopicHandle(data)
            checkIsCourseComplete(sections)


            if (response && response.data && response.data.data && response.data.data.sections[0] && response.data.data.sections[0].topics[0] && response.data.data.sections[0].topics[0].file_id) {
                const topicId = response.data.data.sections[0].topics[0].id;

                await videoUrl(response.data.data.sections[0].topics[0].file_id, topicId)
            }
        } catch (e) {
            // alert("Unable to fetch course")
            console.error(e)
        }

    }

    const checkIsCourseComplete = (sections) => {
        let totalTopics = 0;
        let completedTopics = 0;

        for (let i = 0; i < sections.length; i++) {
            totalTopics = totalTopics + sections[i].topics.length
            for (let j = 0; j < sections[i].topics.length; j++) {
                if (sections[i].topics[j].isWatched) {
                    completedTopics++;
                }

            }
        }

        if (completedTopics >= totalTopics) {
            setIsCourseCompleted(true)
        }
    }

    const onResourceClick = async (topicId) => {
        try {
            setResourceModel(true)

            const response = await api.getResources(topicId)

            setResources(response?.data?.data || [])
        } catch (e) {
            // alert("Unable to fetch resources")
            console.error(e);
        }


    }

    const videoUrl = async (i, id) => {
        if (id) {
            setCurrentTopicId(id)
        }
        try {
            const data = {
                fileId: i
            }
            const getVideoUrl = await api.readFileUrl(data);
            const url = getVideoUrl?.data?.data || "";
            setCurrentVideoUrl(url)

            let cT = 0;
            for (let j = 0; j < sections.length; j++) {
                for (let k = 0; k < sections[j].topics.length; k++) {
                    if (sections[j].topics[k].file_id === i) {
                        setCurrentVideoNumber(cT)
                    }
                    cT = cT + 1
                }
            }

        } catch (e) {
            // alert("Unable to fetch video")
            console.error(e)
        }
    }

    const userTrackUpdate = () => {
        let totalTopic = 0;
        let watchedTopic = 0;

        for (let i = 0; i < sections.length; i++) {
            for (let j = 0; j < sections[i].topics.length; j++) {
                totalTopic = totalTopic + 1;
                watchedTopic = watchedTopic + (sections[i].topics[j].isWatched ? 1 : 0)
            }
        }
        const percentage = (watchedTopic / totalTopic) * 100

        try {
            const res = api.updateDuration(currentCourseId, percentage ? percentage : 0)

        } catch (e) {
            console.log(e)

        }

    }

    const nextVideoHandle = async () => {
        setCurrentVideoNumber(currentVideoNumber + 1)
        let currentVideoIndex = currentVideoNumber + 1;

        let topicCount = 0;
        let totalTopicLangth = 0;

        for (let i = 0; i < sections.length; i++) {
            for (let j = 0; j < sections[i].topics.length; j++) {
                if (currentVideoIndex >= totalTopicsLength) {
                    setCurrentVideoNumber(-1)
                    return;
                }
                if (currentVideoIndex === topicCount) {
                    const fileID = sections[i].topics[j].file_id
                    const topicId = sections[i].topics[j].id
                    setCurrentTopicId(topicId)
                    await videoUrl(fileID, topicId)
                    return;
                }
                topicCount = topicCount + 1;
            }
        }


    }

    const totalTopicHandle = (sections) => {
        let topicsLength = 0;
        for (let i = 0; i < sections.length; i++) {
            topicsLength = topicsLength + sections[i].topics.length
        }

        setTotalTopicsLength(topicsLength)
    }

    const videoCompleting = () => {
        const data = {
            topicId: currentTopicId,
            isCompleted: true
        }
        setVideoCompletingCheck(data)
    }

    useEffect(() => {
        getCourseApi()
    }, [])

    useEffect(() => {
        return () => {
            userTrackUpdate();
        }
    }, [sections])




    return (
        <div

            className='bg-black h-100vh overflow-auto scroll-0'>
            <Header name={courseName ? courseName : ""} isAuthenticated={isAuthenticated} />
            <div
                className='flex-column-phone p-0-phone '
                style={{
                    display: "flex",
                    padding: "18px"

                }}
            >
                <div
                    className='w-100p-phone p-0-phone overflow-auto scroll-0 h-auto-phone b-radius-0-phone position-sticky-phone top-0-phone'
                    style={{
                        width: "65%",
                        marginRight: "20px",
                        height: "calc(100vh - 20px - 48px - 40px - 3px)",
                        borderRadius: "12px",
                        zIndex: "2"


                    }}>
                    {
                        !isLoading
                            ?
                            <VideoPlayer
                                videoCompleting={videoCompleting}
                                onNextClick={nextVideoHandle}
                                videoUrl={currentVideoUrl}
                            />
                            :
                            <div
                                className={"bg-dark-500 b-radius-0-phone"}
                                style={{
                                    width: "100%",
                                    aspectRatio: "639/359",
                                    borderRadius: "12px"
                                }}
                            />
                    }

                    <div
                        className='h-auto-phone b-radius-0-phone p-20-phone overflow-auto bg-dark-500 d-none-phone'
                        style={{
                            width: "100%",
                            backgroundColor: "#F6F6F9",
                            borderRadius: "12px",
                            marginTop: "20px",
                            padding: "40px",
                            overflow: "scroll"

                        }}
                    >
                        {
                            !isLoading
                                ?
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "12px",
                                            marginBottom: "26px"

                                        }}
                                    >
                                        <img
                                            alt="Avatar"
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "50%",
                                                objectFit: "cover"
                                            }}
                                            src={author.avatar ? author.avatar : PersonImage} />
                                        <p
                                            className='fs-14-phone text-white'
                                            style={{
                                                margin: "0",
                                                fontSize: "16px",
                                                fontFamily: "semiBold"
                                            }}
                                        >{author && author.first_name && author.last_name ? author.first_name + " " + author.last_name : "Anurag Sunderka"}</p>
                                    </div>
                                    <p
                                        className='fs-20-phone text-white'
                                        style={{
                                            margin: "0",
                                            fontSize: "24px",
                                            fontFamily: "semiBold",
                                            marginBottom: "10px"
                                        }}
                                    >Overview</p>
                                    <p
                                        className='fs-14-phone text-gray-100 fs-14'
                                        style={{
                                            margin: "0",
                                            fontSize: "18px",
                                            fontFamily: "medium"
                                        }}
                                    >{courseDescription ? courseDescription : "For web-based features, MS Exec for Finance is optimized for the latest versions of popular web browsers such as Google Chrome, Mozilla Firefox, and Microsoft Edge."}</p>
                                </div>
                                :
                                <div
                                    className="description-box-phone"
                                    style={{
                                        width: "100%",
                                        height: "138px"
                                    }}
                                >
                                </div>

                        }


                    </div>
                </div>
                <div
                    className='w-100p-phone mt-20-phone'
                    style={{
                        width: "35%"
                    }}
                >
                    <div
                        className='b-radius-0-phone p-10-phone bg-dark-500 h-auto-phone scroll-0'
                        style={{
                            height: "calc(100vh - 20px - 48px - 40px - 3px)",
                            background: "#F6F6F9",
                            borderRadius: "12px",
                            overflow: "auto",
                            padding: "12px",
                            position: "relative"

                        }}>
                        {
                            isLoading
                                ?
                                <div className='d-flex flex-column g-10 '>
                                    {
                                        loadingContainers.map((i, index) => {
                                            return (
                                                <div

                                                    key={index}
                                                    className={"bg-dark-400 topic-cart-phone"}
                                                    style={{
                                                        width: "100%",
                                                        height: "77px",
                                                        borderRadius: "12px",
                                                    }}
                                                />
                                            )
                                        })

                                    }

                                </div>
                                :
                                <Outlet context={{
                                    sections,
                                    videoUrl,
                                    onResourceClick,
                                    videoCompletingCheck,
                                    setVideoCompletingCheck,
                                    currentTopicId,
                                    totalTopicsLength,
                                    isCourseComplted,
                                    courseId
                                }} />
                        }
                    </div>
                </div>
            </div>
            <Model
                title={"Resources"}
                subTitle={"Download resources"}
                onCloseClick={() => setResourceModel(false)}
                isShow={resourceModel}
            >
                <div
                    style={{
                        padding: "30px 30px",
                        maxHeight: "500px",
                        overflow: "auto"
                    }}
                >

                    <div
                        className='col-4 col-3-phone'
                        style={{
                            gap: "25px 15px"
                        }}
                    >
                        {
                            resources && resources.length !== 0 ?
                                resources.map((i, index) => {
                                    return (
                                        <div
                                            key={index}
                                        >

                                            <a href={i.link} target={"_blank"}>
                                                <button

                                                    style={{
                                                        borderRadius: "12px",
                                                        height: "140px",
                                                        width: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        overflow: "hidden"
                                                    }} className=" border-0 bg-dark-500 cursor-pointer">
                                                    <div
                                                        className={"bg-dark-500"}
                                                    >
                                                        <DownloadIcon />

                                                    </div>
                                                </button>
                                            </a>

                                            <p
                                                className='text-white'
                                                style={{
                                                    fontSize: "12px",
                                                    fontFamily: "medium",
                                                    color: "black",
                                                    margin: "10px 0 0 0",
                                                    textAlign: "center"
                                                }}
                                            >
                                                Resource {index + 1}
                                            </p>
                                        </div>

                                    )
                                })
                                :
                                <p className='ff-bold text-white'>Loading...</p>
                        }

                    </div>
                </div>
            </Model>
        </div>
    )
}

export default Learn
