import React from 'react';

const FileIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="16" fill="#363636" />
            <path d="M21.5338 19.9423L21.534 19.9418L22.9308 15.4761L22.931 15.4755C23.0732 15.0142 22.9896 14.5243 22.7023 14.1332C22.4148 13.7417 21.97 13.5157 21.4845 13.5157H21.3033V12.7751C21.3033 11.942 20.6265 11.2657 19.7908 11.2657H15.8158C15.6276 11.2657 15.4466 11.191 15.3118 11.059C15.3117 11.0589 15.3116 11.0587 15.3115 11.0586L14.6875 10.4378C14.6875 10.4378 14.6874 10.4377 14.6874 10.4377C14.4029 10.1533 14.0242 9.99697 13.622 9.99697H10.5158C9.68273 9.99697 9.00642 10.6737 9.00642 11.5095V19.4353C8.99657 19.7707 9.09714 20.1029 9.30133 20.3798C9.59179 20.7739 10.0395 21.0032 10.5314 21.0032H20.0908C20.7537 21.0032 21.333 20.5759 21.5338 19.9423ZM9.80017 11.6595H9.8033V11.5095C9.8033 11.117 10.124 10.797 10.5127 10.797H13.622C13.8092 10.797 13.9877 10.871 14.1266 11.0042L14.7504 11.6249C14.7504 11.625 14.7504 11.625 14.7505 11.625C15.0349 11.9094 15.4137 12.0657 15.8158 12.0657H19.7939C20.1864 12.0657 20.5064 12.3864 20.5064 12.7751V13.5157H11.9064C11.2437 13.5157 10.6644 13.9428 10.4635 14.5733L10.4633 14.574L9.80017 16.689V11.6595ZM22.1635 15.2391L22.1633 15.2397L20.7664 19.7053L20.7663 19.7056C20.6735 20.0046 20.4009 20.2032 20.0877 20.2032H10.5283C10.2958 20.2032 10.0839 20.0955 9.94275 19.9079C9.80469 19.7172 9.76431 19.4823 9.83391 19.2607L9.83396 19.2605L11.2246 14.8136L11.2247 14.8133C11.3175 14.5143 11.5901 14.3157 11.9033 14.3157H21.4814C21.7121 14.3157 21.9201 14.4214 22.0542 14.6044L22.0542 14.6044L22.0552 14.6057C22.1949 14.792 22.2331 15.0198 22.1635 15.2391Z" fill="white" stroke="white" strokeWidth="0.3" />
        </svg>
    );
};

export default FileIcon;
