import React, {useEffect, useState} from 'react'
import Header from '../../components/globle/header'
import Card from '../../components/landing/card'
import {Link} from 'react-router-dom'
import api from '../../services/api'
import Loading from '../../components/globle/loading'
import config from '../../config.json'

const Landing = ({isAuthenticated, isAdmin, currentUser, isUser}) => {

    const [allCourses, setAllCourses] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [loadingArray, setLoadingArray] = useState([0,0,0,0,0,0,0])


    const initFunction = async () => {

        try {
            const getAllCourses = await api.getAllCourses();

            if (getAllCourses && getAllCourses.data && getAllCourses.data.data) {
                const resData = getAllCourses?.data?.data || []
                setAllCourses(resData)
                isUser()
            }
            setIsLoading(false)

        } catch (e) {
            alert("Unable to fetch all courses")
            setIsLoading(false)
        }


    }

    useEffect(() => {

        if(config?.env === "development"){
            initFunction()
        }else{
            window.location.href = config.zl_url;
        }

    }, [])



    if (isLoading) {
        return (

            <Loading/>

            // <div className='bg-black h-100vh overflow-auto scroll-0-phone scroll-0'>
            //
            //     <HeaderLoading currentUser={currentUser} isAdmin={isAdmin} isAuthenticated={isAuthenticated}
            //                    styles={{boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)"}}/>
            //     <div className='top-cont'>
            //         <div className='' style={{
            //             padding: "60px 20px 0px 0px",
            //             display: "flex",
            //             justifyContent: "space-between",
            //             alignItems: "center"
            //         }}>
            //             <p
            //                 className='text-white'
            //                 style={{margin: "0", fontSize: "20px", fontFamily: "bold"}}>All Courses</p>
            //
            //         </div>
            //         <div
            //             className='grid-template-100-phone'
            //             style={{
            //                 display: "grid",
            //                 gridTemplateColumns: "repeat(4, 1fr)",
            //                 marginTop: "50px",
            //                 columnGap: "30px",
            //                 rowGap: "40px"
            //             }}>
            //             {
            //                 loadingArray.length !== 0 ? loadingArray.map((i, index) => {
            //
            //                         return (
            //
            //
            //                                 <CardLoading
            //                                     key={index}
            //                                     imgUrl={i.course_image_url}
            //                                     discountedPrice={i.discounted_price}
            //                                     price={i.price}
            //                                     studentCount={i.student_count}
            //                                     starRating={i.star_rating}
            //                                     category={i && i.course_categories && i.course_categories.length !== 0 && i.course_categories[0].category && i.course_categories[0].category.name ? i.course_categories[0].category.name : "null"}
            //                                     courseName={i.name}/>
            //
            //
            //                         )
            //                     })
            //                     :
            //                     <p className='text-white'>No Courses Found</p>
            //             }
            //         </div>
            //
            //
            //     </div>
            // </div>

        )
    } else {
        return (
            <div className='bg-black h-100vh overflow-auto scroll-0-phone scroll-0'>
                <Header currentUser={currentUser} isAdmin={isAdmin} isAuthenticated={isAuthenticated}
                        styles={{boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)"}}/>
                <div className='top-cont'>
                    <div className='' style={{
                        padding: "60px 20px 0px 0px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <p
                            className='text-white'
                            style={{margin: "0", fontSize: "20px", fontFamily: "bold"}}>All Courses</p>

                    </div>
                    <div
                        className='grid-template-100-phone'
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(4, 1fr)",
                            marginTop: "50px",
                            columnGap: "30px",
                            rowGap: "40px"
                        }}>
                        {
                            allCourses.length !== 0 ? allCourses.map((i, index) => {

                                    return (

                                        <Link

                                            key={index}
                                            style={{
                                                color: "inherit",
                                                textDecoration: "none"
                                            }}
                                            to={`/course/info/${i.slug}`}
                                            state={{courseId: i.id}}
                                        >
                                            <Card
                                                imgUrl={i.course_image_url}
                                                discountedPrice={i.discounted_price}
                                                price={i.price}
                                                studentCount={i.student_count}
                                                starRating={i.star_rating}
                                                category={i && i.course_categories && i.course_categories.length !== 0 && i.course_categories[0].category && i.course_categories[0].category.name ? i.course_categories[0].category.name : "null"}
                                                courseName={i.name}/>
                                        </Link>

                                    )
                                })
                                :
                                <p className='text-white'>No Courses Found</p>
                        }
                    </div>


                </div>
            </div>
        )
    }


}

export default Landing
