import React, {useEffect, useState} from 'react'
import HalfArrow from '../../assets/icons/halfArrow'
import Topic from './topic'
import {useParams} from "react-router-dom";

const Section = ({topics, name, onTopicClick, sectionId, index}) => {
    const {course, section, topic} = useParams();
    const [isOpen, setIsOpen] = useState(false)
    const [topicsTime, setTopicsTime] = useState(0)
    const [completedTopicCount, setCompletedTopicCount] = useState(0)

    useEffect(() => {
        if (sectionId == parseInt(section)) {
            setIsOpen(true)
        }else{
            setIsOpen(false)
        }

        if(!section && index === 0){
            setIsOpen(true)
        }
    }, [])

    return (
        <div
            className='p-10-phone bg-dark-400'
            style={{
                background: "white",
                borderRadius: "12px",
                padding: "18px"
            }}
        >
            <div
                className='cursor-pointer'
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
                style={{
                    display: "flex",
                    borderRadius: "12px",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px"
                    }}
                >
                    <p
                        className='fs-14-phone text-white'
                        style={{
                            margin: "0",
                            fontSize: "15px",
                            fontFamily: "medium"
                        }}
                    >{name ? name : "No Name"}</p>
                    <p
                        className='fs-10-phone text-gray-100'
                        style={{
                            margin: "0",
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "bold",
                            fontSize: "12px",
                            color: "#C3C3C3",
                            gap: "8px"
                        }}
                    > {topics ? topics.length : "0"} Topic
                    </p>
                </div>
                <div
                    style={{
                        display: "flex",
                        gap: "18px",
                        alignItems: "center"
                    }}
                >

                    <HalfArrow
                        className="w-10-phone filter-invert-100"
                        style={{
                            width: "16px",
                            height: "16px",
                            transform: isOpen ? "" : "rotate(180deg)"
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    display: isOpen ? "" : "none"
                }}
            >
                <div
                    className='bg-dark-500'
                    style={{
                        width: "100%",
                        background: "#F6F6F9",
                        height: "2px",
                        margin: "10px 0"
                    }}
                ></div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px"
                    }}
                >

                    {
                        topics.map((topic, index) => {
                            return (
                                <Topic
                                    index={index}
                                    onClick={() => onTopicClick(topic)}
                                    time={topic?.file?.duration || 0}
                                    key={index}
                                    id={topic.id}
                                    name={topic.name}/>
                            )
                        })
                    }


                </div>
            </div>
        </div>
    )
}

export default Section
