import React, { useEffect, useState } from 'react'
import CourseCart from '../../components/courses/courseCart'
import api from '../../services/api'
import { useNavigate } from 'react-router-dom'
import Loading from '../../components/globle/loading'
import Category from '../../components/category/category'
import config from '../../config.json'


const Courses = () => {
    const [courses, setCourses] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [totalPage, setTotalPage] = useState(0)
    const [page, setPage] = useState(1)
    const [coursesLimit, setCoursesLimit] = useState(6)
    const [k12, setK12] = useState(true)
    const navigation = useNavigate()

    const getAllCourses = async () => {

        try {
            const response = await api.getAllCoursesAdminV2(page, coursesLimit, k12 ? '1' : '0');
            const data = response?.data?.data || [];
            setCourses(data)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }

        setIsLoading(false)
    }


    const statusHandle = async (slug) => {
        try {
            const response = await api.courseStatusHandle(slug);
            getAllCourses()
        } catch (e) {
            alert("Unable to update")
        }
    }


    const deleteCourse = async (slug) => {

        try {
            const confirmed = window.confirm("Are you sure you want to delete this course?");
            if (confirmed) {
                const response = await api.deleteCourseBySlug(slug);
                getAllCourses()
            }

        } catch (e) {
            alert("Unable to delete")
        }
    }

    const copyLinkHandle = async (text) => {
        const finalText = config.domain_name + '/#/k12/' + text
        try {
            await navigator.clipboard.writeText(finalText);
            alert('Text Copied');
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    }

    useEffect(() => {
        getAllCourses()
    }, [page, k12])


    if (isLoading) {
        return (
            <Loading />
        )
    } else {
        return (
            <div
                className='top-cont'
                style={{
                    marginTop: "72px"
                }}
            >
                <div className='d-flex j-c-end'>
                    <button
                        style={{
                            marginBottom: "10px",
                            float: 'left'
                        }}
                        className={'btn-dark-500 bg-dark-400-hover text-hover-white text-primary'}
                        onClick={() => setK12(!k12)}>
                        {k12 ? "K12" : "Course"}
                    </button>
                </div>
                {
                    courses.length !== 0 ?
                        courses.map((i, index) => {
                            return (
                                <CourseCart
                                    onClickCopyLink={() => copyLinkHandle(i && i.slug ? i.slug : "")}
                                    k12={k12}
                                    className={'bg-black text-white'}
                                    titleClassName={'text-white'}
                                    status={true}
                                    activeStatus={i && i.is_active ? i.is_active : null}
                                    onStatusClick={() => statusHandle(i && i.slug ? i.slug : "")}
                                    idx={index}
                                    coursesLength={courses.length}
                                    firstName={i && i.user && i.user.first_name ? i.user.first_name : ""}
                                    lastName={i && i.user && i.user.last_name ? i.user.last_name : ""}

                                    onEditClick={() => navigation(`/admin/course/edit/${i.slug}`)}
                                    key={index}
                                    imageUrl={i?.k12 === 1 ? 'https://zebralearn-content-studio.s3.ap-south-1.amazonaws.com/storage/images/k12_logo.png' : i.course_image_url}
                                    courseName={i.name}
                                    categories={i.course_categories.length >= 0 ? i.course_categories : []}
                                    starRating={i.star_rating}
                                    studentCount={i.student_count}
                                    sectionsCount={i.sections.length}
                                    price={i?.discounted_price || "Free"}
                                    cutPrice={i.price}
                                    isEdit={true}
                                    isDelete={true}
                                    onDeleteClick={() => deleteCourse(i?.slug || "")}
                                />
                            )
                        }) : <p>No courses found. Create course</p>
                }
                <div className={"d-flex g-10 j-c-center"}>
                    <button
                        onClick={() => {
                            if (page === 1) return;
                            setPage(page - 1)
                        }}
                        className={'btn-dark-500'}>{" < Previous"}</button>
                    <button className={'btn-dark-500'}>{page}</button>
                    <button
                        onClick={() => {
                            if (courses.length < coursesLimit) return;
                            setPage(page + 1)
                        }}
                        className={'btn-dark-500'}>{"Next >"}</button>
                </div>

            </div>
        )
    }


}

export default Courses
