import React, {useEffect, useState} from 'react'
import theme from '../../../theme/theme'
import utility from "../../../utility/utilities"

const CourseContentLesson = ({lesson, expandSection, name, index, item, time}) => {


    return (
        <div className='border-dark-500 topic-container-phone' style={{
            padding: "15px 40px",
            display: "flex",
            alignItems: "center",
            borderBottom: item && item.length === index + 1 ? "" : `2px solid ${theme.color.gray100}`
        }}>
            <div className='bg-white'
                 style={{
                     width: "4px",
                     minWidth: "4px",
                     height: "4px",
                     minHeight: "4px",
                     background: "black",
                     borderRadius: "50%",
                     marginRight: "30px"}}
            ></div>
            <div className='bg-gray-300'
                 style={{
                     width: "3px",
                     minWidth: "3px",
                     height: "40px",
                     background: theme.color.gray100,
                     marginRight: "30px"
                 }}
            ></div>
            <div style={{display: "flex", flexDirection: "column", gap: "12px"}}>
                <p className='fs-10-phone text-gray-100' style={{
                    margin: 0,
                    fontFamily: "bold",
                    fontSize: "14px",
                    color: "#B4B4B4"
                }}>{time ? ((time / 60).toFixed(2)) + " min" : "00:00"}</p>
                <p className='fs-12-phone text-white'
                   style={{margin: 0, fontFamily: "semiBold", fontSize: "14px"}}>{name ? name : "name"}</p>
            </div>
        </div>
    )
}

export default CourseContentLesson
