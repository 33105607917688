import React from 'react'
import loadingGif from "../../assets/images/loading.gif"

const Loading = ({style}) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "black",
                height: "100vh",
                width: "100%",
                position: "absolute",
                top: "0px",
                left: "0px",
                ...style
            }}
        >
            <img
                style={{
                    width: "100px"
                }}
                src={loadingGif}/>
        </div>
    )
}

export default Loading