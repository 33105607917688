import React, {useEffect, useState} from 'react';
import IconInput from "../globle/iconInput";
import Button from "../globle/button";
import api from "../../services/api";

const Category = () => {
    const [searchResponse, setsearchResponse] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [currentEditIndex, setCurrentEditIndex] = useState(null)
    const [currentEditValue, setCurrentEditValue] = useState("")


    const searchHandle = async (i) => {
        try {
            setSearchValue(i.target.value)
            const response = await api.searchCategories(i.target.value)
            console.log(response.data.data)
            if (response?.data?.data) setsearchResponse(response.data.data)

        } catch (e) {
            console.error(e)
        }
    }

    const createHandle = async () => {
        try {
            const response = await api.createCategory(searchValue);

            alert(response?.data?.message);
        } catch (e) {
            alert(e?.response?.data?.message)
            console.error(e)
        }
    }

    const updateCategoryHandle = async (id) => {
        try{
            const response = await api.updateCategory(id, currentEditValue);

            alert(response?.data?.message)
        }catch (e) {
            alert(e?.response?.data?.message)
            console.error(e)
        }
    }

    useEffect(()=>{
        searchHandle({target: { value: " "}})
    },[])

    return (
        <div className=' w-50p'>
            <div>
                <div className='d-flex g-10'>
                    <input

                        className='w-100p bg-dark-400 p-10 outline-none text-white border-dark-500 border-2-s-dark-500 b-radius-10px'
                        onChange={searchHandle}
                    />
                    <button className={'btn-dark-500 border-2-s-dark-400'} onClick={createHandle}>Create</button>
                </div>
                <div
                className='d-flex flex-column g-10 mt-10'
                >
                    {
                        searchResponse.map((i, index) => {
                            return (
                                <div key={index} className='d-flex bg-dark-400 j-c-s-b text-white b-radius-10px border-2-s-dark-500'>
                                    <input

                                        className='border-0 bg-transparent text-white p-10 outline-none b-radius-10px fs-16'
                                        readOnly={currentEditIndex === index ? false : true}
                                           type="text"
                                           onChange={(i) => currentEditIndex === index ? setCurrentEditValue(i.target.value) : null}
                                           value={currentEditIndex === index ? currentEditValue : i.name}
                                    />
                                    <button
                                        className={'btn-dark-500 border-2-s-dark-400'}
                                        onClick={async () => {
                                        if (currentEditIndex === index) {
                                            await updateCategoryHandle(i.id)
                                            setCurrentEditIndex(null)
                                            setCurrentEditValue("")
                                            await searchHandle({
                                                target: {
                                                    value: ""
                                                }
                                            })
                                            return;
                                        }
                                        setCurrentEditIndex(index)
                                        setCurrentEditValue(i.name)
                                    }}>{currentEditIndex === index ? "Done" : "Edit"}</button>
                                </div>
                            )
                        })
                    }


                </div>
            </div>
        </div>
    );
};

export default Category;
