import React from 'react'

const IconInput = ({ placeholder, icon, onChange, inputType }) => {
    return (
        <div
            className='bg-dark-400'
            style={{
                borderRadius: "16px",
                height: "56px",
                alignItems: "center",
                display: "flex"
            }}
        >

            {icon ?
                React.cloneElement(icon, {
                    style: {
                        padding: "0 20px"
                    },
                })
                :
                <></>
            }
            <input
                type={inputType ? inputType : "text"}
                onChange={(i) => { onChange(i) }}
                className='outline-none bg-dark-400 text-white'
                placeholder={placeholder}
                style={{
                    width: "-webkit-fill-available",
                    fontSize: "13px",
                    border: "0",
                    fontFamily: "medium",
                }}
            />
        </div>
    )
}

export default IconInput