import React, { useState } from 'react'

const Button = ({ title, buttonStyle, textStyle, onClick, className }) => {
    const [hover, setHover] = useState(false)
    const [click, setClick] = useState(false)
    return (
        <button
            className={`${className} bg-dark-400-hover text-hover-white`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onMouseDown={() => setClick(true)}
            onMouseUp={() => setClick(false)}
            onClick={() => onClick ? onClick() : null}
            style={{
                border: "0",
                borderRadius: "12px",
                background: hover ? click ? "#C3C3C3" :"#DFDFDF" :"#F6F6F6",
                height: "46px",
                padding: "0 32px",
                ...buttonStyle,
                cursor: "pointer"
            }}
        ><p
            style={{
                fontFamily: "semiBold",
                fontSize: "14px",
                margin: "0",
                ...textStyle
            }}
        >{title ? title : "Button"}</p></button>
    )
}

export default Button