import React from 'react'

const EmailIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 5.34884C0 2.39476 2.39476 0 5.34884 0H14.6512C17.6052 0 20 2.39476 20 5.34884V10.9302C20 13.8843 17.6052 16.2791 14.6512 16.2791H5.34884C2.39476 16.2791 0 13.8843 0 10.9302V5.34884ZM6.0526 5.92477C5.72915 5.66601 5.25718 5.71845 4.99843 6.0419C4.73967 6.36534 4.79211 6.83731 5.11556 7.09607L8.51733 9.81749C9.52168 10.621 10.9488 10.621 11.9532 9.81749L15.3549 7.09607C15.6784 6.83731 15.7308 6.36534 15.4721 6.0419C15.2133 5.71845 14.7413 5.66601 14.4179 5.92477L11.0161 8.64618C10.5596 9.0114 9.91089 9.0114 9.45437 8.64618L6.0526 5.92477Z" fill="#010101" />
        </svg>
    )
}

export default EmailIcon