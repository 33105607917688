import React, {useState} from 'react'
import zebraLogo from '../../assets/ZEBRALEARN.svg'
import theme from '../../theme/theme'
import IconInput from '../../components/globle/iconInput'
import EmailIcon from '../../assets/icons/emailIcon'
import LockIcon from '../../assets/icons/lockIcon'
import Btn from '../../components/auth/btn'
import api from '../../services/api'
import { useNavigate} from 'react-router-dom'
import utilities from '../../utility/utilities'

const Signin = ({setIsAuthenticated, isUser}) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [resMessage, setResMessage] = useState("")
    const [resSuccess, setResSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate();


    const submitFormHandle = async () => {


        try {
            setIsLoading(true)

            const data = {
                email,
                password
            }

            console.log("sign: ", data)
            const response = await api.signIn(data)
            const token = response?.data?.data?.token || "";
            utilities.setCookie("token", token, 20)
            setResSuccess(true);
            setResMessage(response?.data?.message || "something went wrong");
            setIsAuthenticated(true);
            await isUser();
            navigate("/admin")
            setIsLoading(false)
        } catch (e) {
            setResSuccess(false)
            setResMessage(e?.response?.data?.message || "something went wrong")
            setIsAuthenticated(false)
            setIsLoading(false)
        }

        setIsLoading(false)
    }


    return (
        <div
            className='bg-black'
            style={{
                width: "100vw",
                height: "100vh",
                display: 'flex',
                justifyContent: "center",
                overflow: "auto"
            }}
        >

            <div
                className='bg-dark-500 b-radius-0-phone'
                style={{
                    width: "550px",
                    borderRadius: "20px",
                    padding: "30px",
                    alignSelf: "center"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "46px"
                    }}
                >
                    <img
                        alt={"ZEBRALEARN"}
                        style={{
                            height: "23px",
                        }}
                        src={zebraLogo}
                    />


                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >

                </div>

                {
                    !resSuccess ?

                        <p
                            style={{
                                fontSize: "16px",
                                fontFamily: "medium",
                                color: "red",
                                textAlign: "center",
                                margin: "0 0 40px 0",
                                textTransform: "capitalize"

                            }}
                        >{resMessage}</p>
                        :
                        <></>
                }

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        marginBottom: "20px"
                    }}
                >

                    <IconInput onChange={(i) => setEmail(i.target.value)} icon={<EmailIcon/>} placeholder={"Email"}/>
                    <IconInput inputType={"password"} onChange={(i) => setPassword(i.target.value)} icon={<LockIcon/>}
                               placeholder={"Password"}/>
                </div>

                <Btn
                    isLoading={isLoading}
                    onClick={submitFormHandle}
                    title={"Login"}
                    style={{
                        marginBottom: "24px"
                    }}
                />


                <p
                    className='text-white'
                    style={{
                        fontSize: "14px",
                        fontFamily: "medium",
                        textAlign: "center",
                        margin: "0"
                    }}
                >By using our service you are agreeing to
                    our
                    <a target="_blank"
                       className="text-decoration-none"
                       href='https://zebralearn.com/Privacy_Policy_Reader.html'>
                    <span
                        style={{
                            color: theme.primary,
                            fontFamily: "bold"
                        }}
                    > Terms and condition</span>
                    </a>
                </p>

            </div>

        </div>
    )
}

export default Signin
