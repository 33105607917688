import React, { useState } from 'react'
import Section from './section'
import { useOutletContext } from 'react-router-dom';
import api from "../../services/api";
import Model from "../../components/globle/model";
import LoadingAnimation from "../../components/globle/loadingAnimation";

const Sections = () => {
    const [showModel, setShowModel] = useState(false)
    const { sections, videoUrl, totalTopicsLength, isCourseComplted, courseId } = useOutletContext();
    const [certificateLoading, setCertificateLoading] = useState(false)

    const generateCertificate = async () => {
        try {
            setCertificateLoading(true)
            const response = await api.getCertificate(courseId);
            window.open(response.data.data, '_blank');
            setCertificateLoading(false)
        } catch (e) {
            alert(e?.response?.data?.message || "Unable to download")
            setCertificateLoading(false)
        }
    }

    return (
        <div

            style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px"
            }}
        >
            <button
                onClick={isCourseComplted ? generateCertificate : () => setShowModel(true)}
                className={`overflow-hidden border-0 bg-dark-400 b-radius-12px d-flex a-i-center j-c-center ${isCourseComplted ? "text-primary" : 'text-gray-100'} ff-semibold cursor-pointer bg-gray-300-hover bg-dark-400-active`}
                style={{
                    height: "50px"
                }}
            >
                {
                    certificateLoading
                        ?
                        <LoadingAnimation style={{
                            width: "50px"
                        }} />
                        :
                        "Download Certificate"

                }


            </button>


            {
                sections && sections.length !== 0 ?
                    sections.map((i, index) => {
                        return (
                            <Section
                                onClick={(i, id) => videoUrl(i, id)}
                                key={index}
                                topics={i.topics}
                                name={i.name}
                            />
                        )
                    })

                    : <Section
                        name={"Financial modeling"}
                    />
            }
            <Model
                title={"Certificates"}
                isShow={showModel}
                onCloseClick={() => setShowModel(false)}
            >
                <p className='text-white p-25'>Complete all topics to unlock your certificate. Happy learning!</p>
            </Model>
        </div>
    )
}

export default Sections
