import React from 'react'
import LoadingAnimation from "../globle/loadingAnimation";

const Btn = ({title, style, onClick, isLoading}) => {
    return (
        <button
            className="bg-primary"
            onClick={() => onClick()}
            style={{
                background: "#010101",
                fontSize: "14px",
                color: "white",
                borderRadius: "16px",
                fontFamily: "bold",
                height: "56px",
                border: "0",
                width: "100%",
                ...style,
                cursor: "pointer"
            }}
        >{
            isLoading ?
                <LoadingAnimation style={{width: "60px"}}/>
                :
                (title ? title : "Submit")
        }

        </button>
    )
}

export default Btn