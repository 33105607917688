import React, {useEffect, useState} from 'react'
import PlayIcon from '../../assets/icons/playIcon'
import DownloadIcon from '../../assets/icons/downloadIcon'
import {useOutletContext} from 'react-router-dom';
import TopicCheckIcon from "../../assets/icons/topicCheckIcon";
import TopicUncheckIcon from "../../assets/icons/topicUncheckIcon";
import api from "../../services/api";
import PauseIcon from "../../assets/icons/pauseIcon";
import FileIcon from "../../assets/icons/fileIcon";


const Topic = ({time, name, onClick, id, isWatched, resourses, setCompletedTopicCount, completedTopicCount}) => {

    const [isChecked, setIsChecked] = useState(false);
    const {onResourceClick, videoCompletingCheck, setVideoCompletingCheck, currentTopicId} = useOutletContext();

    const checkHandle = async () => {
        setIsChecked(!isChecked);
        const check = !isChecked ? "1" : "0"

        if (check === "1") {
            setCompletedTopicCount(completedTopicCount + 1)
        } else {
            setCompletedTopicCount(completedTopicCount - 1)
        }

        try {
            const response = await api.userTrackHandle(id, check)
        } catch (e) {
            alert("Unable to update")
        }
    }


    const videoCompleted = async () => {
        if (videoCompletingCheck.topicId === id && videoCompletingCheck.isCompleted && !isChecked) {
            setIsChecked(true);
            setCompletedTopicCount(completedTopicCount + 1)
            try {
                const response = await api.userTrackHandle(id, "1")
                const data = {
                    topicId: null,
                    isCompleted: false
                }
                setVideoCompletingCheck(data)
            } catch (e) {
                alert("Unable to update")
            }

        }

    }

    useEffect(() => {
        videoCompleted()
    }, [videoCompletingCheck])


    useEffect(() => {
        setIsChecked(isWatched ? true : false)
    }, [])


    return (
        <div

            className='bg-dark-500 cursor-pointer'

            style={{
                display: "flex",
                gap: "13px",
                background: "#F6F6F9",
                alignItems: "center",
                padding: "9px 20px",
                borderRadius: "12px",
                justifyContent: "space-between"

            }}
        >
            <div
                className='d-flex a-i-center g-10'
            >
                {
                    isChecked ?
                        <TopicCheckIcon
                            style={{
                                minWidth: "20px",
                                minHeight: "20px",
                                maxWidth: "20px",
                                maxHeight: "20px",
                                width: "20px",
                                height: "20px"
                            }}
                            onClick={() => checkHandle()}/>
                        : <TopicUncheckIcon
                            style={{
                                minWidth: "20px",
                                minHeight: "20px",
                                maxWidth: "20px",
                                maxHeight: "20px",
                                width: "20px",
                                height: "20px"
                            }}
                            onClick={() => checkHandle()}/>

                }
                <div
                    onClick={() => onClick ? onClick() : null}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        width: "-webkit-fill-available"

                    }}
                >
                    <p
                        className='text-white'
                        style={{
                            margin: "0",
                            fontFamily: "medium",
                            fontSize: "13px"
                        }}
                    >{name ? name : "No Topic"}</p>
                    <div
                        style={{
                            display: "flex",
                            gap: "7px",
                            alignItems: "center",

                        }}
                    >
                        <p
                            className='text-gray-100'
                            style={{
                                margin: "0px",
                                fontFamily: "semiBold",
                                fontSize: "12px",
                                color: "#BBBBBB"
                            }}
                        >{time ? ((time / 60).toFixed(2)) + " mins" : "00:00"}</p>

                    </div>
                </div>
            </div>
            <div
                style={{
                    width: "55px",
                    gap: "7px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end"
                }}
            >

                {
                    resourses.length === 0
                        ?
                        <></>
                        :
                        <FileIcon
                            onClick={() => onResourceClick ? onResourceClick(id) : null}
                            style={{
                                width: "24px",
                                height: "24px"
                            }}
                        />
                }
                {
                    currentTopicId === id
                        ?
                        <PauseIcon
                            style={{
                                width: "24px",
                                height: "24px"
                            }}
                        />
                        :
                        <PlayIcon
                            style={{
                                width: "24px",
                                height: "24px"
                            }}
                            onClick={() => onClick ? onClick() : null}/>
                }

            </div>
        </div>
    )
}

export default Topic
