import React from 'react';

const NotFound = () => {
    return (
        <div className={'d-flex a-i-center j-c-center h-100vh flex-column bg-black'}>
            <h1 className={'text-primary m-0'}>404</h1>
            <h1 className={'text-white'}>Page Not Found</h1>
        </div>
    );
};

export default NotFound;
