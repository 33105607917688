import React from 'react'
import RoundCloseIcon from '../../assets/icons/roundCloseIcon'
import Button from './button'

const Model = ({
                   children,
                   isShow,
                   onCloseClick,
                   onCancelClick,
                   onPrimaryButtonClick,
                   onOutsideClick,
                   primaryBtnTitle,
                   title,
                   subTitle
               }) => {


    if (isShow) {
        return (
            <div
                className='h-100vh overflow-auto scroll-0 position-fixed top-0 left-0'
                onClick={() => onOutsideClick ? onOutsideClick() : null}
                style={{
                    height: "100vh",
                    width: "100%",
                    background: "rgba(0, 0, 0, 0.40)",
                    WebkitBackdropFilter: "blur(5px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backdropFilter: "blur(5px)",
                    zIndex: "100000"
                }}
            >
                <div
                    className='bg-black'
                    style={{
                        width: "600px",
                        borderRadius: "20px",
                        overflow: "hidden",
                        background: "white"
                    }}
                >
                    <div
                        className='bg-dark-500'
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "25px",
                            background: "#F6F6F6",
                            gap: "10px"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px"
                            }}
                        >
                            {
                                title
                                    ?
                                    <p
                                        className='fs-18-phone text-white'
                                        style={{
                                            fontSize: "20px",
                                            color: "black",
                                            fontFamily: "semiBold",
                                            margin: "0"
                                        }}
                                    >{title}</p>
                                    :
                                    <></>
                            }
                            {
                                subTitle
                                    ?
                                    <p
                                        className='fs-14-phone text-gray-100'
                                        style={{
                                            fontSize: "15px",
                                            color: "#737373",
                                            fontFamily: "medium",
                                            margin: "0"
                                        }}
                                    >{subTitle}</p>
                                    :
                                    <></>

                            }


                        </div>
                        <RoundCloseIcon className='filter-invert-100 cursor-pointer'
                                        onClick={() => onCloseClick ? onCloseClick() : null}/>
                    </div>
                    {children}
                    {
                        onCancelClick || primaryBtnTitle || onPrimaryButtonClick
                            ?


                            <div
                                style={{
                                    display: "flex",
                                    boxShadow: "0px -4px 5px 0px rgba(0, 0, 0, 0.03)",
                                    justifyContent: "space-between",
                                    padding: "15px",
                                    gap: "10px"
                                }}
                            >
                                {
                                    onCancelClick
                                        ?
                                        <button
                                            className='bg-dark-500 text-white border-0 bg-dark-400-hover cursor-pointer'
                                            onClick={() => onCancelClick ? onCancelClick() : null}
                                            style={{
                                                borderRadius: "12px",
                                                height: "52px",
                                                width: "144px",
                                                background: "white"
                                            }}
                                        ><span
                                            style={{
                                                fontFamily: "semiBold",
                                                fontSize: "14px",

                                            }}
                                        >Cancel</span></button>
                                        :
                                        <></>

                                }


                                {
                                    primaryBtnTitle
                                        ?
                                        <Button
                                            className='bg-dark-500 text-white border-0 bg-dark-400-hover'
                                            buttonStyle={{
                                                width: "100%",
                                                height: "52px",
                                            }}
                                            onClick={() => onPrimaryButtonClick ? onPrimaryButtonClick() : null}
                                            title={primaryBtnTitle ? primaryBtnTitle : "Done"}
                                        />
                                        :
                                        <></>

                                }

                            </div>
                            :
                            <></>
                    }

                </div>
            </div>
        )
    } else {
        return (
            <></>
        )
    }

}

export default Model
