import React, { useEffect, useState } from 'react'
import HalfArrow from '../../assets/icons/halfArrow'
import Topic from './topic'

const Section = ({ topics, name, onClick }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [topicsTime, setTopicsTime] = useState(0)
    const [completedTopicCount, setCompletedTopicCount] = useState(0)

    const calculateSectionTime = () => {

        let totalSec = 0;
        if (!topics) {
            return;
        }
        for (let i = 0; i < topics.length; i++) {
            const sec = topics[i]?.file?.duration || "0"

            if (sec) {
                totalSec = totalSec + parseFloat(topics[i]?.file?.duration || 0)
            }
        }
        setTopicsTime((totalSec / 60).toFixed(2))
    }

    const isCompletedCount = () => {
        let completed = 0
        for (let i = 0; i < topics.length; i++) {
            if (topics[i] && topics[i].isWatched && topics[i].isWatched === 1) {
                completed++
            }
        }
        setCompletedTopicCount(completed)
    }

    useEffect(() => {
        calculateSectionTime()
        isCompletedCount()
    }, [topics])

    return (
        <div
            className='p-10-phone bg-dark-400'
            style={{
                background: "white",
                borderRadius: "12px",
                padding: "18px"
            }}
        >
            <div
                className='cursor-pointer'
                onClick={() => { setIsOpen(!isOpen) }}
                style={{
                    display: "flex",
                    borderRadius: "12px",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px"
                    }}
                >
                    <p
                        className='fs-14-phone text-white'
                        style={{
                            margin: "0",
                            fontSize: "15px",
                            fontFamily: "medium"
                        }}
                    >{name ? name : "No Name"}</p>
                    <p
                        className='fs-10-phone text-gray-100'
                        style={{
                            margin: "0",
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "bold",
                            fontSize: "12px",
                            color: "#C3C3C3",
                            gap: "8px"
                        }}
                    >{completedTopicCount} / {topics ? topics.length : "0"}
                        <span
                            style={{
                                width: "3px",
                                height: "3px",
                                background: "#BEBEBE",
                                borderRadius: "50%"
                            }}
                        ></span>
                        {topicsTime} mins</p>
                </div>
                <div
                    style={{
                        display: "flex",
                        gap: "18px",
                        alignItems: "center"
                    }}
                >

                    <HalfArrow
                        className="w-10-phone filter-invert-100"
                        style={{
                            width: "16px",
                            height: "16px",
                            transform: isOpen ? "" : "rotate(180deg)"
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    display: isOpen ? "" : "none"
                }}
            >
                <div
                    className='bg-dark-500'
                    style={{
                        width: "100%",
                        background: "#F6F6F9",
                        height: "2px",
                        margin: "10px 0"
                    }}
                ></div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px"
                    }}
                >

                    {
                        topics ? topics.map((i, index) => {
                            return (
                                <Topic setCompletedTopicCount={setCompletedTopicCount} completedTopicCount={completedTopicCount} resourses={i?.resourses || []} index={index} isWatched={i?.isWatched || 0} time={i?.file?.duration || 0} id={i.id} onClick={() => onClick(i.file_id, i.id)} key={index} duration="3 min" name={i.name} />
                            )
                        }) : <Topic duration="3 min" name={"Accounting and finance"} />
                    }


                </div>
            </div>
        </div>
    )
}

export default Section
