import React, { useEffect, useState } from 'react'
import Header from '../../pages/learn/header'
import VideoPlayer from '../../pages/learn/videoPlayer'
import PersonImage from "../../assets/images/ZebraLearnLogo.png"
import "../../assets/css/learn.css"
import HalfArrow from '../../assets/icons/halfArrow'
import TopicCheckIcon from "../../assets/icons/topicCheckIcon";
import TopicUncheckIcon from "../../assets/icons/topicUncheckIcon";
import PauseIcon from "../../assets/icons/pauseIcon";
import FileIcon from "../../assets/icons/fileIcon";
import PlayIcon from '../../assets/icons/playIcon'
import { useParams } from 'react-router-dom';
import { fill } from 'lodash'


const Public = ({ isAuthenticated }) => {
    const [currentVideoUrl, setCurrentVideoUrl] = useState("")
    const [courseDescription, setCourseDescription] = useState("")
    const [author, setAuthor] = useState({})
    const [loadingContainers, setLoadingContainers] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [isCourseComplted, setIsCourseCompleted] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [videos, setVideos] = useState([])
    const { name } = useParams();




    const data = [
        {
            name: 'trading-glossary-aseem-singhal',
            topics: [
                {
                    name: 'Basic Order Types',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/Basic+Order+Types.mp4'
                },
                {
                    name: 'Bear & Bull Market',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/Bear+%26+Bull+Market.mp4'
                },
                {
                    name: 'breakout and breakdown',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/breakout+and+breakdown.mp4'
                },
                {
                    name: 'Continuation Patterms',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/Continuation+Patterms.mp4'
                },
                {
                    name: 'Divergence',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/Divergence.mp4'
                },
                {
                    name: 'double tops and double bottoms',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/double+tops+and+double+bottoms.mp4'
                },
                {
                    name: 'Gap',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/Gap.mp4'
                },
                {
                    name: 'Head and shoulder patterns',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/Head+and+shoulder+patterns.mp4'
                },
                {
                    name: 'Market Breadth and Depth',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/Market+Breadth+and+Depth.mp4'
                },
                {
                    name: 'Market Sentiments',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/Market+Sentiments.mp4'
                },
                {
                    name: 'Reversal Pattern',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/Reversal+Pattern.mp4'
                },
                {
                    name: 'Sideways market',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/Sideways+market.mp4'
                },
                {
                    name: 'triangle pattern',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/triangle+pattern.mp4'
                },
                {
                    name: 'Volatility',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/Volatility.mp4'
                },
                {
                    name: 'Volume',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/Volume.mp4'
                },
                {
                    name: 'What are candlesticks',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/What+are+candlesticks.mp4'
                },
                {
                    name: 'What are support and resistance level',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/What+are+support+and+resistance+level.mp4'
                },
                {
                    name: 'What is trendline, types and ways to create trendline',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/What+is+trendline%2C+types+and+ways+to+create+trendline.mp4'
                },
                {
                    name: 'What us EMA, and the diffrence between EMA & SMA',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/What+us+EMA%2C+and+the+diffrence+between+EMA+%26+SMA.mp4'
                },
                {
                    name: 'Why Hieken Aishi and the difference in normal and Heikin Ashi candle',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/trading+glossary/Why+Hieken+Aishi+and+the+difference+in+normal+and+Heikin+Ashi+candle.mp4'
                },



            ]
        },
        {
            name: 'options-glossary-aseem-singhal',
            topics: [
                {
                    name: 'Basic Option Terminology - 2',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/option+glossary/Basic+Option+Terminology+-+2.mp4'
                },
                {
                    name: 'Basic Option Terminology',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/option+glossary/Basic+Option+Terminology.mp4'
                },
                {
                    name: 'Covered call strategy or Rental Strategy',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/option+glossary/Covered+call+strategy+or+Rental+Strategy.mp4'
                },
                {
                    name: 'How to read an options payoff chart',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/option+glossary/How+to+read+an+options+payoff+chart.mp4'
                },
                {
                    name: 'How to read analyze using NSE option chain',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/option+glossary/How+to+read+analyze+using+NSE+option+chain.mp4'
                },
                {
                    name: 'iron condor',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/option+glossary/iron+condor.mp4'
                },
                {
                    name: 'Option Greeks',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/option+glossary/Option+Greeks.mp4'
                },
                {
                    name: 'Straddles and Strangles',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/option+glossary/Straddles+and+Strangles.mp4'
                },
                {
                    name: 'What is Implied Volatility',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/option+glossary/What+is+Implied+Volatility.mp4'
                },
                {
                    name: 'is put buying equal to call selling',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/option+glossary/is+put+buying+equal+to+call+selling.mp4'
                },
                {
                    name: 'What are options',
                    url: 'https://zebralearn-lms.s3.ap-south-1.amazonaws.com/lms/public+videos/option+glossary/What+are+options.mp4'
                },

            ]
        },
    ]


    const initFunc = () => {
        const current = data.find(i => i.name === name);
        console.log('first: ', current)
        setVideos(current.topics);
        setCurrentVideoUrl(current.topics[0].url)
    }

    useEffect(() => {
        initFunc()
    }, [])
















    return (
        <div

            className='bg-black h-100vh overflow-auto scroll-0'>
            <Header name={""} isAuthenticated={isAuthenticated} />
            <div
                className='flex-column-phone p-0-phone '
                style={{
                    display: "flex",
                    padding: "18px"

                }}
            >
                <div
                    className='w-100p-phone p-0-phone overflow-auto scroll-0 h-auto-phone b-radius-0-phone position-sticky-phone top-0-phone'
                    style={{
                        width: "65%",
                        marginRight: "20px",
                        height: "calc(100vh - 20px - 48px - 40px - 3px)",
                        borderRadius: "12px",
                        zIndex: "2"


                    }}>
                    {
                        !isLoading
                            ?
                            <VideoPlayer
                                videoUrl={currentVideoUrl}
                            />
                            :
                            <div
                                className={"bg-dark-500 b-radius-0-phone"}
                                style={{
                                    width: "100%",
                                    aspectRatio: "639/359",
                                    borderRadius: "12px"
                                }}
                            />
                    }


                </div>
                <div
                    className='w-100p-phone mt-20-phone'
                    style={{
                        width: "35%"
                    }}
                >
                    <div
                        className='b-radius-0-phone p-10-phone bg-dark-500 h-auto-phone scroll-0'
                        style={{
                            height: "calc(100vh - 20px - 48px - 40px - 3px)",
                            background: "#F6F6F9",
                            borderRadius: "12px",
                            overflow: "auto",
                            padding: "12px",
                            position: "relative"

                        }}>
                        {
                            isLoading
                                ?
                                <div className='d-flex flex-column g-10 '>
                                    {
                                        loadingContainers.map((i, index) => {
                                            return (
                                                <div

                                                    key={index}
                                                    className={"bg-dark-400 topic-cart-phone"}
                                                    style={{
                                                        width: "100%",
                                                        height: "77px",
                                                        borderRadius: "12px",
                                                    }}
                                                />
                                            )
                                        })

                                    }

                                </div>
                                :

                                <div

                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px"
                                    }}
                                >



                                    <div
                                        className='p-10-phone bg-dark-400'
                                        style={{
                                            background: "white",
                                            borderRadius: "12px",
                                            padding: "18px"
                                        }}
                                    >
                                        <div
                                            className='cursor-pointer'
                                            style={{
                                                display: "flex",
                                                borderRadius: "12px",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >


                                        </div>
                                        <div
                                            style={{
                                            }}
                                        >

                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "10px"
                                                }}
                                            >


                                                {/* Topic item */}
                                                {
                                                    videos.map((item, index) =>
                                                        <div
                                                            key={index}
                                                            onClick={() => setCurrentVideoUrl(item.url)}
                                                            className='bg-dark-500 cursor-pointer'

                                                            style={{
                                                                display: "flex",
                                                                gap: "13px",
                                                                background: "#F6F6F9",
                                                                alignItems: "center",
                                                                padding: "9px 20px",
                                                                borderRadius: "12px",
                                                                justifyContent: "space-between"

                                                            }}
                                                        >
                                                            <div
                                                                className='d-flex a-i-center g-10'
                                                            >
                                                                {
                                                                    true ?
                                                                        <TopicCheckIcon
                                                                            style={{
                                                                                minWidth: "20px",
                                                                                minHeight: "20px",
                                                                                maxWidth: "20px",
                                                                                maxHeight: "20px",
                                                                                width: "20px",
                                                                                height: "20px"
                                                                            }}
                                                                        />
                                                                        : <TopicUncheckIcon
                                                                            style={{
                                                                                minWidth: "20px",
                                                                                minHeight: "20px",
                                                                                maxWidth: "20px",
                                                                                maxHeight: "20px",
                                                                                width: "20px",
                                                                                height: "20px"
                                                                            }}

                                                                        />

                                                                }
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: "5px",
                                                                        width: "-webkit-fill-available"

                                                                    }}
                                                                >
                                                                    <p
                                                                        className=''
                                                                        style={{
                                                                            margin: "0",
                                                                            fontFamily: "medium",
                                                                            fontSize: "13px",
                                                                            color: currentVideoUrl == item.url ? '#ff5612' : 'white'
                                                                        }}
                                                                    >{item.name}</p>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            gap: "7px",
                                                                            alignItems: "center",

                                                                        }}
                                                                    >
                                                                        <p
                                                                            className='text-gray-100'
                                                                            style={{
                                                                                margin: "0px",
                                                                                fontFamily: "semiBold",
                                                                                fontSize: "12px",
                                                                                color: "#BBBBBB"
                                                                            }}
                                                                        > </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: "55px",
                                                                    gap: "7px",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "flex-end"
                                                                }}
                                                            >

                                                                {
                                                                    true
                                                                        ?
                                                                        <></>
                                                                        :
                                                                        <FileIcon
                                                                            style={{
                                                                                width: "24px",
                                                                                height: "24px"
                                                                            }}
                                                                        />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }



                                            </div>
                                        </div>
                                    </div>

                                </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Public
