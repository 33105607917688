import React, { useState } from 'react'
import theme from '../../theme/theme'
import checkIcon from "../../assets/check.svg"
import '../../assets/css/courseInfo.css'


const WhatYouWillLearn = ({ courseWillLearn, className }) => {

    const [height, setHeight] = useState(false)

    return (
        <div className={`${className} `}>
            <div className='p-20-phone bg-dark-500' style={{ padding: "50px", background: theme.color.gray100, borderRadius: "20px", maxHeight: height ? "" : "350px", overflow: "hidden" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginBottom: "30px" }}>
                    <p className='text-primary' style={{ margin: 0, fontFamily: "semiBold", fontSize: "14px" }}>Learning</p>
                    <p className='fs-20-phone text-white' style={{ margin: 0, fontFamily: "semiBold", fontSize: "35px" }}>What you’ll learn</p>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>

                    {
                        courseWillLearn && courseWillLearn.length !== 0 ? courseWillLearn.map((i, index) => {
                            return (
                                <div key={index} style={{ display: "flex", alignItems: "start", gap: "10px" }}>
                                    <img className="filter-invert-100" style={{ width: "20px", height: "20px" }} src={checkIcon} />
                                    <p className="text-gray-100" style={{ fontFamily: "semiBold", color: "#8D8D8D", margin: "0", fontSize: "14px" }}>{i.description}</p>
                                </div>
                            )
                        }) :
                            <div style={{ display: "flex", alignItems: "start", gap: "10px" }}>
                                <img style={{ width: "20px", height: "20px" }} src={checkIcon} />
                                <p style={{ fontFamily: "semiBold", color: "#8D8D8D", margin: "0", fontSize: "14px" }}>What you will learn</p>
                            </div>
                    }
                </div>

            </div>
            <button
                className="bg-dark-500 "
                onClick={() => setHeight(!height)}
                style={{
                    background: "#F6F6F9",
                    border: "0",
                    width: "100%",
                    height: '50px',
                    borderRadius: "20px",
                    marginTop: "10px"
                }}
            >
                <span
                    className='text-white'
                    style={{
                        fontFamily: "medium",
                        fontSize: "15px",
                        color: "black",
                    }}
                >{height ? "close" : "Read more"}</span>
            </button>
        </div>
    )
}

export default WhatYouWillLearn