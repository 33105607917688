import React, {useEffect, useState} from 'react'
import CourseContentLesson from './courseContentLesson'
import UpArrowBlack from "../../../assets/upArrowBlack.svg"


const CourseContentItem = ({ name, topics, index }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [topicsTime, setTopicsTime] = useState(0)



    const calculateSectionTime = () => {

        let totalSec = 0;
        if(!topics){
            return;
        }
        for(let i = 0; i < topics.length; i++){
            const sec = topics[i]?.file?.duration || "0"

                if(sec){
                    totalSec = totalSec + parseFloat(topics[i]?.file?.duration || 0)
                }
        }
        setTopicsTime((totalSec/60).toFixed(2))
    }

    useEffect(()=>{
        calculateSectionTime()
    },[topics])

    useEffect(()=>{
        if(index === 0){
            setIsOpen(true)
        }
    },[])





    return (
        <div  style={{ padding: "10px", borderBottom: "3px solid black", cursor: "pointer" }}>
            <div onClick={() => setIsOpen(!isOpen)} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", padding: "5px 20px", boxSizing: "border-box", gap: "10px" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "10px" }}>
                    <p className='fs-10-phone text-gray-200' style={{ margin: 0, fontFamily: "bold", fontSize: "14px", color: "#B4B4B4" }}>{topics && topics.length !== 0 ? topics.length : "0"} Lectures &#903; {topicsTime} min</p>
                    <p className='fs-14-phone text-white' style={{ margin: 0, fontFamily: "medium", fontSize: "14px" }}>{name}</p>
                </div>
                <img className='filter-invert-100' alt='arrow' src={UpArrowBlack} style={{ width: "13px", transform: isOpen ? "rotate(0deg)" : "rotate(180deg)", }} />
            </div>
            <div className='bg-dark-400' style={{ borderRadius: "12px", background: "white", height: isOpen ? "revert-layer" : "0px", overflow: isOpen ? "" : "hidden" }}>
                {
                    topics && topics.length !== 0 ? topics.map((i, index) => {
                        return (
                            <CourseContentLesson time={i?.file?.duration || 0} item={topics} index={index} key={index} name={i.name} />
                        )
                    })
                        :
                        <CourseContentLesson name={"No topics"} />
                }



            </div>
        </div>
    )
}

export default CourseContentItem
