import React, {useState} from 'react'
import Button from "./button";

const SelectDropdown = ({titleList, imageUrlList, placeholder, onOptionClick, value, onNextClick}) => {
    const [inputHeight, setInputHeight] = useState(65)
    const [focus, setFocus] = useState(false)
    return (
        <div
            className={' text-white'}
            style={{
                position: "relative"
            }}
        >
            <input

                className={'outline-none bg-dark-400 text-white border-2-s-dark-500'}
                onChange={(i)=>console.log(i.target.value)}
                contentEditable={false}
                onClick={() => setFocus(!focus)}
                value={value ? value : ""}
                placeholder={placeholder ? placeholder : "type anything"}
                style={{
                    borderRadius: "16px",
                    height: `${inputHeight}px`,
                    padding: "0 30px",
                    fontSize: "16px",
                    flex: "1",
                    width: "100%",
                    cursor: "pointer"
                }}/>
            {titleList.length !== 0
                ?
                <div
                    className={'bg-dark-400 border-2-s-dark-500'}
                    style={{
                        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
                        borderRadius: "16px",
                        overflow: "hidden",
                        position: "absolute",
                        top: `${inputHeight + 8}px`,
                        width: "100%",
                        zIndex: 1000,
                        display: focus ? "" : "none"
                    }}
                >
                    {
                        titleList.map((i, index) => {

                            const itemClickHandle = (index) => {
                                if (onOptionClick) {
                                    onOptionClick(index)
                                }
                                setFocus(false)
                                // onOptionClick ? onOptionClick(index) : null
                            }


                            return (
                                <div key={index}>
                                    <div
                                        className={`${titleList.length - 1 === index ? "" : "border-bottom-2 border-bottom-solid border-black"}`}
                                        onClick={() => itemClickHandle(index)}
                                        style={{
                                            display: "flex",
                                            gap: "26px",
                                            alignItems: "center",
                                            padding: "16px 40px",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <img
                                            src={imageUrlList ? imageUrlList[index] : null}
                                            style={{
                                                width: "44px",
                                                height: "44px",
                                                background: "#D9D9D9",
                                                borderRadius: "50%",
                                                objectFit: "cover"
                                            }}
                                        />
                                        <p

                                            style={{
                                                margin: "0",
                                                fontSize: "16px",
                                                fontFamily: "medium"
                                            }}
                                        >{i}</p>
                                    </div>
                                    {
                                        titleList.length >= 6
                                        ?
                                            <Button onClick={()=>onNextClick()} className={`${titleList.length === index + 1 ? "" : "d-none"} a-s-end float-right m-10 btn-dark-500` } title={"Next ➡️"}/>

                                            :
                                            <></>
                                    }
                                </div>

                            )
                        })

                    }

                </div> :
                <></>
            }


        </div>
    )
}

export default SelectDropdown
